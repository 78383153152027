import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import AccountRoutes from '@/modules/account/AccountRoutes';
import PortalRoutes from '@/modules/portal/PortalRoutes';
import ClientRoutes from '../modules/clients/ClientRoutes';
import SubscriptionRoutes from '../modules/subscription/SubscriptionRoutes';
import ProjectRoutes from '../modules/projects/ProjectRoutes';
import AccountingRoutes from '@/modules/accounting/AccountingRoutes';
import PipelineRoutes from "@/modules/pipeline/PipelineRoutes";
import Calendar from '@/modules/calendar/Calendar';
import Profile from '../modules/preferences/Profile';

import Login from '../modules/auth/Login';
import Loader from '../modules/auth/Loader';
import MagicToken from '../modules/auth/MagicToken';
import InvitationHandler from '@/modules/auth/InvitationHandler';
import AccountUsers from '@/modules/users/AccountUsers';
import IntegrationRoutes from '@/modules/integrations/IntegrationRoutes';
import CommunicatorRoutes from "@/modules/communicator/CommunicatorRoutes";
import GettingStarted from '@/modules/home/GettingStarted';
import Welcome from '@/modules/welcome/Welcome';
import TemplateRoutes from '@/modules/templates/TemplateRoutes';
import ProposalRoutes from '@/modules/proposals/ProposalRoutes';
import HomeBase from "@/modules/home/HomeBase";
import ConnectedApps from "@/modules/account/apps/ConnectedApps";
import Stripe from "@/modules/integrations/stripe/Stripe";
import Preferences from "@/modules/account/Preferences";
import Import from "@/modules/account/import/Import";
import TimeTrackRoutes from "@/modules/timetracking/TimeTrackRoutes";
import Export from "@/modules/account/export/Export";
import WorkflowList from "@/modules/workflow/WorkflowList";
import CustomFieldRoutes from "@/modules/fields/CustomFieldRoutes";
import FormRoutes from "@/modules/discovery/FormRoutes";
import MeetingRoutes from "@/modules/meetings/MeetingRoutes";
import Me from "@/modules/account/Me";

Vue.use(VueRouter);

const baseRoutes = [
	{
		path: '/loader',
		name: 'loader',
		component: Loader,
		meta: {
			insecure: true,
			initState: true,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			insecure: true,
		},
	},
	{
		path: '/magicToken',
		name: 'magicToken',
		component: MagicToken,
		meta: {
			insecure: true,
			initState: true,
		},
	},
	{
		path: '/invitation',
		name: 'invitation',
		component: InvitationHandler,
		meta: {
			insecure: true,
			initState: true,
		},
	},
	{
		path: '/signup',
		name: 'signUp',
		component: Login,
		meta: {
			insecure: true,
			initState: true,
		},
	},
	{
		path: '/welcome',
		name: 'welcome',
		component: Welcome,
	},
	{
		path: '/getting-started',
		name: 'GettingStarted',
		component: GettingStarted,
		props: (route) => ({
			celebration: route.query.celebration,
			welcome: route.query.welcome,
		}),
	},
	{
		path: '/me/profile',
		name: 'me',
		component: Me,
		props: route => ({
			view: route.params.view,
		})
	},
	{
		path: '/calendar',
		name: 'calendar',
		component: Calendar,
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
	},
	{
		path: '/team',
		name: 'Team',
		component: AccountUsers,
	},
	{
		path: '/apps',
		name: 'Apps',
		component: ConnectedApps,
		props: route => ({
			v: route.query.v,
		})
	},
	{
		path: '/stripe',
		name: 'Stripe',
		component: Stripe,
	},
	{
		path: '/preferences',
		name: 'Preferences',
		component: Preferences,
	},
	{
		path: '/data/import',
		name: 'Import',
		component: Import,
	},
	{
		path: '/data/export',
		name: 'Export',
		component: Export,
	},
	{
		path: '/workflows',
		name: 'WorkflowList',
		component: WorkflowList,
	},
	{
		path: '/',
		redirect: '/home/focus',
	},
	{
		path: '/home',
		redirect: '/home/focus',
	},
	{
		path: '/home/:view',
		name: 'HomeBase',
		component: HomeBase,
		props: route => ({
			v: route.params.view,
		})
	},
	{
		path: '*',
		name: 'NotFound',
		redirect: '/home',
	},
];

const routes = baseRoutes.concat(
	ClientRoutes,
	CommunicatorRoutes,
	SubscriptionRoutes,
	ProjectRoutes,
	AccountRoutes,
	IntegrationRoutes,
	AccountingRoutes,
	PortalRoutes,
	TemplateRoutes,
	ProposalRoutes,
	TimeTrackRoutes,
	PipelineRoutes,
	CustomFieldRoutes,
	FormRoutes,
	MeetingRoutes
);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (!store.state.initialized) {
		if (to.meta.initState) {
			next();
		} else {
			store.commit('setOrigRequestedUri', to.fullPath);
			next('/loader');
		}
	} else {
		if(store.state.loggedIn && store.getters.isDisabledAccount && !to.path.startsWith('/subscription') && !to.path.startsWith('/me/') && !to.path.startsWith('/data/') && !to.path.startsWith('/referrals') && !to.path.startsWith('/apps')){
			next('/subscription/workspace');
		} else if (store.state.loggedIn || to.meta.insecure) {
			next();
		} else {
			next('/login');
		}
	}
});

export default router;
