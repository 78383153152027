var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "panel-modal", staticStyle: { width: "600px" } },
        [
          _c("input", {
            attrs: {
              id: "opportunityLink",
              name: "opportunityLink",
              type: "hidden"
            },
            domProps: { value: _vm.opportunityLink }
          }),
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.save.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1 material-symbols-rounded",
                    attrs: { color: "black" }
                  },
                  [_vm._v("filter_alt")]
                ),
                _c("div", { staticClass: "brand-medium font-18 mater" }, [
                  _vm._v("Opportunity details")
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c(
                      "v-btn",
                      { staticClass: "super-action", on: { click: _vm.save } },
                      [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer ml-2",
                        attrs: { size: "20" },
                        on: {
                          click: function($event) {
                            return _vm.getShareLink()
                          }
                        }
                      },
                      [_vm._v("link")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer ml-2",
                        attrs: { size: "20" },
                        on: {
                          click: function($event) {
                            return _vm.confirmDelete()
                          }
                        }
                      },
                      [_vm._v("$delete")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "panel-modal-body text-left" }, [
            _c("div", { attrs: { id: "opportunity-detail" } }, [
              _c(
                "div",
                { staticClass: "row-format" },
                [
                  _c("v-text-field", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      placeholder: "Opportunity name"
                    },
                    model: {
                      value: _vm.opportunity.name,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity, "name", $$v)
                      },
                      expression: "opportunity.name"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "mt-6 mb-4 row-format align-center" }, [
                _c(
                  "div",
                  {
                    class:
                      "page-link " +
                      (_vm.currentView === "Overview"
                        ? "page-link-active"
                        : ""),
                    on: {
                      click: function($event) {
                        return _vm.setView("Overview")
                      }
                    }
                  },
                  [_c("div", [_vm._v("Overview")])]
                ),
                _c(
                  "div",
                  {
                    class:
                      "page-link " +
                      (_vm.currentView === "Notes" ? "page-link-active" : ""),
                    on: {
                      click: function($event) {
                        return _vm.setView("Notes")
                      }
                    }
                  },
                  [_c("div", [_vm._v("Notes")])]
                ),
                _c(
                  "div",
                  {
                    class:
                      "page-link " +
                      (_vm.currentView === "Attachments"
                        ? "page-link-active"
                        : ""),
                    on: {
                      click: function($event) {
                        return _vm.setView("Attachments")
                      }
                    }
                  },
                  [_c("div", [_vm._v("Attachments")])]
                ),
                _c(
                  "div",
                  {
                    class:
                      "page-link " +
                      (_vm.currentView === "Activity"
                        ? "page-link-active"
                        : ""),
                    on: {
                      click: function($event) {
                        return _vm.setView("Activity")
                      }
                    }
                  },
                  [_c("div", [_vm._v("Activity")])]
                ),
                _c(
                  "div",
                  {
                    class:
                      "page-link " +
                      (_vm.currentView === "Lead" ? "page-link-active" : ""),
                    on: {
                      click: function($event) {
                        return _vm.setView("Lead")
                      }
                    }
                  },
                  [_c("div", [_vm._v("Lead info")])]
                ),
                _c(
                  "div",
                  {
                    class:
                      "page-link " +
                      (_vm.currentView === "Automations"
                        ? "page-link-active"
                        : ""),
                    on: {
                      click: function($event) {
                        return _vm.setView("Automations")
                      }
                    }
                  },
                  [_c("div", [_vm._v("Automations")])]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "show-scrollbar",
                  staticStyle: {
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto"
                  }
                },
                [
                  _vm.currentView === "Overview"
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("group")]
                                  ),
                                  _vm.assignedClient
                                    ? _c("div", { staticClass: "ml-2" }, [
                                        _vm._v(
                                          _vm._s(_vm.assignedClient.clientType)
                                        )
                                      ])
                                    : _c("div", { staticClass: "ml-2" }, [
                                        _vm._v("Client / Prospect")
                                      ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-click": true,
                                        "close-on-content-click": false,
                                        "offset-y": "",
                                        disabled:
                                          _vm.$store.getters
                                            .isAccountCollaborator
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    { staticClass: "pointer" },
                                                    on
                                                  ),
                                                  [
                                                    _vm.assignedClient
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row-format align-center"
                                                          },
                                                          [
                                                            _c(
                                                              "client-avatar",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  client:
                                                                    _vm.assignedClient,
                                                                  "disable-click": true
                                                                }
                                                              }
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ml-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .assignedClient
                                                                      .name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _c("div", [
                                                          _vm._v("--")
                                                        ])
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1881710377
                                      ),
                                      model: {
                                        value: _vm.clientMenu,
                                        callback: function($$v) {
                                          _vm.clientMenu = $$v
                                        },
                                        expression: "clientMenu"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "column-format pa-2",
                                          staticStyle: {
                                            "background-color":
                                              "var(--v-white-base)"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "",
                                              dense: "",
                                              outlined: "",
                                              placeholder: "Filter...",
                                              autofocus: ""
                                            },
                                            model: {
                                              value: _vm.clientFilter,
                                              callback: function($$v) {
                                                _vm.clientFilter = $$v
                                              },
                                              expression: "clientFilter"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-left column-format pt-1 mt-2 show-scrollbar",
                                              staticStyle: {
                                                "max-height": "300px",
                                                "overflow-y": "scroll"
                                              }
                                            },
                                            _vm._l(
                                              _vm.filteredClientList,
                                              function(client) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: client.id,
                                                    staticClass:
                                                      "row-format align-center px-3 py-2 pointer hover-gray",
                                                    staticStyle: {
                                                      "border-bottom":
                                                        "1px solid var(--v-gray_30-base)"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setClient(
                                                          client
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    client.id &&
                                                    client.id.length > 10
                                                      ? _c("client-avatar", {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            client: client,
                                                            "x-small": ""
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "brand-medium"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(client.name)
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c("v-icon", { attrs: { size: "20" } }, [
                                    _vm._v("bolt")
                                  ]),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Stage")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-click": true,
                                        "close-on-content-click": true,
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "opportunityStage row-format align-center pointer",
                                                      style:
                                                        "--status-color:" +
                                                        _vm.stage.hexColor
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("div", {
                                                      staticClass: "stageBox"
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.stage.label
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1384003264
                                      )
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-left pa-3 font-14",
                                          staticStyle: {
                                            "background-color":
                                              "var(--v-white-base)"
                                          }
                                        },
                                        _vm._l(_vm.stages, function(stage) {
                                          return _c(
                                            "div",
                                            {
                                              key: stage.id,
                                              staticClass: "py-1 pointer",
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateStatus(stage)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "opportunityStage row-format align-center highlight-hover",
                                                  style:
                                                    "--status-color:" +
                                                    stage.hexColor
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "stageBox"
                                                  }),
                                                  _c("div", [
                                                    _vm._v(_vm._s(stage.label))
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("thumbs_up_down")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Confidence")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-click": true,
                                        "close-on-content-click": true,
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "material-symbols-rounded ml-n1",
                                                      attrs: {
                                                        color:
                                                          _vm.sentiment.color,
                                                        size: "28"
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.sentiment.icon)
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        961887833
                                      )
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pa-2 column-format gap-2",
                                          staticStyle: {
                                            "background-color":
                                              "var(--v-white-base)"
                                          }
                                        },
                                        _vm._l(_vm.confidenceList, function(
                                          item
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.value,
                                              staticClass: "pointer",
                                              on: {
                                                click: function($event) {
                                                  return _vm.setSentiment(
                                                    item.value
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-symbols-rounded",
                                                  attrs: {
                                                    color: item.color,
                                                    size: "28"
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.icon))]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c("v-icon", { attrs: { size: "20" } }, [
                                    _vm._v("attach_money")
                                  ]),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Est. value")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "overview-right row-format gap-2"
                                },
                                [
                                  _c("currency-input", {
                                    staticStyle: { width: "60px" },
                                    attrs: {
                                      options: {
                                        currency:
                                          _vm.$store.state.defaultCurrency
                                      }
                                    },
                                    model: {
                                      value: _vm.opportunity.value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.opportunity, "value", $$v)
                                      },
                                      expression: "opportunity.value"
                                    }
                                  }),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-click": true,
                                        "close-on-content-click": true,
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    { staticClass: "pointer" },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.timePeriod.label
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4111499079
                                      )
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "more-menu" },
                                        _vm._l(_vm.timePeriods, function(
                                          period
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: period.value,
                                              staticClass: "more-menu-item",
                                              on: {
                                                click: function($event) {
                                                  _vm.opportunity.timePeriod =
                                                    period.value
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(period.label) + " "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  ),
                                  _vm.timePeriod.hasLength
                                    ? _c("div", { staticClass: "row-format" }, [
                                        _c("div", [_vm._v("for")]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "px-2 editable-div",
                                            attrs: { contenteditable: "true" },
                                            on: { blur: _vm.setPeriods }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.opportunity.periods
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(_vm._s(_vm.timePeriod.label2))
                                        ])
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c("v-icon", { attrs: { size: "20" } }, [
                                    _vm._v("today")
                                  ]),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Est. close date")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c("date-selector", {
                                    attrs: {
                                      micro: true,
                                      "show-year": true,
                                      "micro-font": "font-14",
                                      date: _vm.opportunity.estCloseDate,
                                      label: "--"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.opportunity.estCloseDate = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.closedStages.includes(_vm.opportunity.statusId)
                            ? _c(
                                "div",
                                { staticClass: "row-format align-center py-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "overview-left" },
                                    [
                                      _c("v-icon", { attrs: { size: "20" } }, [
                                        _vm._v("event")
                                      ]),
                                      _c("div", { staticClass: "ml-2" }, [
                                        _vm._v("Date closed")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "overview-right" },
                                    [
                                      _c("date-selector", {
                                        attrs: {
                                          micro: true,
                                          "show-year": true,
                                          "micro-font": "font-14",
                                          date: _vm.opportunity.actualCloseDate,
                                          label: "--"
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.opportunity.actualCloseDate = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._l(_vm.fields, function(field) {
                            return _c(
                              "div",
                              { key: field.id, staticClass: "row-format py-2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "overview-left" },
                                  [
                                    _c("v-icon", { attrs: { size: "20" } }, [
                                      _vm._v(_vm._s(field.icon))
                                    ]),
                                    _c("div", { staticClass: "ml-2" }, [
                                      _vm._v(_vm._s(field.name))
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "overview-right" },
                                  [
                                    _c("custom-field", {
                                      attrs: {
                                        field: field,
                                        value: _vm.getCustomValue(field.id)
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleCustomFieldChange(
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          _c("div", { staticClass: "mt-3" }, [
                            _c("div", { staticClass: "brand-bold mb-2" }, [
                              _vm._v("Description")
                            ]),
                            _c(
                              "div",
                              { staticClass: "font-14" },
                              [
                                _c("editor", {
                                  ref: "Description",
                                  staticClass: "no-wrap-editor px-1",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "api-key": _vm.$store.getters.getTinyMceKey,
                                    inline: true,
                                    init: _vm.mceConfig,
                                    spellcheck: true
                                  },
                                  model: {
                                    value: _vm.opportunity.description,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.opportunity,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "opportunity.description"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt-6" },
                            [
                              _c("div", { staticClass: "brand-bold mb-2" }, [
                                _vm._v("To-Do")
                              ]),
                              _c("to-do", {
                                model: {
                                  value: _vm.opportunity.toDos,
                                  callback: function($$v) {
                                    _vm.$set(_vm.opportunity, "toDos", $$v)
                                  },
                                  expression: "opportunity.toDos"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pa-2 text-left mt-6" },
                            [
                              _c("v-switch", {
                                staticStyle: { "margin-top": "0px" },
                                attrs: {
                                  inset: "",
                                  flat: "",
                                  dense: "",
                                  "hide-details": "",
                                  label:
                                    (_vm.opportunity.archive
                                      ? "Archived"
                                      : "Archive") + " opportunity"
                                },
                                model: {
                                  value: _vm.opportunity.archive,
                                  callback: function($$v) {
                                    _vm.$set(_vm.opportunity, "archive", $$v)
                                  },
                                  expression: "opportunity.archive"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "font-12 font-gray_80 mt-2" },
                                [
                                  _vm._v(
                                    " Archived opportunities will still be accessible from the list screen, but will be filtered off by default. "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.currentView === "Notes"
                    ? _c(
                        "div",
                        [
                          _c("comments", {
                            attrs: { opportunity: _vm.opportunity }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentView === "Activity"
                    ? _c(
                        "div",
                        [
                          _c("workflow", {
                            attrs: { opportunity: _vm.opportunity },
                            on: {
                              save: function($event) {
                                return _vm.save(false)
                              },
                              "send-email": function($event) {
                                return _vm.sendEmail($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentView === "Attachments"
                    ? _c(
                        "div",
                        [
                          _c("file-attachments", {
                            attrs: { opportunity: _vm.opportunity }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentView === "Lead"
                    ? _c(
                        "div",
                        [
                          _c("lead-info", {
                            staticClass: "pt-4",
                            attrs: { opportunity: _vm.opportunity },
                            on: {
                              "send-email": function($event) {
                                return _vm.sendEmail($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentView === "Automations"
                    ? _c(
                        "div",
                        [
                          _c("automation-list", {
                            attrs: {
                              "resource-type": "Opportunity",
                              "resource-id": _vm.opportunity.id
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }