var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode === "standard" || _vm.mode === "dense"
    ? _c(
        "div",
        {
          staticClass: "pa-2",
          staticStyle: {
            width: "100%",
            border: "1px solid var(--v-gray_50-base)",
            "border-radius": "4px",
            position: "relative"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "row-format gap-2 font-14",
              staticStyle: { "flex-wrap": "wrap" }
            },
            _vm._l(_vm.field.options, function(option, index) {
              return _c("label", { key: index }, [
                _vm.field.type.toLowerCase() === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ourValue,
                          expression: "ourValue"
                        }
                      ],
                      staticClass: "custom-checkbox",
                      staticStyle: {
                        width: "12px",
                        height: "12px",
                        "border-radius": "4px"
                      },
                      attrs: { "true-value": option, type: "checkbox" },
                      domProps: {
                        value: option,
                        checked: Array.isArray(_vm.ourValue)
                          ? _vm._i(_vm.ourValue, option) > -1
                          : _vm._q(_vm.ourValue, option)
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.ourValue,
                            $$el = $event.target,
                            $$c = $$el.checked ? option : false
                          if (Array.isArray($$a)) {
                            var $$v = option,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.ourValue = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.ourValue = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.ourValue = $$c
                          }
                        }
                      }
                    })
                  : _vm.field.type.toLowerCase() === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ourValue,
                          expression: "ourValue"
                        }
                      ],
                      staticClass: "custom-checkbox",
                      staticStyle: {
                        width: "12px",
                        height: "12px",
                        "border-radius": "4px"
                      },
                      attrs: { "true-value": option, type: "radio" },
                      domProps: {
                        value: option,
                        checked: _vm._q(_vm.ourValue, option)
                      },
                      on: {
                        change: function($event) {
                          _vm.ourValue = option
                        }
                      }
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ourValue,
                          expression: "ourValue"
                        }
                      ],
                      staticClass: "custom-checkbox",
                      staticStyle: {
                        width: "12px",
                        height: "12px",
                        "border-radius": "4px"
                      },
                      attrs: {
                        "true-value": option,
                        type: _vm.field.type.toLowerCase()
                      },
                      domProps: { value: option, value: _vm.ourValue },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.ourValue = $event.target.value
                        }
                      }
                    }),
                _c("span", { staticClass: "ml-1" }, [_vm._v(_vm._s(option))])
              ])
            }),
            0
          ),
          _c("div", { staticClass: "phone-label" }, [
            _vm._v(_vm._s(_vm.field.name))
          ])
        ]
      )
    : _c(
        "div",
        {
          staticClass: "row-format gap-2",
          staticStyle: { "flex-wrap": "wrap" }
        },
        _vm._l(_vm.field.options, function(option, index) {
          return _c("label", { key: index }, [
            _vm.field.type.toLowerCase() === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ourValue,
                      expression: "ourValue"
                    }
                  ],
                  staticClass: "custom-checkbox",
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "border-radius": "4px"
                  },
                  attrs: { "true-value": option, type: "checkbox" },
                  domProps: {
                    value: option,
                    checked: Array.isArray(_vm.ourValue)
                      ? _vm._i(_vm.ourValue, option) > -1
                      : _vm._q(_vm.ourValue, option)
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.ourValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? option : false
                      if (Array.isArray($$a)) {
                        var $$v = option,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.ourValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.ourValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.ourValue = $$c
                      }
                    }
                  }
                })
              : _vm.field.type.toLowerCase() === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ourValue,
                      expression: "ourValue"
                    }
                  ],
                  staticClass: "custom-checkbox",
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "border-radius": "4px"
                  },
                  attrs: { "true-value": option, type: "radio" },
                  domProps: {
                    value: option,
                    checked: _vm._q(_vm.ourValue, option)
                  },
                  on: {
                    change: function($event) {
                      _vm.ourValue = option
                    }
                  }
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ourValue,
                      expression: "ourValue"
                    }
                  ],
                  staticClass: "custom-checkbox",
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "border-radius": "4px"
                  },
                  attrs: {
                    "true-value": option,
                    type: _vm.field.type.toLowerCase()
                  },
                  domProps: { value: option, value: _vm.ourValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.ourValue = $event.target.value
                    }
                  }
                }),
            _c("span", { staticClass: "ml-1" }, [_vm._v(_vm._s(option))])
          ])
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }