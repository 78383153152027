var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isInitialized
    ? _c(
        "div",
        { staticClass: "column-format fill-height" },
        [
          _c("input", {
            attrs: { id: "shareableLink", type: "hidden" },
            domProps: { value: _vm.shareableLink }
          }),
          _vm.isEmpty
            ? _c(
                "div",
                {
                  staticClass: "row-format centered fill-height",
                  staticStyle: { "min-height": "calc(100vh - 300px)" }
                },
                [
                  _c("empty-view", {
                    attrs: {
                      header: "No forms yet",
                      body:
                        "The most common kind of form is a discovery form. This helps you learn a potential client’s needs and budget, as well as screen good ones from bad. But these forms are completely customizable, so you can make them into anything you need.",
                      cta: "Send a form",
                      "video-header": "See how it works",
                      "video-body":
                        "Learn how to create and customize forms for discovery, follow-up, customer satisfaction, feedback, or anything else you can think of.",
                      "video-cta": "Watch the tutorial",
                      "video-id": "-IL2PtIX6ZQ"
                    },
                    on: {
                      "cta-clicked": function($event) {
                        return _vm.addNew()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formSubmissionCount ? _vm._t("filter") : _vm._e(),
          _vm.discoveryList.length
            ? _c(
                "div",
                {
                  staticClass:
                    "text-left column-format gap-2 font-14 mt-3 pa-3",
                  staticStyle: {
                    border: "1px solid var(--v-gray_30-base)",
                    "border-radius": "4px",
                    "background-color": "var(--v-white-base)"
                  }
                },
                _vm._l(_vm.discoveryList, function(discovery) {
                  return _c(
                    "div",
                    { key: discovery.id, staticClass: "form-wrapper font-14" },
                    [
                      _c(
                        "div",
                        { staticClass: "row-format align-center gap-2" },
                        [
                          _c("div", [_vm._v(_vm._s(discovery.name))]),
                          !discovery.completedTimestamp &&
                          !discovery.viewedTimestamp
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row-format align-center font-12 font-gray_70"
                                },
                                [
                                  _vm._v(
                                    " Created: " +
                                      _vm._s(
                                        _vm.DateTime.fromISO(
                                          discovery.sentTimestamp
                                        ).toLocaleString(
                                          _vm.DateTime.DATETIME_FULL
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : discovery.viewedTimestamp
                            ? _c(
                                "div",
                                {
                                  staticClass: "row-format align-center font-12"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "var(--v-primary-base)"
                                      }
                                    },
                                    [_vm._v("Viewed:")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ml-",
                                      staticStyle: {
                                        color: "var(--v-primary-base)"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.DateTime.fromISO(
                                              discovery.viewedTimestamp
                                            ).toLocaleString(
                                              _vm.DateTime.DATETIME_FULL
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "ml-auto row-format gap-2" },
                            [
                              _c(
                                "v-icon",
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        content:
                                          "Copy the link for the form request"
                                      },
                                      expression:
                                        "{ content: 'Copy the link for the form request' }"
                                    }
                                  ],
                                  staticClass:
                                    "material-symbols-rounded pointer",
                                  attrs: { size: "20" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.copyLink(discovery.url)
                                    }
                                  }
                                },
                                [_vm._v("link")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        content:
                                          "Make changes or send the request via email"
                                      },
                                      expression:
                                        "{ content: 'Make changes or send the request via email' }"
                                    }
                                  ],
                                  staticClass:
                                    "material-symbols-rounded pointer",
                                  attrs: { size: "20" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editDiscoveryRequest(discovery)
                                    }
                                  }
                                },
                                [_vm._v("edit")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: { content: "Delete form request" },
                                      expression:
                                        "{ content: 'Delete form request' }"
                                    }
                                  ],
                                  staticClass:
                                    "material-symbols-rounded pointer",
                                  attrs: { size: "20" },
                                  on: {
                                    click: function($event) {
                                      return _vm.confirmDelete(discovery)
                                    }
                                  }
                                },
                                [_vm._v("delete")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c("form-submissions-table", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.formSubmissionCount > 0,
                expression: "formSubmissionCount > 0"
              }
            ],
            staticClass: "mt-3",
            attrs: { "client-id": _vm.client.id, filter: _vm.filter },
            on: {
              "record-count": function($event) {
                return _vm.setRecordCount($event)
              }
            }
          }),
          _vm.newDiscoveryDialog
            ? _c(
                "basic-modal",
                {
                  attrs: {
                    dialog: _vm.newDiscoveryDialog,
                    persistent: false,
                    "max-width": _vm.maxWidth
                  },
                  on: {
                    close: function($event) {
                      return _vm.handleTemplateModalClose()
                    }
                  }
                },
                [
                  _c("discovery-templates", {
                    attrs: {
                      "client-id": _vm.client.id,
                      discoveryOrigin: "clientDetail",
                      "modal-state": _vm.modalState,
                      "v2-only": true
                    },
                    on: {
                      close: function($event) {
                        return _vm.handleTemplateModalClose()
                      },
                      "template-selected": _vm.handleTemplateSelected
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.discoveryDialog
            ? _c(
                "basic-modal",
                {
                  attrs: {
                    dialog: _vm.discoveryDialog,
                    persistent: true,
                    fullscreen: true,
                    "retain-focus": false
                  },
                  on: { close: _vm.handleBuilderClose }
                },
                [
                  _c("discovery-builder", {
                    attrs: {
                      client: _vm.client,
                      "template-id": _vm.templateId
                    },
                    on: {
                      close: _vm.handleBuilderClose,
                      sent: _vm.handleBuilderSent,
                      duplicated: _vm.handleBuilderDuplicated
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("confirm-dialog", {
            attrs: {
              dialog: _vm.confirmDeleteDialog,
              "heading-text": "Delete?",
              "body-text":
                "Are you sure you want to delete this discovery request?"
            },
            on: { close: _vm.cancelDelete, confirm: _vm.deleteForm }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }