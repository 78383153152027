var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { height: "100%" }, attrs: { "data-app": "" } },
    [
      _c("on-boarding-controller"),
      _vm.$store.state.loggedIn && !_vm.$store.state.inWelcomeFlow
        ? _c("div", { staticClass: "column-format fill-all" }, [
            _c(
              "div",
              {
                staticClass: "row-format",
                style: "--v-right-nav-width:" + _vm.rightNavWidth,
                attrs: { id: "app-area" }
              },
              [
                _c("global-modal-controller", { ref: "gmc" }),
                _c("left-nav"),
                _c(
                  "div",
                  {
                    staticClass: "column-format",
                    attrs: { id: "right-section" }
                  },
                  [
                    _c("top-nav"),
                    _c("div", { attrs: { id: "main-app" } }, [
                      _c("div", { staticClass: "row-format" }, [
                        _c(
                          "div",
                          {
                            class: "px-9 pt-6  " + _vm.$store.getters.scroll,
                            style:
                              "max-width: " +
                              _vm.centerWidth +
                              "; overflow-y: auto",
                            attrs: { id: "main-content" }
                          },
                          [
                            _c(
                              "v-main",
                              [
                                _c(
                                  "v-snackbar",
                                  {
                                    staticStyle: {
                                      "font-size": "1.1em",
                                      "margin-right": "16px",
                                      "margin-bottom": "60px"
                                    },
                                    attrs: {
                                      id: "main-snackbar",
                                      color: _vm.color,
                                      timeout: _vm.timeout,
                                      bottom: true,
                                      right: true
                                    },
                                    model: {
                                      value: _vm.show,
                                      callback: function($$v) {
                                        _vm.show = $$v
                                      },
                                      expression: "show"
                                    }
                                  },
                                  [
                                    _c("div", [_vm._v(_vm._s(_vm.message))]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          dark: "",
                                          icon: "",
                                          id: "main-snackbar-close"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.show = false
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("close")])],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("safe-delete-handler"),
                                _c("object-created-handler"),
                                _c("router-view")
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { attrs: { id: "right-bar" } },
                          [
                            _c("right-nav", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.$store.state.rightNavExpanded,
                                  expression: "$store.state.rightNavExpanded"
                                }
                              ]
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c("help-center", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showHelpCenter,
                      expression: "showHelpCenter"
                    }
                  ],
                  attrs: { "is-open": _vm.showHelpCenter },
                  on: {
                    close: function($event) {
                      _vm.showHelpCenter = false
                    }
                  }
                }),
                _vm.$store.state.loggedIn
                  ? _c("contact-state-manager")
                  : _vm._e(),
                _vm.$store.state.loggedIn
                  ? _c("ai-entitlement-state-manager")
                  : _vm._e(),
                _vm.$store.state.loggedIn
                  ? _c("notification-state-manager")
                  : _vm._e(),
                _c("universal-search"),
                _c(
                  "v-overlay",
                  {
                    staticStyle: { "z-index": "1000" },
                    attrs: { value: _vm.$store.state.loadingState }
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", size: "64" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm.showSampleFooter
              ? _c(
                  "div",
                  {
                    staticClass: "py-1",
                    staticStyle: {
                      "border-radius": "8px",
                      width: "500px",
                      position: "absolute",
                      bottom: "12px",
                      "z-index": "10",
                      color: "var(--v-white-base)",
                      "background-color": "var(--v-secondary-base)",
                      left: "0",
                      right: "0",
                      "margin-right": "auto",
                      "margin-left": "auto"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "brand-medium row-format centered",
                        staticStyle: { width: "100%", "min-height": "42px" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "font-16 mr-2 brand-semilight",
                            staticStyle: { "text-align": "center" }
                          },
                          [
                            _vm._v(
                              " When you are done exploring, click here 👉 "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "font-16 py-1 px-2 pointer",
                            staticStyle: {
                              "background-color": "var(--v-accent-base)",
                              "border-radius": "4px"
                            },
                            on: { click: _vm.deleteSampleData }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { color: "var(--v-white-base)" } },
                              [_vm._v("Clear sample data")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        : _c(
            "div",
            { staticStyle: { height: "100%" } },
            [
              _c("router-view"),
              _c(
                "v-overlay",
                {
                  staticStyle: { "z-index": "1000" },
                  attrs: { value: _vm.$store.state.loadingState }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" }
                  })
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  staticStyle: { "font-size": "1.1em" },
                  attrs: {
                    id: "main-snackbar",
                    width: "100%",
                    color: _vm.color,
                    timeout: _vm.timeout,
                    bottom: true
                  },
                  model: {
                    value: _vm.show,
                    callback: function($$v) {
                      _vm.show = $$v
                    },
                    expression: "show"
                  }
                },
                [
                  _c("div", [_vm._v(_vm._s(_vm.message))]),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", icon: "", id: "main-snackbar-close" },
                      on: {
                        click: function($event) {
                          _vm.show = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }