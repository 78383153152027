var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        {
          class:
            "meeting-detail " +
            (_vm.scheduledMeeting.formData.answers.length
              ? "with-answers"
              : "no-answers")
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-padding d-flex",
              attrs: { id: "meeting-inner-wrapper" }
            },
            [
              _c(
                "div",
                { staticClass: "row-format mb-2 align-center" },
                [
                  _vm.view === "MAIN"
                    ? _c("div", { staticClass: "brand-medium font-18" }, [
                        _vm._v(_vm._s(_vm.$t("forms.meeting-modal.title")))
                      ])
                    : _c(
                        "v-icon",
                        {
                          on: {
                            click: function($event) {
                              _vm.view = "MAIN"
                            }
                          }
                        },
                        [_vm._v("$arrowLeft")]
                      ),
                  _c(
                    "div",
                    { staticClass: "ml-auto row-format align-center gap-4" },
                    [
                      _c("pipeline-link-widget", {
                        attrs: {
                          "item-id": _vm.scheduledMeeting.id,
                          "item-type": "ScheduledMeeting",
                          client: _vm.scheduledMeeting.clientId
                        }
                      }),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-auto pointer",
                          attrs: { size: "20" },
                          on: {
                            click: function($event) {
                              return _vm.saveScheduledMeeting(true)
                            }
                          }
                        },
                        [_vm._v("$close")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.view === "MAIN"
                ? _c(
                    "div",
                    [
                      _c(
                        "v-container",
                        { staticClass: "ml-0 pa-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "ml-0",
                                  attrs: {
                                    md: _vm.scheduledMeeting.formData.answers
                                      .length
                                      ? 6
                                      : 12,
                                    cols: "12",
                                    align: "left"
                                  }
                                },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "pa-0 ma-0 font-14" },
                                    [
                                      _vm.isCancelled
                                        ? _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "brand-medium",
                                                  attrs: { cols: "4" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "forms.meeting-modal.status"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "brand-medium",
                                                  staticStyle: {
                                                    color: "var(--v-error-base)"
                                                  },
                                                  attrs: { cols: "8" }
                                                },
                                                [_vm._v("Cancelled")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isCancelled
                                        ? _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "brand-medium",
                                                  attrs: { cols: "4" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "forms.meeting-modal.cancellation-reason"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "brand-medium",
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                    border:
                                                      "1px solid var(--v-gray_30-base)"
                                                  },
                                                  attrs: { cols: "8" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.scheduledMeeting
                                                        .cancellationReason
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "brand-medium",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.meeting-modal.meeting-name"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.scheduledMeeting
                                                    .meetingName
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "brand-medium",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("global.contact"))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row-format align-center gap-2"
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.scheduledMeeting
                                                            .formData.firstName
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.scheduledMeeting
                                                            .formData.lastName
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _vm.showCreateContact
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "tippy",
                                                              rawName:
                                                                "v-tippy",
                                                              value: {
                                                                content:
                                                                  "Create new contact"
                                                              },
                                                              expression:
                                                                "{ content: 'Create new contact' }"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "pointer material-symbols-rounded",
                                                          attrs: {
                                                            size: "22",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.addNewContact()
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("person_add")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-12 font-gray_70"
                                                },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.scheduledMeeting
                                                          .scheduledTimezone
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.businessInfo
                                        ? _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "brand-medium",
                                                  attrs: { cols: "4" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "forms.meeting-modal.business-info"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("v-col", {
                                                attrs: { cols: "8" },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.businessInfo
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "brand-medium",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("global.email"))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.scheduledMeeting.formData
                                                    .email
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "brand-medium",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("global.phone"))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.scheduledMeeting.formData
                                                    .phone
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.scheduledMeeting
                                        .additionalAttendees &&
                                      _vm.scheduledMeeting.additionalAttendees
                                        .length
                                        ? _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "brand-medium",
                                                  attrs: { cols: "4" }
                                                },
                                                [_vm._v("Additional attendees")]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "8" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.scheduledMeeting.additionalAttendees.toString()
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "brand-medium",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.meeting-modal.meeting-time"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              class: _vm.isCancelled
                                                ? "strike"
                                                : "",
                                              attrs: { cols: "8" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.DateTime.fromISO(
                                                    _vm.scheduledMeeting
                                                      .confirmedTime.start
                                                  ).toFormat("cccc") +
                                                    " " +
                                                    _vm.DateTime.fromISO(
                                                      _vm.scheduledMeeting
                                                        .confirmedTime.start
                                                    ).toLocaleString(
                                                      _vm.DateTime.DATETIME_MED
                                                    ) +
                                                    " (" +
                                                    _vm.Settings.defaultZone
                                                      .name +
                                                    ")"
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "brand-medium",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.meeting-modal.duration"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              class: _vm.isCancelled
                                                ? "strike"
                                                : "",
                                              attrs: { cols: "8" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.DateTime.fromISO(
                                                    _vm.scheduledMeeting
                                                      .confirmedTime.end
                                                  ).diff(
                                                    _vm.DateTime.fromISO(
                                                      _vm.scheduledMeeting
                                                        .confirmedTime.start
                                                    ),
                                                    "minutes"
                                                  ).minutes
                                                ) + " minutes"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-col", { attrs: { cols: "4" } }),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _c("create-time-entry", {
                                                attrs: {
                                                  start:
                                                    _vm.scheduledMeeting
                                                      .confirmedTime.start,
                                                  end:
                                                    _vm.scheduledMeeting
                                                      .confirmedTime.end,
                                                  notes: _vm.meetingName,
                                                  client: _vm.client
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      !_vm.isCancelled
                                        ? _c("div", [
                                            _vm.scheduledMeeting.location
                                              .type === "Zoom" &&
                                            _vm.scheduledMeeting.zoomMeeting
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "brand-medium",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [_vm._v("Join URL")]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href:
                                                                    _vm
                                                                      .scheduledMeeting
                                                                      .zoomMeeting
                                                                      .join_url,
                                                                  target:
                                                                    "_blank"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .scheduledMeeting
                                                                      .zoomMeeting
                                                                      .join_url
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "brand-medium",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [_vm._v("Meeting Id")]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .scheduledMeeting
                                                                  .zoomMeeting
                                                                  .id
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm.scheduledMeeting
                                                      .zoomMeeting.pstnPassword
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            attrs: { dense: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "brand-medium",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Passcode"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "8"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .scheduledMeeting
                                                                      .zoomMeeting
                                                                      .pstnPassword
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.zoomDialIn
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            attrs: { dense: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "brand-medium",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Dial in"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "8"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.zoomDialIn.number.replaceAll(
                                                                      " ",
                                                                      ""
                                                                    )
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .zoomDialIn
                                                                        .city
                                                                    ) +
                                                                    ", " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .zoomDialIn
                                                                        .country_name
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _vm.scheduledMeeting
                                                  .microsoftEvent &&
                                                _vm.scheduledMeeting
                                                  .microsoftEvent.meetingLink
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c("v-col", {
                                                          staticClass:
                                                            "brand-medium",
                                                          attrs: { cols: "4" }
                                                        }),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "my-2",
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  elevation: "0"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openTeamsMeeting(
                                                                      _vm
                                                                        .scheduledMeeting
                                                                        .microsoftEvent
                                                                        .meetingLink
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "$microsoft"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " Join Teams meeting"
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm.scheduledMeeting
                                                  .sessionEvent &&
                                                _vm.scheduledMeeting
                                                  .sessionEvent.sessionLink
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c("v-col", {
                                                          staticClass:
                                                            "brand-medium",
                                                          attrs: { cols: "4" }
                                                        }),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "my-2",
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  elevation: "0"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openTeamsMeeting(
                                                                      _vm
                                                                        .scheduledMeeting
                                                                        .sessionEvent
                                                                        .sessionLink
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "$sessionsIcon"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " Join Session"
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm.scheduledMeeting.location
                                                  .type === "Google" &&
                                                _vm.scheduledMeeting
                                                  .googleMeeting
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "brand-medium",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [_vm._v("Join URL")]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href:
                                                                    _vm
                                                                      .scheduledMeeting
                                                                      .googleMeeting
                                                                      .hangoutLink,
                                                                  target:
                                                                    "_blank"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .scheduledMeeting
                                                                      .googleMeeting
                                                                      .hangoutLink
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm.googleDialIn
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            attrs: { dense: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "brand-medium",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Dial in"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "8"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .googleDialIn
                                                                      .label
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .googleDialIn
                                                                        .regionCode
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.googleDialIn
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            attrs: { dense: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "brand-medium",
                                                                attrs: {
                                                                  cols: "4"
                                                                }
                                                              },
                                                              [_vm._v("Pin")]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "8"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .googleDialIn
                                                                      .pin
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _vm.scheduledMeeting.location
                                                  .type === "PhoneIn"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "brand-medium",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Invitee will call"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .scheduledMeeting
                                                                  .location
                                                                  .phone
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm.scheduledMeeting.location
                                                  .type === "PhoneOut"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "brand-medium",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Call invitee at"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .scheduledMeeting
                                                                  .formData
                                                                  .phone
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm.scheduledMeeting.location
                                                  .type === "InPerson"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "brand-medium",
                                                            attrs: { cols: "4" }
                                                          },
                                                          [_vm._v("Meet at")]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "8" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .scheduledMeeting
                                                                  .location
                                                                  .address
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .scheduledMeeting
                                                                  .location
                                                                  .custom
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                          ])
                                        : _vm._e(),
                                      _vm.income
                                        ? _c(
                                            "v-row",
                                            { staticClass: "mt-2" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "brand-medium",
                                                  attrs: { cols: "4" }
                                                },
                                                [_vm._v("Payment received")]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "row-format align-center gap-2",
                                                  attrs: { cols: "8" }
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$formatters.dollars(
                                                            _vm.income.amount,
                                                            true,
                                                            true,
                                                            _vm.income.currency
                                                          )
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm.income
                                                            .paymentSource
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "material-symbols-rounded pointer",
                                                      attrs: {
                                                        size: "20",
                                                        color: "primary"
                                                      },
                                                      on: {
                                                        click: _vm.openReceipt
                                                      }
                                                    },
                                                    [_vm._v("receipt")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isCancelled
                                        ? _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "mt-4 d-flex align-center justify-space-between gap-3",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "secondary-action",
                                                      staticStyle: {
                                                        flex: "0 1 50%"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.view = "CANCEL"
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "var(--v-error-base)"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Cancel meeting"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "primary-action",
                                                      staticStyle: {
                                                        flex: "0 1 50%"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.view =
                                                            "RESCHEDULE"
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Reschedule meeting"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-2 mb-0 pb-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("select-client", {
                                                ref: "selectClient",
                                                attrs: {
                                                  id: "select-client",
                                                  "prepend-icon": "$clients",
                                                  "hide-archive": true,
                                                  placeholder: _vm.$t(
                                                    "timetrack.current.choose-client"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.scheduledMeeting
                                                      .clientId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scheduledMeeting,
                                                      "clientId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scheduledMeeting.clientId"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "mt-n2 text-center pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              !_vm.scheduledMeeting.clientId
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "secondary-action add-new-client",
                                                      attrs: {
                                                        color: "primary",
                                                        text: ""
                                                      },
                                                      on: {
                                                        click: _vm.addNewClient
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "+ " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "forms.meeting-modal.create-client"
                                                            )
                                                          )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-textarea", {
                                                staticClass: "h-outline",
                                                attrs: {
                                                  "hide-details": "",
                                                  "persistent-placeholder": "",
                                                  label: "Meeting notes",
                                                  rows: "3",
                                                  "auto-grow": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.scheduledMeeting.notes,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.scheduledMeeting,
                                                      "notes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scheduledMeeting.notes"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.fromLeadGen
                                        ? _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "mt-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      inset: "",
                                                      label: _vm.$t(
                                                        "forms.meeting-modal.archive-from-leadgen"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.scheduledMeeting
                                                          .leadGenArchived,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.scheduledMeeting,
                                                          "leadGenArchived",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scheduledMeeting.leadGenArchived"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.scheduledMeeting.formData.answers.length
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        md: "6",
                                        cols: "12",
                                        align: "left"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "mb-2" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "forms.meeting-modal.questionnaire"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c(
                                        "v-container",
                                        {
                                          staticClass: "pa-2 ma-0 font-14",
                                          staticStyle: {
                                            "background-color":
                                              "var(--v-gray_5-base)",
                                            "border-radius": "4px"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "pa-4" },
                                                _vm._l(
                                                  _vm.scheduledMeeting.formData
                                                    .answers,
                                                  function(answer, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "text-left font-14 pb-3"
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "brand-medium",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              answer.question
                                                            )
                                                          }
                                                        }),
                                                        answer.fieldType ===
                                                        "FileInput"
                                                          ? _c(
                                                              "div",
                                                              _vm._l(
                                                                JSON.parse(
                                                                  answer.answer
                                                                ),
                                                                function(
                                                                  fileName
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: fileName
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "font-primary pointer",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadFile(
                                                                                fileName
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                fileName
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "pre-wrap"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    answer.answer
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm.view === "MAIN"
            ? _c(
                "div",
                { staticClass: "modal-footer row-format centered gap-1" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "super-action",
                      staticStyle: { width: "160px" },
                      on: { click: _vm.saveScheduledMeeting }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { icon: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "20" },
                          on: { click: _vm.deleteMeeting }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.view === "CANCEL"
            ? _c(
                "div",
                [
                  _c("i-frame-wrapper", {
                    attrs: { "frame-url": _vm.cancelUrl },
                    on: { "frame-event": _vm.frameEvent }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.view === "RESCHEDULE"
            ? _c(
                "div",
                [
                  _c("i-frame-wrapper", {
                    attrs: { "frame-url": _vm.rescheduleUrl },
                    on: { "frame-event": _vm.frameEvent }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }