<template>
	<div class="panel-modal" style="width: 600px" v-if="isReady">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="saveTicket(true)">$chevronRight</v-icon>
				<v-icon color="black" class="mr-1 material-symbols-rounded">local_activity</v-icon>
				<div class="brand-medium font-18">Ticket details</div>
				<div class="ml-auto row-format align-center gap-1">
					<v-btn v-if="isReady" class="super-action" @click="saveTicket(true)">
						{{ $t('global.save') }}
					</v-btn>
					<pipeline-link-widget
						:icon-only="true"
						:item-id="ourTicket.id"
						item-type="Request"
						:client-id="ourTicket.clientId"
					></pipeline-link-widget>
					<v-menu v-model="mergeMenu" nudge-bottom="10">
						<template v-slot:activator>
							<v-icon
								size="20"
								color="black"
								class="pointer material-symbols-rounded"
								v-tippy
								content="Merge ticket"
								@click="getMergeTickets()"
								>merge</v-icon
							>
						</template>
						<div style="background-color: var(--v-white-base)" class="text-left pa-2 column-format gap-1">
							<div class="brand-medium">Merge into ticket:</div>
							<div class="more-menu">
								<div class="more-menu-item" v-for="ticket in mergeTickets" :key="ticket.id" style="padding:0" @click="mergeIntoTicket(ticket)">
									{{ ticket.ticketNumber }} - {{ ticket.subject }}
								</div>
							</div>
						</div>
					</v-menu>

					<v-icon size="20" color="gray_70" class="pointer material-symbols-rounded" @click="confirmDelete()">delete</v-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body text-left">
			<div id="ticket-detail">
				<div class="row-format">
					<v-text-field
						hide-details
						dense
						outlined
						placeholder="Subject name"
						v-model="ourTicket.subject"
					></v-text-field>
				</div>
				<div class="mt-6 mb-4 row-format align-center gap-3">
					<div
						:class="`page-link ${currentView === 'Overview' ? 'page-link-active' : ''}`"
						@click="setView('Overview')"
					>
						<div>Overview</div>
					</div>
					<div
						:class="`page-link ${currentView === 'Comments' ? 'page-link-active' : ''}`"
						@click="setView('Comments')"
					>
						<div>Comments</div>
					</div>
					<div :class="`page-link ${currentView === 'Tasks' ? 'page-link-active' : ''}`" @click="setView('Tasks')">
						<div>Linked tasks</div>
					</div>
					<div :class="`page-link ${currentView === 'Summary' ? 'page-link-active' : ''}`" @click="setView('Summary')">
						<div>Summary</div>
					</div>
				</div>

				<div class="show-scrollbar" style="max-height: calc(100vh - 210px); overflow-y: auto">
					<div v-if="currentView === 'Summary'" style="width:98%">
						<div v-if="ourTicket.summary" class="text-left">
							<div v-html="ourTicket.summary"></div>
						</div>
						<div class="row-format justify-end">
							<v-btn class="primary-action" @click="generateSummary(false)">
								<span class="font-secondary">Summarize ✨</span>
							</v-btn>
						</div>
					</div>
					<div v-if="currentView === 'Overview'" style="width: 98%">
						<div class="row-format align-center py-2" v-if="ourTicket.ticketNumber">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">tag</v-icon>
								<div class="ml-2">Number</div>
							</div>
							<div class="overview-right">
								{{ ourTicket.ticketNumber }}
							</div>
						</div>
						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">group</v-icon>
								<div class="ml-2">Client</div>
							</div>
							<div class="overview-right">
								<v-menu
									:close-on-click="true"
									:close-on-content-click="false"
									offset-y
									v-model="clientMenu"
									:disabled="$store.getters.isAccountCollaborator"
								>
									<template v-slot:activator="{ on }">
										<div v-on="on" class="pointer">
											<div class="row-format align-center" v-if="assignedClient">
												<client-avatar
													x-small
													:client="assignedClient"
													:disable-click="true"
												></client-avatar>
												<div class="ml-2">{{ assignedClient.name }}</div>
											</div>
											<div v-else>--</div>
										</div>
									</template>
									<div class="column-format pa-2" style="background-color: var(--v-white-base)">
										<v-text-field
											v-model="clientFilter"
											hide-details
											dense
											outlined
											placeholder="Filter..."
											autofocus
										></v-text-field>
										<div
											class="text-left column-format pt-1 mt-2 show-scrollbar"
											style="max-height: 300px; overflow-y: scroll"
										>
											<div
												@click="
													ourTicket.clientId = client.id;
													clientMenu = false;
												"
												v-for="client in filteredClientList"
												:key="client.id"
												class="row-format align-center px-3 py-2 pointer hover-gray"
												style="border-bottom: 1px solid var(--v-gray_30-base)"
											>
												<client-avatar :client="client" x-small></client-avatar>
												<div class="ml-2 brand-medium">{{ client.name }}</div>
											</div>
										</div>
									</div>
								</v-menu>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">person</v-icon>
								<div class="ml-2">Requester</div>
							</div>
							<div class="overview-right">
								<v-select
									:items="contactList"
									v-model="ourTicket.clientContactId"
									item-value="clientPortalUserId"
									item-text="fullName"
									hide-details
									dense
									flat
									solo
									placeholder="--"
									class="ml-n3 mt-n2"
								>
								</v-select>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">assignment_ind</v-icon>
								<div class="ml-2">Assigned to</div>
							</div>
							<div class="overview-right">
								<v-menu :close-on-click="true" :close-on-content-click="false" offset-y>
									<template v-slot:activator="{ on }">
										<div v-on="on" class="pointer">
											<div v-if="ourTicket.assignedTo.length" class="row-format align-center">
												<assigned-user
													v-on="on"
													v-for="(assignedTo, index) in ourTicket.assignedTo"
													:key="assignedTo"
													:assigned-to="assignedTo"
													:show-name="false"
													empty-label="--"
													:style="
														`${
															index > 0 ? 'margin-left: -6px' : ''
														}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
													"
												></assigned-user>
											</div>
											<div v-else>--</div>
										</div>
									</template>
									<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
										<div
											v-for="user in userList"
											:key="user.userId"
											class="row-format align-center text-left py-1 pointer"
										>
											<input
												style="width: 20px; height: 20px; border-radius: 4px"
												type="checkbox"
												:value="user.userId"
												v-model="ourTicket.assignedTo"
											/>
											<assigned-user
												class="mx-2"
												v-if="user.userId"
												:assigned-to="user.userId"
												:show-name="true"
											></assigned-user>
										</div>
									</div>
								</v-menu>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">page_info</v-icon>
								<div class="ml-2">Request type</div>
							</div>
							<div class="overview-right">
								<v-select
									:items="ticketSettings.ticketTypes"
									v-model="ourTicket.type"
									item-value="id"
									item-text="type"
									hide-details
									dense
									flat
									solo
									placeholder="--"
									class="ml-n3 mt-n2"
								>
								</v-select>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">bolt</v-icon>
								<div class="ml-2">Status</div>
							</div>
							<div class="overview-right">
								<v-select
									:items="statusOptions"
									v-model="ourTicket.status"
									hide-details
									dense
									flat
									solo
									placeholder="--"
									class="ml-n3 mt-n2"
								>
								</v-select>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">event</v-icon>
								<div class="ml-2">Due date</div>
							</div>
							<div class="overview-right">
								<date-selector
									:micro="true"
									:show-year="true"
									micro-font="font-14"
									:date="ourTicket.dueDate"
									label="--"
									@change="ourTicket.dueDate = $event"
								></date-selector>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">timer</v-icon>
								<div class="ml-2">Time worked</div>
							</div>
							<div class="overview-right">
								<div class="pointer" @click="openTimerDetails" style="width: 60px">
									{{ $formatters.formatSecondsFull(totalTimeWorked + runningTimeSeconds) }}
								</div>
								<v-icon
									size="14"
									@click.stop="createTimerEvent"
									class="pointer ml-1"
									v-tippy="{ content: 'Add a time entry for this ticket' }"
									>$plus</v-icon
								>
								<v-icon
									size="14"
									@click.stop="startRunningTimer"
									class="pointer ml-1"
									v-if="!currentTimer"
									v-tippy="{ content: 'Start timer for this ticket' }"
									>$play</v-icon
								>
								<v-icon
									size="14"
									@click.stop="stopRunningTimer"
									color="red"
									class="pointer ml-1"
									v-if="currentTimer"
									v-tippy="{ content: 'Stop running timer for this ticket' }"
									>$stop</v-icon
								>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">schedule</v-icon>
								<div class="ml-2">Ticket created</div>
							</div>
							<div class="overview-right">
								<span class="brand-medium">{{
									HDateTime.fromISO(ourTicket.created).toLocaleString(HDateTime.DATETIME_FULL)
								}}</span>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">update</v-icon>
								<div class="ml-2">Last updated</div>
							</div>
							<div class="overview-right">
								<span class="brand-medium">{{
									HDateTime.fromISO(ourTicket.updated).toLocaleString(HDateTime.DATETIME_FULL)
								}}</span>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="18" class="material-symbols-rounded">mail</v-icon>
								<div class="ml-2">CC List</div>
							</div>
							<div class="overview-right gap-2" style="flex-wrap:  wrap; line-height: 0.8">
								<div
									class="brand-medium row-format justify-end cc"
									v-for="(cc, index) in ourTicket.ccList"
									:key="index"
								>
									<div>{{ cc }}</div>
									<v-icon class="delete pointer" color="error" size="16" @click="confirmDeleteCC(cc)"
										>delete
									</v-icon>
								</div>
								<div class="font-primary pointer" @click="confirmAddCC">+ add</div>
							</div>
						</div>

						<div class="row-format align-center py-2">
							<div class="overview-left">
								<v-icon size="20" class="material-symbols-rounded">done_all</v-icon>
								<div class="ml-2">Mark complete</div>
							</div>
							<div class="overview-right">
								<v-switch
									hide-details
									dense
									class="mb-0 mt-n1"
									v-model="ourTicket.open"
									:true-value="false"
									:false-value="true"
								></v-switch>
							</div>
						</div>

						<v-container
							v-if="ourTicket.formData && ourTicket.formData.answers.length"
							class="font-14 mt-5"
							style="border: 1px solid var(--v-gray_50-base); border-radius: 4px;"
						>
							<v-row dense v-for="(answer, index) in ourTicket.formData.answers" :key="index">
								<v-col cols="12">
									<div v-html="answer.question" class="font-gray_70"></div>
									<div style="white-space: pre-wrap">{{ answer.answer }}</div>
								</v-col>
							</v-row>
						</v-container>
					</div>
					<div v-if="currentView === 'Comments'" class="pb-2" style="width: 98%">
						<editor ref="commentEditor" :init="mceConfig" rows="1" auto-grow v-model="comment"> </editor>

						<div v-cloak @drop.prevent="addDropFile" @dragover.prevent>
							<v-file-input
								v-model="attachments"
								:placeholder="$t('requests.upload-attachments')"
								:label="$t('requests.attachments')"
								multiple
								hide-details
								prepend-icon="attach_file"
							>
								<template v-slot:selection="{ text }">
									<v-chip small label color="primary">
										{{ text }}
									</v-chip>
								</template>
							</v-file-input>
						</div>
						<div class="row-format my-5" style="gap: 12px">
							<v-btn class="super-action" :disabled="!comment" width="200" @click="addComment()"
								>{{ $t('requests.add-comment') }}
							</v-btn>
							<v-checkbox v-model="privateComment" label="Private comment" dense hide-details></v-checkbox>
						</div>

						<div style="height: 16px"></div>

						<v-container>
							<v-row>
								<v-col
									cols="12"
									class="text-left py-2 mb-2"
									:style="
										`border-bottom: 1px solid var(--v-gray_30-base); ${
											comment.commentType === 'PRIVATE'
												? 'background-color: var(--v-yellow_10-base); border-radius: 8px'
												: ''
										}`
									"
									v-for="comment in comments"
									:key="comment.id"
								>
									<div class="font-12 font-gray_70 row-format">
										{{ getCommentBy(comment.commentBy) }} on
										{{ HDateTime.fromISO(comment.created).toLocaleString(HDateTime.DATETIME_FULL) }}
										<div class="ml-auto">
											<span
												v-if="comment.commentType === 'PRIVATE'"
												class="material-symbols-rounded font-14"
												v-tippy="{ content: 'Private comment' }"
												>visibility_off</span
											>
										</div>
									</div>
									<div class="font-12 font-gray_70" v-if="comment.updated">
										Updated:
										{{ HDateTime.fromISO(comment.updated).toLocaleString(HDateTime.DATETIME_FULL) }}
									</div>
									<div :class="comment.commentFormat === 'PlainText' ? 'text-pre-wrap' : ''">
										<span class="font-14" v-html="comment.comment"></span>
									</div>

									<div v-if="comment.attachments && comment.attachments.length" class="py-2">
										<div
											v-for="file in comment.attachments"
											:key="file.fileName"
											class="row-format align-center mb-2"
										>
											<div class="row-format align-center attachment-wrapper" style="position: relative">
												<div class="buttonOverlay row-format align-center gap-2 justify-space-between">
													<v-icon size="20" class="pointer" @click.native="downloadFile(file)">
														$download
													</v-icon>
													<v-icon
														size="20"
														class="pointer"
														@click.native="downloadFile(file, false)"
														v-if="isOpenableFile(file)"
														>open_in_new</v-icon
													>
												</div>
												<v-icon small class="material-symbols-rounded">attach_file</v-icon>
												<div class="font-12">{{ file.fileName }}</div>
											</div>
										</div>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</div>
					<div v-if="currentView === 'Tasks'" style="width: 98%">
						<linked-tasks :ticket="ourTicket"></linked-tasks>
					</div>
				</div>
			</div>
		</div>
		<ai-email-widget v-if="aiDialog" :dialog="aiDialog" mode="draft" @cancel="aiDialog = false" @result="runAiAssist($event)" placeholder="Tell me a little about what your response should say"></ai-email-widget>
	</div>
</template>

<script>
	import HDateTime from '@/modules/utils/HDateTime';
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';
	import TicketMixin from '@/modules/communicator/inbox/tickets/TicketMixin';
	import ConfirmModal from '@/components/ConfirmModal';
	import axios from 'axios';
	import SimpleTextInput from '@/components/SimpleTextInput';
	import ClientAvatar from '@/components/ClientAvatar';
	import AssignedUser from '@/components/AssignedUser';
	import DateSelector from '@/components/DateSelector';
	import editor from '@tinymce/tinymce-vue';
	import LinkedTasks from '@/modules/communicator/inbox/tickets/LinkedTasks';
	import AggregateTimerList from '@/modules/calendar/lists/AggregateTimerList';
	import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
	import DateTime from '@/modules/utils/HDateTime';
	import AiAssistantService from "@/modules/ai/AiAssistantService";
	import marked from "marked";
	import AiEmailWidget from "@/modules/communicator/inbox/email/AiEmailWidget";
	import TimeTrackService from '@/modules/timetracking/TimeTrackService';

	export default {
		name: 'TicketDetail',

		isRightModal: true,

		props: ['id', 'folder', 'clientId'],

		components: { AiEmailWidget, LinkedTasks, ClientAvatar, AssignedUser, DateSelector, editor, PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget') },

		mixins: [TicketMixin],

		data: function() {
			return {
				aiSession: null,
				aiDialog: false,
				aiAssistantService: new AiAssistantService(),
				ticketId: this.id,
				ticketService: new CommunicatorTicketService(),
				timeTrackService: new TimeTrackService(),
				HDateTime: HDateTime,
				hovering: false,
				comments: [],
				comment: null,
				attachments: [],
				isReady: false,
				subject: null,
				privateComment: false,
				clientFilter: null,
				clientMenu: false,
				currentView: 'Overview',
				timerEvents: [],
				timeRefreshTimer: null,
				runningTimeSeconds: 0,
				mergeTickets: [],
				mergeMenu: false,

				mceConfig: {
					menubar: false,
					inline: false,
					paste_as_text: false,
					browser_spellcheck: true,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					placeholder: 'Add a note...',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: false,
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'bold italic underline  | bullist numlist formatting_group| link unlink | ai ',
					],
					content_style:
						"@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); body { font-family: 'Inter', sans-serif; font-size:14px }",

					toolbar_groups: {
						formatting_group: {
							icon: 'format',
							tooltip: 'Formatting',
							items: 'forecolor fontsizeselect | alignleft aligncenter alignright alignjustify |  indent outdent table | removeformat',
						},
					},
					style_formats: [
						{ title: 'Paragraph', format: 'p' },
						{ title: 'Title', format: 'h1' },
						{ title: 'Heading', format: 'h2' },
						{ title: 'Subheading', format: 'h3' },
						{ title: 'Code', format: 'code' },
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('ai', {
							text: '✨Assistant',
							onAction: () => {
								this.aiDialog = true;
							},
						});
					}
				},
			};
		},

		mounted() {
			this.initialize()
		},

		beforeDestroy() {
			this.shutDown();
		},

		methods: {
			initialize: function(){
				this.isReady = false;
				this.getTicketDetails();
				this.ourFolder = this.folder;
				this.getTicketSettings();
				this.setupTicketListeners();
				this.$store.state.eventBus.$on(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
			},

			shutDown: function(){
				this.stopTicketListeners();
				this.$store.state.eventBus.$off(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
				if (this.timeRefreshTimer) {
					clearInterval(this.timeRefreshTimer);
				}
			},

			getMergeTickets: function() {
				this.mergeTickets.splice(0);

				if (this.ourTicket.clientId) {
					this.$store.commit('startLoading');
					this.ticketService
						.getTickets(null, null, true, this.ourTicket.clientId)
						.then((res) => {
							if (res.data.length === 0) {
								this.$store.commit('error', 'No open tickets found for this client.');
								return;
							}
							this.mergeTickets.push(...res.data.filter(t => t.id !== this.ticketId));
							this.mergeTickets.sort((a, b) => {
								if (a.ticketNumber && b.ticketNumbe) {
									return b.ticketNumber - a.ticketNumber;
								} else if (b.ticketNumber) {
									return 1;
								} else if (a.ticketNumber) {
									return -1;
								} else {
									return 0;
								}
							});
							this.mergeMenu = true;
						})
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			mergeIntoTicket: function(ticket){
				let binding = {
					headingText: 'Confirm merge',
					bodyText: `Are you sure you want to merge this ticket into ticket #${ticket.ticketNumber}? This cannot be undone.`
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.$store.commit('startLoading');
						this.ticketService.mergeTicket(this.ourTicket.id,ticket.id).then((res) => {
							this.shutDown();
							this.ticketId = res.data.id;
							this.initialize();
							this.$store.commit('success','Ticket successfully merged');
						}).finally(() => this.$store.commit('stopLoading'));
					}
				})
			},

			getTimerEvents: function() {
				this.timeTrackService
					.getTimerEventsFull(null, null, null, null, null, null, null, this.ourTicket.id)
					.then((res) => {
						this.timerEvents = res.data;
						this.timerEvents.sort(this.sortTimerEvents);
					});
			},

			sortTimerEvents: function(a, b) {
				return b.timerStart.localeCompare(a.timerStart);
			},

			openTimerDetails: function() {
				if (this.timerEvents.length) {
					let binding = {
						events: this.timerEvents,
					};
					this.$store.state.globalModalController.openModal(AggregateTimerList, binding, true, false).then((res) => {
						if (!res) return;
						this.timerEvents.splice(0, this.timerEvents.length);
						this.timerEvents.push(...res.filter((e) => e.ticketId === this.ourTicket.id));
					});
				}
			},

			createTimerEvent: function() {
				let timerStart = this.$DateTime.local();
				let timerEnd = this.$DateTime.local();

				let timerEvent = {
					id: null,
					userId: this.$store.getters.getLoggedInUserId,
					clientId: this.ourTicket.clientId,
					ticketId: this.ourTicket.id,
					timerStart: this.$DateTime
						.local()
						.minus({ minutes: 30 })
						.toISO(),
					timerEnd: this.$DateTime.local().toISO(),
					duration: timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']),
				};

				let binding = {
					timerEvent: timerEvent,
					client: this.client,
				};

				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					if (res) {
						this.addNewTimer(res);
					}
				});
			},

			addNewTimer: function(event) {
				if (event.ticketId === this.ourTicket.id) {
					let start = DateTime.fromISO(event.timerStart);
					let end = DateTime.fromISO(event.timerEnd);
					event.duration = Math.round(end.diff(start, ['seconds']).seconds);
					this.timerEvents.push(event);
				}
			},

			startRunningTimer: function() {
				let defaults = {
					clientId: this.ourTicket.clientId,
					ticketId: this.ourTicket.id,
				};

				this.$store.state.eventBus.$emit('start-timer', defaults);
			},

			stopRunningTimer: function() {
				this.$store.state.eventBus.$emit('stop-timer');
			},

			isTimerInScope: function(timer) {
				if (!timer || !this.ourTicket) {
					return false;
				}

				if (timer.ticketId === this.ourTicket.id) {
					return true;
				} else {
					return false;
				}
			},

			refreshTime: function() {
				const timerStart = this.$DateTime
					.fromISO(this.currentTimer.timerStart)
					.plus({ seconds: this.currentTimer.pausedSeconds });
				let endTime;
				if (this.currentTimer.pausedAt) {
					endTime = this.$DateTime.fromISO(this.currentTimer.pausedAt);
				} else {
					endTime = this.$DateTime.local();
				}

				this.runningTimeSeconds = Math.ceil(endTime.diff(timerStart, ['seconds']).seconds);
			},

			isOpenableFile(s3File) {
				if (
					s3File.fileType === 'JPG' ||
					s3File.fileType === 'PNG' ||
					s3File.fileType === 'GIF' ||
					s3File.fileType === 'PDF'
				) {
					return true;
				} else {
					return false;
				}
			},

			setView: function(view) {
				this.currentView = view;
			},

			confirmDeleteCC: function(cc) {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to remove ${cc} as a CC on this this ticket?`,
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.ticketService.removeCC(this.ourTicket.id, cc).then(() => {
							let ix = this.ourTicket.ccList.findIndex((c) => c === cc);
							if (ix > -1) {
								this.ourTicket.ccList.splice(ix, 1);
							}
						});
					}
				});
			},

			confirmAddCC: function() {
				let binding = {
					label: 'Please enter the email address you would like to add.',
				};
				this.$store.state.globalModalController.openModal(SimpleTextInput, binding).then((res) => {
					if (res) {
						this.ticketService
							.addCC(this.ourTicket.id, res)
							.then(() => {
								this.ourTicket.ccList.push(res);
							})
							.catch((err) => this.$store.commit('error', err.response.data.message));
					}
				});
			},

			setupTicketListeners: function() {
				this.$store.state.eventBus.$on(
					`${this.$store.getters.getMessageChannelPrefix}.ticketUpdated.${this.ticketId}`,
					this.ticketNotification
				);
				this.$store.state.eventBus.$on(
					`${this.$store.getters.getMessageChannelPrefix}.ticketComment.${this.ticketId}`,
					this.ticketNotification
				);
				this.$store.state.eventBus.$on(
					`${this.$store.getters.getMessageChannelPrefix}.ticketDeleted.${this.ticketId}`,
					this.ticketNotification
				);
			},

			stopTicketListeners: function() {
				this.$store.state.eventBus.$off(
					`${this.$store.getters.getMessageChannelPrefix}.ticketUpdated.${this.ticketId}`,
					this.ticketNotification
				);
				this.$store.state.eventBus.$off(
					`${this.$store.getters.getMessageChannelPrefix}.ticketComment.${this.ticketId}`,
					this.ticketNotification
				);
				this.$store.state.eventBus.$off(
					`${this.$store.getters.getMessageChannelPrefix}.ticketDeleted.${this.ticketId}`,
					this.ticketNotification
				);
			},

			ticketNotification: function(event) {
				if (event.channel.includes('ticketUpdated')) {
					this.ourTicket = event.message;
				} else if (event.channel.includes('ticketComment')) {
					this.ourTicket = event.message.ticket;
					this.commentAdded(event.message.comments[0]);
				} else if (event.channel.includes('ticketDeleted')) {
					this.$emit('back');
				}
			},

			commentAdded: function(comment) {
				let ix = this.comments.findIndex((c) => c.id === comment.id);
				if (ix === -1) {
					this.comments.unshift(comment);
				}
			},

			addComment: function() {
				return new Promise((resolve, reject) => {
					if (this.comment) {
						let commentType = this.privateComment ? 'PRIVATE' : 'PUBLIC';
						this.$store.commit('startLoading');
						this.ticketService
							.addComment(this.ourTicket.id, this.comment, commentType, this.attachments)
							.then((res) => {
								this.commentAdded(res.data);
								this.comment = null;
								this.$refs.commentEditor.editor.setContent('');
								this.attachments.splice(0, this.attachments.length);
								this.ourTicket.updated = HDateTime.now().toISO();
								this.ourTicket.lastComment = res.data.comment;
								resolve(res);
							})
							.catch((err) => reject(err))
							.finally(() => this.$store.commit('stopLoading'));
					} else {
						resolve();
					}
				});
			},

			getTicketDetails: function() {
				this.ticketService.getTicketDetails(this.ticketId).then((res) => {
					this.ourTicket = res.data.ticket;
					this.comments.splice(0, this.comments.length);
					this.comments.push(...res.data.comments);
					this.subject = this.ourTicket.subject;
					this.isReady = true;
					this.getTimerEvents();
				});
			},

			getCommentBy: function(id) {
				let contact = this.$store.getters.getContactByClientPortalId(id);
				if (contact) {
					return contact.firstName + ' ' + contact.lastName;
				} else {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === id);
					if (user) {
						return user.firstName + ' ' + user.lastName;
					} else {
						return ' -- ';
					}
				}
			},

			addDropFile: function(e) {
				this.attachments.push(...Array.from(e.dataTransfer.files));
			},

			downloadFile: function(s3File, download = true) {
				this.ticketService.getSignedUrl(this.ourTicket.id, s3File.fileName).then((res) => {
					let signedUrl = res.data;

					if (download === false) {
						window.open(signedUrl, '_blank');
					} else {
						axios({
							url: signedUrl, //your url
							method: 'GET',
							responseType: 'blob', // important
						}).then((response) => {
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', s3File.fileName); //or any other extension
							document.body.appendChild(link);
							link.click();
						});
					}
				});
			},

			saveTicket: function(close = false) {
				this.addComment().then(() => {
					this.$store.commit('startLoading');
					this.ticketService
						.updateTicket(this.ourTicket.id, this.ourTicket)
						.then((res) => {
							this.ourTicket = res.data;
							if (close) {
								this.$emit('result', this.ourTicket);
							} else {
								this.$store.commit('success', 'Ticket updated');
							}
						})
						.finally(() => this.$store.commit('stopLoading'));
				});
			},

			confirmDelete: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this ticket?  This cannot be undone.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.ticketService.deleteTicket(this.ourTicket.id).then(() => {
							this.ourTicket.deleted = true;
							this.$emit('result', this.ourTicket);
						});
					}
				});
			},

			handleClose: function() {
				this.saveTicket(true);
			},

			async initializeAiSession(){
				if(!this.aiSession){
					let createRequest = {
						model: 'gpt-4o-mini',
						useCase: 'EmailResponse',
						context: [],
					};

					createRequest.context.push({role:'system',content:'You are helping to draft an email.'});
					createRequest.context.push({role:'system',content:'Your response should only contain the actual email body content without the subject line.'});
					createRequest.context.push({role:'system',content:`The senders name is ${this.$store.state.loggedInUser.firstName}`});
					let historicalContent = '';

					this.comments.forEach(c => {
						historicalContent += `${c.commentUserName} Said: ${c.comment}; `
					})

					createRequest.context.push({
						role: 'system',
						content: `For context here are all the previous messages on the thread: ${historicalContent}`
					});

					let result =  await this.aiAssistantService.createChatSession(createRequest);
					this.aiSession = result.data;
				}
			},

			removeMarkdownCharacters: function(text) {
				return text
						.replace(/[_*~`>#+\-=|{}[\]()]/g, "") // Remove common Markdown characters
						.replace(/^\s*\n/gm, "") // Remove empty lines

			},

			async runAiAssist(params){
				this.$store.commit('startLoading');

				this.aiDialog = false;
				await this.initializeAiSession();
				let instructions = `Please write me a new email in a ${params.tone} tone.  The email content should include: ${params.input}.`
				let newContent = '';
				let editor = this.$refs.commentEditor.editor;
				editor.setContent('');

				await this.aiAssistantService.continueSessionStreaming(this.aiSession.id, instructions, {
					onData: (data) => {
						newContent += data;
						editor.execCommand('mceInsertContent', false, this.removeMarkdownCharacters(data));
					},
					onComplete: () => {
						this.$store.commit('stopLoading');
						let formatted = marked(newContent, {breaks: true});
						editor.setContent(formatted);
					},
					onError: (error) => {
						console.log(error);
						this.$store.commit('stopLoading');
					}
				})
			},

			async generateSummary() {
				try {
					this.$store.commit('startLoading');
					let createRequest = {
						model: 'gpt-4o-mini',
						useCase: 'Summary',
						context: [],
						fetchResponse: true,
					};

					createRequest.context.push({
						role:'system',
						content:'You should provide a clear and concise summarization of the data provided by the user. Format the response with clear sections, bold headers, and properly formatted code blocks using markdown.'
					});

					createRequest.context.push({
						role: 'user',
						content: `Subject: ${this.ourTicket.subject}; Type: ${this.ourTicket.type}; Status: ${this.ourTicket.status}; Created: ${DateTime.fromISO(this.ourTicket.created).toLocaleString(DateTime.DATETIME_MED)}`
					});

					createRequest.context.push({
						role: 'user',
						content: `As of ${DateTime.now().toLocaleString(DateTime.DATETIME_MED)} the ticket has been worked on for ${this.$formatters.formatSecondsFull(this.totalTimeWorked + this.runningTimeSeconds)}`
					});

					this.comments.forEach(c => {
						createRequest.context.push({
							role: 'user',
							content: `${c.commentUserName} Said: ${c.comment}`
						});
					})

					if(this.ourTicket.formData && this.ourTicket.formData.answers) {
						for (let i = 0; i < this.ourTicket.formData.answers.length; i++) {
							let answer = this.ourTicket.formData.answers[i];
							createRequest.context.push({
								role: 'user',
								content: `Question: ${answer.question}; Answer: ${answer.answer}`
							});
						}
					}

					createRequest.context.push({
						role: 'user',
						content: 'Please summarize this support ticket.  Include sections for topics, important dates, and a high level summary.  Only output the result, no other content.'
					});

					let result = await new AiAssistantService().createChatSession(createRequest);
					let aiSession = result.data;
					let lastMessage = aiSession.messages[aiSession.messages.length - 1];
					let summary = lastMessage.content;
					this.ourTicket.summary = marked(summary,{breaks:true});
					this.saveTicket(false);
				} catch (err) {
					this.$store.commit('error', err.response?.data?.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},
		},

		watch: {
			currentTimer: {
				immediate: true,
				handler: function(val) {
					if (val && !this.timeRefreshTimer) {
						this.timeRefreshTimer = setInterval(this.refreshTime, 1000);
					} else if (!val && this.timeRefreshTimer) {
						clearInterval(this.timeRefreshTimer);
						this.timeRefreshTimer = null;
					}

					if (!val) {
						this.runningTimeSeconds = 0;
					}
				},
			},

			peopleInThread: {
				immediate: true,
				handler: function(val) {
					this.$emit('set-contacts', val);
				},
			},
		},

		computed: {
			totalTimeWorked: function() {
				return this.timerEvents.reduce((sum, { duration }) => sum + duration, 0);
			},

			currentTimer: function() {
				let timer = this.$store.state.currentTimer;
				if (timer && this.isTimerInScope(timer)) {
					return timer;
				} else {
					return null;
				}
			},

			assignedClient: function() {
				if (this.ourTicket && this.ourTicket.clientId) {
					return this.$store.getters.getClientById(this.ourTicket.clientId);
				} else {
					return null;
				}
			},

			fixedClientList: function() {
				let result = [...this.$store.getters.clients];
				if (this.originalClient && result.findIndex((c) => c.id === this.originalClient.id) === -1) {
					result.push(this.originalClient);
				}
				result.sort((a, b) => a.name.localeCompare(b.name));
				result.unshift({ id: null, name: '--' });

				return result;
			},

			filteredClientList: function() {
				if (this.clientFilter) {
					return this.fixedClientList.filter(
						(c) => c.name && c.name.toLowerCase().includes(this.clientFilter.toLowerCase())
					);
				} else {
					return this.fixedClientList;
				}
			},

			statusOptions: function() {
				let type = this.ticketSettings.ticketTypes.find((t) => t.id === this.ourTicket.type);
				if (type) {
					return type.statusList;
				} else {
					return [];
				}
			},

			contactList: function() {
				if (this.ourTicket) {
					return this.$store.getters.contacts.filter((c) => c.clientId === this.ourTicket.clientId);
				} else {
					return [];
				}
			},

			client: function() {
				if (this.ourTicket && this.ourTicket.clientId) {
					return this.$store.state.clientCache.find((c) => c.id === this.ourTicket.clientId);
				} else {
					return null;
				}
			},

			requester: function() {
				if (this.ourTicket && this.ourTicket.clientContactId) {
					return this.contactList.find((c) => c.clientPortalUserId === this.ourTicket.clientContactId);
				} else {
					return null;
				}
			},

			userList: function() {
				let users = [];

				for (let i = 0; i < this.$store.state.usersForAccount.length; i++) {
					let user = this.$store.state.usersForAccount[i];
					if (user.userType === 'OWNER' || user.userType === 'FULL_USER' || user.userType === 'IMPLEMENTER') {
						users.push(user);
					} else if (user.userType === 'RESTRICTED_USER' && user.featureAccess && user.featureAccess.tickets) {
						users.push(user);
					}
				}

				return users;
			},

			peopleInThread: function() {
				let result = [this.contact];

				if (!this.ourTicket || !this.ourTicket.ccList) {
					return result;
				}

				this.ourTicket.ccList.forEach((p) => {
					let contact = this.$store.getters.getContactByEmail(p);

					if (contact) {
						result.push(contact);
					} else {
						result.push({
							id: null,
							email: p,
							phone: null,
							firstName: p.split('@')[0],
							lastName: '',
						});
					}
				});
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.cc {
		.delete {
			display: none;
		}

		&:hover {
			.delete {
				display: unset;
			}
		}
	}

	#ticket-detail {
		padding-left: 12px;
		padding-top: 18px;

		.overview-left {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: fit-content;
			color: var(--v-gray_70-base);
			font-size: 14px;
			font-weight: 500;
			width: 140px;
			min-width: 140px;
			max-width: 140px;
			margin-right: 16px;
		}

		.overview-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 14px;
			font-weight: 500;
			flex: 1 1 auto;
		}

		.attachment-wrapper {
			background-color: var(--v-gray_30-base);
			border-radius: 16px;
			padding: 4px 8px;
		}

		.buttonOverlay {
			position: absolute;
			top: 0px;
			left: 0px;
			z-index: 400;
			width: 100%;
			height: 100%;
			padding-left: 8px;
			padding-right: 8px;
			background-color: var(--v-white-base);
			border-radius: 16px;
			opacity: 0;
			transition: all ease 250ms;

			&:hover {
				opacity: 0.9;
			}
		}
	}
</style>
