var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "panel-modal", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.cancelEditDeliverable(true, false)
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c(
                  "v-icon",
                  { staticClass: "mr-1", attrs: { color: "black" } },
                  [_vm._v("splitscreen")]
                ),
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _vm.isReady
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "super-action",
                            on: {
                              click: function($event) {
                                return _vm.saveAndCloseInternal(true, true)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                        )
                      : _vm._e(),
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": true,
                          "close-on-click": true,
                          bottom: "",
                          "nudge-bottom": "30"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "ml-1",
                                        attrs: { icon: "" }
                                      },
                                      scope.on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            scope.value
                                              ? "$arrowUp"
                                              : "$moreHorizontal"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1638167150
                        )
                      },
                      [
                        _c("div", { staticClass: "more-menu" }, [
                          _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: { click: _vm.getShareLink }
                            },
                            [_vm._v("Share")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: { click: _vm.confirmDuplicate }
                            },
                            [_vm._v("Duplicate")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: { click: _vm.archiveDeliverable }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.deliverable.archived
                                      ? "Unarchive"
                                      : "Archive"
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: {
                                click: function($event) {
                                  _vm.deleteDeliverableDialog = true
                                }
                              }
                            },
                            [_vm._v("Delete")]
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "panel-modal-body text-left" }, [
            _c("div", { attrs: { id: "deliverable-detail" } }, [
              _c("input", {
                attrs: {
                  id: "deliverableLink",
                  name: "deliverableLink",
                  type: "hidden"
                },
                domProps: { value: _vm.deliverableLink }
              }),
              _c(
                "div",
                { staticClass: "row-format" },
                [
                  _c("v-text-field", {
                    attrs: {
                      autofocus: "",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      placeholder: "Task name"
                    },
                    model: {
                      value: _vm.deliverable.name,
                      callback: function($$v) {
                        _vm.$set(_vm.deliverable, "name", $$v)
                      },
                      expression: "deliverable.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-6 mb-4 row-format align-center gap-3" },
                [
                  _c(
                    "div",
                    {
                      class:
                        "page-link " +
                        (_vm.currentView === "Overview"
                          ? "page-link-active"
                          : ""),
                      on: {
                        click: function($event) {
                          return _vm.setView("Overview")
                        }
                      }
                    },
                    [_c("div", [_vm._v("Overview")])]
                  ),
                  _c(
                    "div",
                    {
                      class:
                        "page-link " +
                        (_vm.currentView === "Files" ? "page-link-active" : ""),
                      on: {
                        click: function($event) {
                          return _vm.setView("Files")
                        }
                      }
                    },
                    [_c("div", [_vm._v("Files")])]
                  ),
                  _c(
                    "div",
                    {
                      class:
                        "page-link " +
                        (_vm.currentView === "Comments"
                          ? "page-link-active"
                          : ""),
                      on: {
                        click: function($event) {
                          return _vm.setView("Comments")
                        }
                      }
                    },
                    [_c("div", [_vm._v("Comments")])]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "show-scrollbar",
                  staticStyle: {
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentView === "Overview",
                          expression: "currentView === 'Overview'"
                        }
                      ],
                      staticStyle: { width: "98%" }
                    },
                    [
                      _vm.deliverable.archived
                        ? _c(
                            "div",
                            { staticClass: "row-format align-center mb-2" },
                            [
                              _c("v-icon", { attrs: { color: "secondary" } }, [
                                _vm._v("lock")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-2 brand-bold font-secondary"
                                },
                                [_vm._v("Archived")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", [
                        _c("div", { staticClass: "row-format align-center" }, [
                          _c(
                            "div",
                            { staticClass: "overview-left" },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v("bolt")
                              ]),
                              _c("div", { staticClass: "ml-2" }, [
                                _vm._v("Status")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "overview-right" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-click": true,
                                    "close-on-content-click": true,
                                    "offset-y": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "deliverableStatus row-format align-center pointer",
                                                  style:
                                                    "--status-color:" +
                                                    _vm.deliverableStatus
                                                      .hexColor
                                                },
                                                on
                                              ),
                                              [
                                                _c("div", {
                                                  staticClass: "statusBox"
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-no-wrap"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.deliverableStatus
                                                          .label
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3504976726
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-left pa-3 font-14",
                                      staticStyle: {
                                        "background-color":
                                          "var(--v-white-base)"
                                      }
                                    },
                                    _vm._l(
                                      _vm.$store.state.deliverableStatusList
                                        .statusList,
                                      function(status) {
                                        return _c(
                                          "div",
                                          {
                                            key: status.id,
                                            staticClass: "py-1 pointer",
                                            on: {
                                              click: function($event) {
                                                _vm.deliverable.statusId =
                                                  status.id
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "deliverableStatus row-format align-center highlight-hover",
                                                style:
                                                  "--status-color:" +
                                                  status.hexColor
                                              },
                                              [
                                                _c("div", {
                                                  staticClass: "statusBox"
                                                }),
                                                _c("div", [
                                                  _vm._v(_vm._s(status.label))
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "row-format align-center py-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("person")
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Assigned to")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "overview-right" },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-click": true,
                                      "close-on-content-click": false,
                                      "offset-y": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  { staticClass: "pointer" },
                                                  on
                                                ),
                                                [
                                                  _vm.deliverable.assignedToList
                                                    .length
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row-format align-center"
                                                        },
                                                        _vm._l(
                                                          _vm.deliverable
                                                            .assignedToList,
                                                          function(
                                                            assignedTo,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "assigned-user",
                                                              _vm._g(
                                                                {
                                                                  key: assignedTo,
                                                                  style:
                                                                    (index > 0
                                                                      ? "margin-left: -6px"
                                                                      : "") +
                                                                    "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                                                                    index,
                                                                  attrs: {
                                                                    "assigned-to": assignedTo,
                                                                    "show-name": false,
                                                                    "empty-label":
                                                                      "--"
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _c("div", [_vm._v("--")])
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1818476486
                                    )
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-left pa-3 font-14",
                                        staticStyle: {
                                          "background-color":
                                            "var(--v-white-base)"
                                        }
                                      },
                                      _vm._l(_vm.userList, function(user) {
                                        return _c(
                                          "div",
                                          {
                                            key: user.userId,
                                            staticClass:
                                              "row-format align-center text-left py-1 pointer"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.deliverable
                                                      .assignedToList,
                                                  expression:
                                                    "deliverable.assignedToList"
                                                }
                                              ],
                                              staticStyle: {
                                                width: "20px",
                                                height: "20px",
                                                "border-radius": "4px"
                                              },
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                value: user.userId,
                                                checked: Array.isArray(
                                                  _vm.deliverable.assignedToList
                                                )
                                                  ? _vm._i(
                                                      _vm.deliverable
                                                        .assignedToList,
                                                      user.userId
                                                    ) > -1
                                                  : _vm.deliverable
                                                      .assignedToList
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.deliverable
                                                        .assignedToList,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = user.userId,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.deliverable,
                                                          "assignedToList",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.deliverable,
                                                          "assignedToList",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.deliverable,
                                                      "assignedToList",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            user.userId
                                              ? _c("assigned-user", {
                                                  staticClass: "mx-2",
                                                  attrs: {
                                                    "assigned-to": user.userId,
                                                    "show-name": true
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format align-center py-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-symbols-rounded",
                                    attrs: { size: "20" }
                                  },
                                  [_vm._v("group")]
                                ),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Client")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "overview-right" },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-click": true,
                                      "close-on-content-click": false,
                                      "offset-y": "",
                                      disabled:
                                        _vm.$store.getters.isAccountCollaborator
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  { staticClass: "pointer" },
                                                  on
                                                ),
                                                [
                                                  _vm.assignedClient
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row-format align-center"
                                                        },
                                                        [
                                                          _c("client-avatar", {
                                                            attrs: {
                                                              "x-small": "",
                                                              client:
                                                                _vm.assignedClient,
                                                              "disable-click": true
                                                            }
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ml-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .assignedClient
                                                                    .name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c("div", [_vm._v("--")])
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1881710377
                                    ),
                                    model: {
                                      value: _vm.clientMenu,
                                      callback: function($$v) {
                                        _vm.clientMenu = $$v
                                      },
                                      expression: "clientMenu"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "column-format pa-2",
                                        staticStyle: {
                                          "background-color":
                                            "var(--v-white-base)"
                                        }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            outlined: "",
                                            placeholder: "Filter...",
                                            autofocus: ""
                                          },
                                          model: {
                                            value: _vm.clientFilter,
                                            callback: function($$v) {
                                              _vm.clientFilter = $$v
                                            },
                                            expression: "clientFilter"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-left column-format pt-1 mt-2 show-scrollbar",
                                            staticStyle: {
                                              "max-height": "300px",
                                              "overflow-y": "scroll"
                                            }
                                          },
                                          _vm._l(
                                            _vm.filteredClientList,
                                            function(client) {
                                              return _c(
                                                "div",
                                                {
                                                  key: client.id,
                                                  staticClass:
                                                    "row-format align-center px-3 py-2 pointer hover-gray",
                                                  staticStyle: {
                                                    "border-bottom":
                                                      "1px solid var(--v-gray_30-base)"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.deliverable.clientId =
                                                        client.id
                                                      _vm.clientMenu = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("client-avatar", {
                                                    attrs: {
                                                      client: client,
                                                      "x-small": ""
                                                    }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-2 brand-medium"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(client.name)
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format align-center py-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-symbols-rounded",
                                    attrs: { size: "20" }
                                  },
                                  [_vm._v("folder")]
                                ),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Project")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "overview-right" },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-click": true,
                                      "close-on-content-click": false,
                                      "offset-y": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  { staticClass: "pointer" },
                                                  on
                                                ),
                                                [
                                                  _vm.assignedProject
                                                    ? _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.assignedProject
                                                              .name
                                                          )
                                                        )
                                                      ])
                                                    : _c("div", [_vm._v("--")])
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2459742074
                                    ),
                                    model: {
                                      value: _vm.projectMenu,
                                      callback: function($$v) {
                                        _vm.projectMenu = $$v
                                      },
                                      expression: "projectMenu"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "column-format pa-2",
                                        staticStyle: {
                                          "background-color":
                                            "var(--v-white-base)"
                                        }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            outlined: "",
                                            placeholder: "Filter...",
                                            autofocus: ""
                                          },
                                          model: {
                                            value: _vm.projectFilter,
                                            callback: function($$v) {
                                              _vm.projectFilter = $$v
                                            },
                                            expression: "projectFilter"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-left column-format pt-1 mt-2 show-scrollbar",
                                            staticStyle: {
                                              "max-height": "300px",
                                              "overflow-y": "scroll"
                                            }
                                          },
                                          _vm._l(
                                            _vm.filteredProjectList,
                                            function(project) {
                                              return _c(
                                                "div",
                                                {
                                                  key: project.id,
                                                  staticClass:
                                                    "row-format align-center px-3 py-2 pointer hover-gray",
                                                  staticStyle: {
                                                    "border-bottom":
                                                      "1px solid var(--v-gray_30-base)"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.deliverable.projectId =
                                                        project.id
                                                      _vm.projectMenu = false
                                                    }
                                                  }
                                                },
                                                [
                                                  project.client
                                                    ? _c("client-avatar", {
                                                        attrs: {
                                                          client:
                                                            project.client,
                                                          "x-small": ""
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-2 brand-medium"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(project.name)
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format align-center py-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-symbols-rounded",
                                    attrs: { size: "20" }
                                  },
                                  [_vm._v("confirmation_number")]
                                ),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Ticket")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "overview-right" },
                              [
                                _c("linked-ticket", {
                                  attrs: { deliverable: _vm.deliverable },
                                  model: {
                                    value: _vm.deliverable.ticketId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.deliverable, "ticketId", $$v)
                                    },
                                    expression: "deliverable.ticketId"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format align-center py-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("today")
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Start date")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "overview-right" },
                              [
                                _c("date-selector", {
                                  attrs: {
                                    micro: true,
                                    "show-year": true,
                                    "micro-font": "font-14",
                                    date: _vm.deliverable.startDate,
                                    label: "--"
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.deliverable.startDate = $event
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format align-center py-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("event")
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Due date")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "overview-right" },
                              [
                                _c("date-selector", {
                                  attrs: {
                                    micro: true,
                                    "show-year": true,
                                    "micro-font": "font-14",
                                    date: _vm.deliverable.dueDate,
                                    label: "--"
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.deliverable.dueDate = $event
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format align-center py-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("priority_high")
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Priority")
                                ])
                              ],
                              1
                            ),
                            _c("div", { staticClass: "overview-right" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.deliverable.priority,
                                    expression: "deliverable.priority"
                                  }
                                ],
                                staticClass: "numberInput",
                                attrs: {
                                  type: "number",
                                  oninput:
                                    "this.value=(parseInt(this.value)||0)",
                                  step: "1"
                                },
                                domProps: { value: _vm.deliverable.priority },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.deliverable,
                                      "priority",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format align-center py-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-symbols-rounded",
                                    attrs: { size: "20" }
                                  },
                                  [_vm._v("timer")]
                                ),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Time worked")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "overview-right" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pointer",
                                    staticStyle: { width: "60px" },
                                    on: { click: _vm.openTimerDetails }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatters.formatSecondsFull(
                                            _vm.totalTimeWorked +
                                              _vm.runningTimeSeconds
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: {
                                          content:
                                            "Add a time entry for this task"
                                        },
                                        expression:
                                          "{ content: 'Add a time entry for this task' }"
                                      }
                                    ],
                                    staticClass: "pointer ml-1",
                                    attrs: { size: "14" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.createTimerEvent.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("$plus")]
                                ),
                                !_vm.currentTimer
                                  ? _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "tippy",
                                            rawName: "v-tippy",
                                            value: {
                                              content:
                                                "Start timer for this task"
                                            },
                                            expression:
                                              "{ content: 'Start timer for this task' }"
                                          }
                                        ],
                                        staticClass: "pointer ml-1",
                                        attrs: { size: "14" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.startRunningTimer.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("$play")]
                                    )
                                  : _vm._e(),
                                _vm.currentTimer
                                  ? _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "tippy",
                                            rawName: "v-tippy",
                                            value: {
                                              content:
                                                "Stop running timer for this task"
                                            },
                                            expression:
                                              "{ content: 'Stop running timer for this task' }"
                                          }
                                        ],
                                        staticClass: "pointer ml-1",
                                        attrs: { size: "14", color: "red" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.stopRunningTimer.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("$stop")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _vm.showFeeSection
                        ? _c("div", { staticClass: "row-format py-2" }, [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-symbols-rounded",
                                    attrs: { size: "20" }
                                  },
                                  [_vm._v("money")]
                                ),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Fees")
                                ])
                              ],
                              1
                            ),
                            _c("div", { staticClass: "overview-right" }, [
                              _c(
                                "div",
                                { staticClass: "column-format text=;eft" },
                                [
                                  _c("per-item-pricing", {
                                    attrs: {
                                      "hide-icon": true,
                                      minimal: true,
                                      product: _vm.deliverable.product,
                                      currency: _vm.currency,
                                      quantity: _vm.deliverable.quantity,
                                      editable: !_vm.deliverable.invoiceId,
                                      hours: _vm.perItemHoursWorked
                                    },
                                    on: {
                                      "product-selected": function($event) {
                                        return _vm.handleProductSelected($event)
                                      },
                                      "quantity-updated": function($event) {
                                        return _vm.handleQuantityUpdated($event)
                                      }
                                    }
                                  }),
                                  _vm.deliverable.invoiceId
                                    ? _c(
                                        "div",
                                        { staticClass: "font-12 font-gray_70" },
                                        [
                                          _vm._v(
                                            " Included in invoice " +
                                              _vm._s(
                                                _vm.deliverable.invoiceNumber
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._l(_vm.fields, function(field) {
                        return _c(
                          "div",
                          { key: field.id, staticClass: "row-format py-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "overview-left" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-symbols-rounded",
                                    attrs: { size: "20" }
                                  },
                                  [_vm._v(_vm._s(field.icon))]
                                ),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(field.name))
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "overview-right" },
                              [
                                _c("custom-field", {
                                  attrs: {
                                    field: field,
                                    value: _vm.getCustomValue(field.id)
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.handleCustomFieldChange($event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _c("div", { staticClass: "mt-6" }, [
                        _c("div", { staticClass: "brand-bold mb-4" }, [
                          _vm._v("Description")
                        ]),
                        _c(
                          "div",
                          { staticClass: "font-14" },
                          [
                            _c("editor", {
                              ref: "Description",
                              staticClass: "no-wrap-editor px-2",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "api-key": _vm.$store.getters.getTinyMceKey,
                                inline: true,
                                init: _vm.mceConfig,
                                spellcheck: true
                              },
                              on: {
                                onblur: function($event) {
                                  return _vm.convertMarkdown()
                                }
                              },
                              model: {
                                value: _vm.deliverable.description,
                                callback: function($$v) {
                                  _vm.$set(_vm.deliverable, "description", $$v)
                                },
                                expression: "deliverable.description"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "my-6" }, [
                        _c("div", { staticClass: "brand-bold mb-4" }, [
                          _vm._v("Subtasks")
                        ]),
                        _c(
                          "div",
                          { staticClass: "font-14" },
                          [
                            _c("tasks", {
                              ref: "tasks",
                              attrs: { "detail-view": true },
                              model: {
                                value: _vm.deliverable.tasks,
                                callback: function($$v) {
                                  _vm.$set(_vm.deliverable, "tasks", $$v)
                                },
                                expression: "deliverable.tasks"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm.approvals.length
                        ? _c("div", { staticClass: "mb-6" }, [
                            _c("div", { staticClass: "brand-bold mb-4" }, [
                              _vm._v("Client workflow")
                            ]),
                            _c(
                              "div",
                              { staticClass: "font-14" },
                              _vm._l(_vm.approvals, function(approval) {
                                return _c(
                                  "ul",
                                  {
                                    key: approval.id,
                                    staticClass:
                                      "font-gray_80 font-14 text-left"
                                  },
                                  [
                                    _c("li", [
                                      _vm._v(
                                        " " +
                                          _vm._s(approval.approverName) +
                                          " on " +
                                          _vm._s(
                                            _vm.DateTime.fromISO(
                                              approval.approvedAt
                                            ).toFormat("DD")
                                          ) +
                                          " from [" +
                                          _vm._s(approval.approvalStatus) +
                                          "] status "
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentView === "Files",
                          expression: "currentView === 'Files'"
                        }
                      ],
                      staticStyle: { width: "98%" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-14" },
                        [
                          _c("file-attachments", {
                            attrs: { deliverable: _vm.deliverable }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentView === "Comments",
                          expression: "currentView === 'Comments'"
                        }
                      ],
                      staticStyle: { width: "98%" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-14" },
                        [
                          _c("activity", {
                            ref: "activity",
                            attrs: {
                              client: _vm.client,
                              project: _vm.project,
                              deliverable: _vm.deliverable,
                              "project-service": _vm.projectService,
                              "render-index": _vm.renderIndex,
                              userList: _vm.isInClientApproval
                                ? _vm.fullUserList
                                : _vm.userList
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          ]),
          _c("confirm-dialog", {
            attrs: {
              dialog: _vm.deleteDeliverableDialog,
              "heading-text": _vm.$t("global.confirm"),
              "body-text": _vm.$t("projects.deliverable-delete-confirm"),
              "yes-text": _vm.$t("global.delete"),
              "no-text": _vm.$t("global.cancel")
            },
            on: {
              close: function($event) {
                _vm.deleteDeliverableDialog = false
              },
              confirm: function($event) {
                return _vm.deleteDeliverable()
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "brand-medium font-18" }, [
      _c("div", [_vm._v("Task details")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }