var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left font-14 pb-8 column-format gap-4" },
    [
      _c("div", { staticClass: "column-format" }, [
        _c(
          "div",
          { staticClass: "row-format align-center" },
          [
            _c("client-avatar", { attrs: { large: "", client: _vm.client } }),
            _c(
              "div",
              { staticClass: "row-format align-center ml-auto gap-2" },
              [
                _vm.client.archive
                  ? _c(
                      "div",
                      { staticClass: "font-gray_70" },
                      [
                        _c("v-icon", { attrs: { color: "gray_70" } }, [
                          _vm._v("archive")
                        ]),
                        _vm._v(" Archived")
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.client.integrationKeys.quickbooksId ||
                _vm.client.integrationKeys.xeroId
                  ? _c(
                      "div",
                      {
                        staticClass: "row-format align-center accounting",
                        staticStyle: { position: "relative" }
                      },
                      [
                        _vm.client.integrationKeys.quickbooksId
                          ? _c(
                              "v-icon",
                              {
                                directives: [
                                  { name: "tippy", rawName: "v-tippy" }
                                ],
                                attrs: {
                                  size: "20",
                                  content:
                                    "Linked to QBO record: " +
                                    _vm.client.integrationKeys.quickbooksId
                                }
                              },
                              [_vm._v("$quickbooks")]
                            )
                          : _vm._e(),
                        _vm.client.integrationKeys.xeroId
                          ? _c(
                              "v-icon",
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy:content",
                                    value:
                                      "Linked to Xero record: " +
                                      _vm.client.integrationKeys.xeroId,
                                    expression:
                                      "`Linked to Xero record: ${client.integrationKeys.xeroId}`",
                                    arg: "content"
                                  }
                                ],
                                attrs: { size: "20" }
                              },
                              [_vm._v("$xero")]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "unlink",
                            staticStyle: {
                              "background-color": "var(--v-white-base)",
                              position: "absolute",
                              right: "12px",
                              top: "-8px",
                              "border-radius": "20px"
                            },
                            on: { click: _vm.confirmUnlink }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "pointer",
                                attrs: { size: "20", color: "red" }
                              },
                              [_vm._v("cancel")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    directives: [{ name: "tippy", rawName: "v-tippy" }],
                    staticStyle: { height: "40px" },
                    attrs: {
                      elevation: "0",
                      color: "white",
                      content: "Edit client info"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("edit-client")
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "material-symbols-rounded",
                        staticStyle: {
                          "border-bottom": "1px solid var(--v-gray_70-base)"
                        }
                      },
                      [_vm._v("edit")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row-format align-center gap-2 mt-2" },
          [
            _c(
              "v-icon",
              {
                directives: [{ name: "tippy", rawName: "v-tippy" }],
                attrs: {
                  color: _vm.isFavorite ? "accent" : "gray_50",
                  size: "24",
                  content: "Toggle favorite"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.toggleFavorite(_vm.client.id)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.isFavorite ? "star" : "star_outline"))]
            ),
            _c(
              "div",
              {
                staticClass: "text-link",
                on: {
                  click: function($event) {
                    return _vm.viewPortalAs()
                  }
                }
              },
              [
                _vm._v(" View portal as "),
                _c(
                  "span",
                  {
                    staticClass: "text-link material-symbols-rounded",
                    staticStyle: { "font-size": "14px" }
                  },
                  [_vm._v("open_in_new")]
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "column-format" }, [
        _c("div", { staticClass: "font-gray_70 font-12" }, [
          _vm._v(_vm._s(_vm.$t("contact.address")))
        ]),
        _vm.client.address1 ||
        _vm.client.address2 ||
        _vm.client.city ||
        _vm.client.locality ||
        _vm.client.postal
          ? _c(
              "div",
              { staticClass: "text-link", on: { click: _vm.openMap } },
              [
                _c("div", { staticClass: "truncate" }, [
                  _vm._v(_vm._s(_vm.client.address1))
                ]),
                _c("div", { staticClass: "truncate" }, [
                  _vm._v(_vm._s(_vm.client.address2))
                ]),
                _c("div", { staticClass: "truncate" }, [
                  _vm._v(
                    _vm._s(_vm.client.city) +
                      " " +
                      _vm._s(_vm.client.locality) +
                      " " +
                      _vm._s(_vm.client.postal)
                  )
                ])
              ]
            )
          : _c("div", [_vm._v("--")])
      ]),
      _c("div", { staticClass: "column-format" }, [
        _c("div", { staticClass: "font-gray_70 font-12" }, [
          _vm._v(_vm._s(_vm.$t("client.phone")))
        ]),
        _vm.phone
          ? _c(
              "div",
              {
                staticClass: "text-link truncate",
                on: {
                  click: function($event) {
                    return _vm.makePhoneCall(_vm.phone)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.phone))]
            )
          : _c("div", [_vm._v("--")])
      ]),
      _c("div", { staticClass: "column-format" }, [
        _c("div", { staticClass: "font-gray_70 font-12" }, [
          _vm._v(_vm._s(_vm.$t("client.website")))
        ]),
        _vm.website
          ? _c(
              "div",
              {
                staticClass: "text-link truncate",
                on: {
                  click: function($event) {
                    return _vm.openWebsite(_vm.website)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.website))]
            )
          : _c("div", [_vm._v("--")])
      ]),
      _c("div", { staticClass: "column-format" }, [
        _c("div", { staticClass: "font-gray_70 font-12" }, [
          _vm._v(_vm._s(_vm.$t("client.source")))
        ]),
        _vm.client.leadSource
          ? _c("div", { staticClass: "text-link truncate" }, [
              _vm._v(_vm._s(_vm.client.leadSource))
            ])
          : _c("div", [_vm._v("--")])
      ]),
      _vm._l(_vm.customValues, function(customValue, index) {
        return _c("div", { key: index, staticClass: "column-format" }, [
          _c("div", { staticClass: "font-gray_70" }, [
            _vm._v(_vm._s(customValue.label))
          ]),
          customValue.isLink
            ? _c(
                "div",
                {
                  staticClass: "text-link truncate",
                  on: {
                    click: function($event) {
                      return _vm.openWebsite(customValue.value)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(customValue.value) + " ")]
              )
            : _c("div", { staticClass: "truncate" }, [
                _vm._v(_vm._s(customValue.value))
              ])
        ])
      }),
      _vm.$store.getters.hasInvoiceAccess
        ? _c("div", { staticClass: "column-format" }, [
            _c("div", { staticClass: "font-gray_70 font-12" }, [
              _vm._v(_vm._s(_vm.$t("client.payment-terms")))
            ]),
            _c("div", [
              _c("span", {}, [_vm._v("Hourly rate:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.client.hourlyAmount
                      ? _vm.$formatters.dollars(
                          _vm.client.hourlyAmount,
                          true,
                          true,
                          _vm.client.currency
                        )
                      : _vm.$formatters.dollars(
                          0,
                          true,
                          true,
                          _vm.client.currency
                        )
                  ) +
                  " "
              )
            ]),
            _vm.client.paymentTerms
              ? _c("div", [
                  _c("span", {}, [_vm._v("Payments due:")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.client.paymentTerms.paymentDays
                          ? _vm.client.paymentTerms.paymentDays + " days"
                          : "--"
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _vm.client.paymentTerms
              ? _c("div", [
                  _c("span", {}, [_vm._v("Late fee:")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.client.paymentTerms.latePaymentFee
                          ? _vm.client.paymentTerms.latePaymentFee + "% monthly"
                          : "--"
                      ) +
                      " "
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c("div", { staticClass: "column-format" }, [
        _c("div", { staticClass: "font-gray_70 font-12" }, [
          _vm._v(_vm._s(_vm.$t("global.contacts")))
        ]),
        _c(
          "div",
          { staticClass: "column-format gap-1" },
          [
            _vm._l(_vm.client.contacts, function(contact) {
              return _c(
                "div",
                {
                  key: contact.id,
                  staticClass: "row-format pointer gap-2",
                  on: {
                    click: function($event) {
                      return _vm.$emit("edit-contact", contact)
                    }
                  }
                },
                [
                  _c("div", [_vm._v(_vm._s(_vm.getContactFullName(contact)))]),
                  contact.phone
                    ? _c(
                        "v-icon",
                        {
                          directives: [{ name: "tippy", rawName: "v-tippy" }],
                          staticClass: "material-symbols-rounded",
                          attrs: {
                            size: "20",
                            color: "gray_80",
                            content: contact.phone
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.makePhoneCall(contact.phone)
                            }
                          }
                        },
                        [_vm._v("phone")]
                      )
                    : _vm._e(),
                  contact.email
                    ? _c(
                        "v-icon",
                        {
                          directives: [{ name: "tippy", rawName: "v-tippy" }],
                          staticClass: "material-symbols-rounded",
                          attrs: {
                            size: "20",
                            color: "gray_80",
                            content: contact.email
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.sendEmail(contact.email)
                            }
                          }
                        },
                        [_vm._v("email")]
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            _c(
              "div",
              {
                staticClass: "font-12 font-gray_70 pointer",
                on: {
                  click: function($event) {
                    return _vm.$emit("create-contact")
                  }
                }
              },
              [_vm._v("+ Add contact")]
            )
          ],
          2
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }