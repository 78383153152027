var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format", staticStyle: { "flex-grow": "1" } },
    [
      !_vm.activeThread && !_vm.newMessage
        ? _c(
            "div",
            {
              staticClass: "column-format",
              staticStyle: { "min-height": "324px" }
            },
            [
              !_vm.activeThread && _vm.isVisible
                ? _c(
                    "div",
                    {
                      staticClass: "messages",
                      staticStyle: {
                        "max-height": "calc(var(--vh) - 150px)",
                        "overflow-y": "auto"
                      }
                    },
                    _vm._l(_vm.$store.state.threads, function(thread) {
                      return _c("thread", {
                        key: _vm.randomKey + "-" + thread.id,
                        attrs: { thread: thread },
                        on: {
                          "open-thread": function($event) {
                            return _vm.openThread(thread)
                          }
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm.newMessage
        ? _c(
            "div",
            [
              _c("start-new-message", {
                attrs: { subscription: _vm.subscription },
                on: {
                  back: _vm.allThreads,
                  "new-message": _vm.newMessageStarted
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "pb-2" },
            [
              _c("thread-detail", {
                key: _vm.refreshKey,
                ref: "ThreadDetail",
                attrs: {
                  thread: _vm.activeThread,
                  "message-update": _vm.messageUpdate,
                  "random-key": _vm.randomKey,
                  "records-per-fetch": _vm.recordsPerFetch
                },
                on: {
                  back: _vm.allThreads,
                  "more-messages": function($event) {
                    return _vm.fetchMoreMessages(_vm.activeThread)
                  },
                  delete: function($event) {
                    return _vm.deleteThread(_vm.activeThread)
                  }
                }
              })
            ],
            1
          ),
      _c("audio", {
        staticStyle: { display: "none" },
        attrs: { src: "/media/inbound_message.mp3", id: "NewMessage" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }