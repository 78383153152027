var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Client portal" }
            ]
          }
        },
        [_vm._v(" Enabled features ")]
      ),
      _vm.features
        ? _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "brand-medium text-left" }, [
                        _vm._v("Default feature access:")
                      ]),
                      _c("v-switch", {
                        attrs: {
                          label: "Projects enabled",
                          "hide-details": ""
                        },
                        on: { change: _vm.saveFeatures },
                        model: {
                          value: _vm.features.projectsEnabled,
                          callback: function($$v) {
                            _vm.$set(_vm.features, "projectsEnabled", $$v)
                          },
                          expression: "features.projectsEnabled"
                        }
                      }),
                      _c("v-switch", {
                        attrs: {
                          label: "Invoices enabled",
                          "hide-details": ""
                        },
                        on: { change: _vm.saveFeatures },
                        model: {
                          value: _vm.features.invoicesEnabled,
                          callback: function($$v) {
                            _vm.$set(_vm.features, "invoicesEnabled", $$v)
                          },
                          expression: "features.invoicesEnabled"
                        }
                      }),
                      _c("v-switch", {
                        attrs: {
                          label: "Financial reports / summary",
                          "hide-details": ""
                        },
                        on: { change: _vm.saveFeatures },
                        model: {
                          value: _vm.features.summaryFinancialsEnabled,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.features,
                              "summaryFinancialsEnabled",
                              $$v
                            )
                          },
                          expression: "features.summaryFinancialsEnabled"
                        }
                      }),
                      _c("v-switch", {
                        attrs: {
                          label: "Agreements enabled",
                          "hide-details": ""
                        },
                        on: { change: _vm.saveFeatures },
                        model: {
                          value: _vm.features.proposalsEnabled,
                          callback: function($$v) {
                            _vm.$set(_vm.features, "proposalsEnabled", $$v)
                          },
                          expression: "features.proposalsEnabled"
                        }
                      }),
                      _c("v-switch", {
                        attrs: {
                          label: "Time worked enabled",
                          "hide-details": ""
                        },
                        on: { change: _vm.saveFeatures },
                        model: {
                          value: _vm.features.timeEnabled,
                          callback: function($$v) {
                            _vm.$set(_vm.features, "timeEnabled", $$v)
                          },
                          expression: "features.timeEnabled"
                        }
                      }),
                      _c("v-switch", {
                        attrs: {
                          label: "Meetings enabled",
                          "hide-details": ""
                        },
                        on: { change: _vm.saveFeatures },
                        model: {
                          value: _vm.features.meetingsEnabled,
                          callback: function($$v) {
                            _vm.$set(_vm.features, "meetingsEnabled", $$v)
                          },
                          expression: "features.meetingsEnabled"
                        }
                      }),
                      _c("v-switch", {
                        attrs: {
                          label: "Requests/tickets enabled",
                          "hide-details": ""
                        },
                        on: { change: _vm.saveFeatures },
                        model: {
                          value: _vm.features.ticketsEnabled,
                          callback: function($$v) {
                            _vm.$set(_vm.features, "ticketsEnabled", $$v)
                          },
                          expression: "features.ticketsEnabled"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }