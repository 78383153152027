var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ticket-table" } },
    [
      _c("v-data-table", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          headers: _vm.headers,
          items: _vm.communications,
          "sort-by": "payload.created",
          "sort-desc": true,
          "group-by": _vm.groupSelection,
          "items-per-page": -1,
          "hide-default-footer": true
        },
        on: {
          "click:row": function($event) {
            return _vm.$emit("open-ticket", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "group.header",
            fn: function(ref) {
              var group = ref.group
              var headers = ref.headers
              var toggle = ref.toggle
              return [
                _c(
                  "td",
                  {
                    staticClass: "text-left",
                    attrs: { colspan: "6" },
                    on: { click: toggle }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row-format align-center gap-2" },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "20", color: "secondary" } },
                          [_vm._v("folder")]
                        ),
                        _vm._v(_vm._s(group ? group : " -- ") + " ")
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.payload.created",
            fn: function(ref) {
              var item = ref.item
              return [
                item.payload.created
                  ? _c("span", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.payload.created).toFormat(
                            "DD"
                          )
                        )
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.payload.updated",
            fn: function(ref) {
              var item = ref.item
              return [
                item.payload.updated
                  ? _c("span", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.payload.updated).toFormat(
                            "DD"
                          )
                        )
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.payload.dueDate",
            fn: function(ref) {
              var item = ref.item
              return [
                item.payload.dueDate
                  ? _c("span", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.payload.dueDate).toFormat(
                            "DD"
                          )
                        )
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.assignedTo",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "row-format" },
                  _vm._l(item.payload.assignedTo, function(assignedTo, index) {
                    return _c("assigned-user", {
                      key: assignedTo,
                      style:
                        (index > 0 ? "margin-left: -6px" : "") +
                        "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                        index,
                      attrs: {
                        "show-name": false,
                        small: true,
                        "assigned-to": assignedTo
                      }
                    })
                  }),
                  1
                )
              ]
            }
          },
          {
            key: "item.payload.ticketType",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: { theme: "left-align" },
                        expression: "{theme: 'left-align'}"
                      }
                    ],
                    attrs: { content: item.payload.summary }
                  },
                  [
                    item.payload.status
                      ? _c("span", { staticClass: "status" }, [
                          _vm._v(_vm._s(item.payload.status))
                        ])
                      : _vm._e(),
                    item.payload.summary
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "material-symbols-rounded ml-1",
                            attrs: { color: "gray_90", size: "16" }
                          },
                          [_vm._v("summarize")]
                        )
                      : _vm._e(),
                    item.payload.ticketNumber
                      ? _c("span", [
                          _vm._v(" • " + _vm._s(item.payload.ticketNumber))
                        ])
                      : _vm._e(),
                    item.payload.ticketType
                      ? _c("span", [
                          _vm._v(" • " + _vm._s(item.payload.ticketType.type))
                        ])
                      : _vm._e(),
                    _c("br"),
                    item.payload.subject
                      ? _c(
                          "span",
                          {
                            staticClass: "brand-semilight font-14 font-gray_70"
                          },
                          [_vm._v(_vm._s(item.payload.subject))]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.client.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { staticClass: "column-format" }, [
                  item.client
                    ? _c(
                        "div",
                        { staticClass: "row-format align-center" },
                        [
                          _c("client-avatar", {
                            staticClass: "mr-0 nested-avatar",
                            attrs: { client: item.client, small: "" }
                          }),
                          _c("span", [_vm._v(_vm._s(item.client.name))])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "brand-semilight font-14 font-gray_70" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.contact(item.payload)
                            ? _vm.contact(item.payload).fullName
                            : "--"
                        )
                      )
                    ]
                  )
                ])
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [
                _vm._v(
                  " No ticket records to display. You can try adjusting your filters or creating a new ticket. "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }