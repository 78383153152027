<template>
	<div>
		<v-data-table
				:headers="headers"
				:items="invoices"
				sort-by="dateCreated"
				:sort-desc="true"
				@click:row="$emit('open-invoice',$event)"
				style="cursor: pointer"
				:items-per-page="-1"
				:hide-default-footer="true"
				:fixed-header="true"
		>
			<template v-slot:item.dateCreated="{ item }">
				<span v-if="item.dateCreated" class="brand-semilight">{{ DateTime.fromISO(item.dateCreated).toFormat('DD') }}</span>
			</template>

			<template v-slot:item.dateSent="{ item }">
				<span v-if="item.dateSent" class="brand-semilight">{{ DateTime.fromISO(item.dateSent).toFormat('DD') }}</span>
			</template>

			<template v-slot:item.dateDue="{ item }">
				<span v-if="item.dateDue" class="brand-semilight">{{ DateTime.fromISO(item.dateDue).toFormat('DD') }}</span>
			</template>

			<template v-slot:item.client="{ item }">
				<div class="row-format align-center">
					<client-avatar small v-if="item.clientInfo" :client="item.clientInfo" />
					<div class="ml-n3">{{ item.clientInfo.name }}</div>
				</div>
			</template>

			<template v-slot:item.invoiceNumberFormatted="{ item }">
				<span class="brand-semilight">{{ item.invoiceNumberFormatted }}</span>
			</template>

			<template v-slot:item.total="{ item }">
				<div v-if="item.total" class="brand-semilight">{{
						$formatters.dollars(item.total ? item.total : 0, true, true, item.currency)
					}}</div>
				<div class="font-12 font-gray_70 " v-if="item.localTotal && item.currency !== $store.state.defaultCurrency">
					{{$formatters.dollars(item.localTotal ? item.localTotal : 0, true, true, $store.state.defaultCurrency)}}
				</div>
			</template>

			<template v-slot:item.amountDue="{ item }">
				<div v-if="item.total" class="brand-semilight">{{
						$formatters.dollars(item.amountDue ? item.amountDue : 0, true, true, item.currency)
					}}</div>
			</template>

			<template v-slot:item.status="{ item }">
				<div class="row-format align-center">
					<div class="brand-semilight font-12" v-html="getStatus(item)"></div>
					<v-icon class="ml-2" size="20" v-if="item.integrationKeys.quickbooksId">$quickbooks</v-icon>
					<v-icon class="ml-2" size="20" v-if="item.integrationKeys.xeroId">$xero</v-icon>
				</div>
			</template>

			<template v-slot:item.action="{ item }">
				<action-menu
						:is-list="true"
						:invoice="item"
						:send-enable="true"
						:show-view="true"
						class="action-menu"
						@paid="$emit('invoice-paid',{invoice:item, payment:$event})"
						@delete-invoice="$emit('remove-invoice',$event)"
						@set-invoice="$emit('add-update-invoice',$event)"
						@view-detail="$emit('open-invoice',$event)"
						@duplicate-invoice="$emit('add-update-invoice',$event)"
				></action-menu>
			</template>

			<template v-slot:no-data>
				No invoice records to display. You can try adjusting your filters or creating a new invoice.
			</template>
		</v-data-table>
	</div>
</template>

<script>
import ActionMenu from "@/modules/invoices/ActionMenu";
import ClientAvatar from "@/components/ClientAvatar";
import DateTime from "@/modules/utils/HDateTime";

export default {
	name: "InvoiceTable",

	props: ['invoices'],

	components: {ActionMenu, ClientAvatar},

	data: function () {
		return {
			DateTime:DateTime
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		getStatus(invoice) {
			let background, color, label;

			switch (invoice.status) {
				case 'PAID': {
					background = '--v-success_10-base';
					color = '--v-gray_80-base';
					label = 'Paid';
					break;
				}

				case 'PARTIAL':
				case 'SENT': {
					if (DateTime.fromISO(invoice.dateDue).endOf('day') < DateTime.now()) {
						background = '--v-alert_10-base';
						color = '--v-gray_80-base';
						label = 'Late';
					} else if (invoice.status === 'PARTIAL') {
						background = '--v-warning_10-base';
						color = '--v-gray_80-base';
						label = invoice.status === 'PARTIAL' ? 'Partially paid' : 'Pending';
					} else {
						background = '--v-blue_5-base';
						color = '--v-gray_80-base';
						label = invoice.status.charAt(0).toUpperCase() + invoice.status.toLowerCase().slice(1);
					}

					break;
				}

				default: {
					background = '--v-gray_20-base';
					color = '--v-gray_80-base';
					label = invoice.status.charAt(0).toUpperCase() + invoice.status.toLowerCase().slice(1);
					break;
				}
			}

			return `<div class="invoice-status" style="--color:var(${color});--background:var(${background})">${label}</div>`;
		},
	},

	computed: {
		headers: function() {
			let result = [
				{ text: this.$t('invoice.amount-total'), value: 'total' },
				{ text: this.$t('invoice.amount-due'), value: 'amountDue' },
				{ text: this.$t('client.client'), value: 'client' },
				{ text: this.$t('invoice.invoice-number'), value: 'invoiceNumberFormatted' },
				{ text: this.$t('invoice.date-created'), value: 'dateCreated' },
				{ text: this.$t('invoice.date-sent'), value: 'dateSent' },
				{ text: this.$t('invoice.date-due'), value: 'dateDue' },
				{ text: this.$t('invoice.status'), value: 'status'},
				{text: '', value: 'action', sortable: false }
			];

			return result;
		},
	},

}
</script>

<style scoped lang="scss">

</style>
