var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format gap-3" },
    [
      _c("v-text-field", {
        staticStyle: {
          "max-width": "200px",
          "background-color": "var(--v-white-base)"
        },
        attrs: {
          outlined: "",
          "hide-details": "",
          dense: "",
          placeholder: "Search...",
          color: "gray_30"
        },
        on: { input: _vm.updated },
        scopedSlots: _vm._u([
          {
            key: "prepend-inner",
            fn: function() {
              return [
                _c(
                  "span",
                  { staticClass: "material-symbols-rounded font-gray_50" },
                  [_vm._v("search")]
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.textFilter,
          callback: function($$v) {
            _vm.textFilter = $$v
          },
          expression: "textFilter"
        }
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "nudge-top": 28,
            bottom: "",
            right: "",
            rounded: "",
            "offset-overflow": "",
            "offset-y": "",
            "close-on-content-click": false,
            "content-class": "filter-menu-class",
            transition: "slide-y-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: {
                          "min-height": "40px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          elevation: "0",
                          outlined: "",
                          color: "gray_30"
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (_vm.isFilterActive
                              ? "font-secondary"
                              : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filterLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.isFilterActive ? "secondary" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c("div", { staticClass: "column-format" }, [
            _c(
              "div",
              { staticClass: "px-2 pt-3" },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "small-radio",
                    attrs: { "hide-details": "", dense: "" },
                    on: { change: _vm.updated },
                    model: {
                      value: _vm.status,
                      callback: function($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  },
                  [
                    _c("v-radio", {
                      attrs: { label: "Active", value: "active" }
                    }),
                    _c("v-radio", {
                      attrs: { label: "Archived", value: "archive" }
                    }),
                    _c("v-radio", { attrs: { label: "All", value: "all" } })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-3 mt-3 mb-3" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-2",
                    staticStyle: { "font-weight": "500" },
                    attrs: {
                      text: "",
                      block: "",
                      color: "secondary",
                      elevation: "0"
                    },
                    on: {
                      click: function($event) {
                        return _vm.clearFilter()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("$clear")]),
                    _vm._v(" Clear filters")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action",
                    staticStyle: { "font-weight": "500" },
                    attrs: { block: "", color: "primary", elevation: "0" },
                    on: {
                      click: function($event) {
                        return _vm.applyFilter()
                      }
                    }
                  },
                  [_vm._v("Apply")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }