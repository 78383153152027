import { render, staticRenderFns } from "./MoneyChart.vue?vue&type=template&id=25becb03&scoped=true&"
import script from "./MoneyChart.vue?vue&type=script&lang=js&"
export * from "./MoneyChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25becb03",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1967088155/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25becb03')) {
      api.createRecord('25becb03', component.options)
    } else {
      api.reload('25becb03', component.options)
    }
    module.hot.accept("./MoneyChart.vue?vue&type=template&id=25becb03&scoped=true&", function () {
      api.rerender('25becb03', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/home/insights/MoneyChart.vue"
export default component.exports