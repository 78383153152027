<template>
	<div :class="'meeting-detail ' + (scheduledMeeting.formData.answers.length ? 'with-answers' : 'no-answers')" v-if="isReady">
		<div id="meeting-inner-wrapper" class="modal-padding d-flex">
			<div class="row-format mb-2 align-center">
				<div class="brand-medium font-18" v-if="view === 'MAIN'">{{ $t('forms.meeting-modal.title') }}</div>
				<v-icon v-else @click="view = 'MAIN'">$arrowLeft</v-icon>
				<div class="ml-auto row-format align-center gap-4">
					<pipeline-link-widget
						:item-id="scheduledMeeting.id"
						item-type="ScheduledMeeting"
						:client="scheduledMeeting.clientId"
					></pipeline-link-widget>
					<v-icon size="20" class="ml-auto pointer" @click="saveScheduledMeeting(true)">$close</v-icon>
				</div>
			</div>
			<div v-if="view === 'MAIN'">
				<v-container class="ml-0 pa-0">
					<v-row>
						<v-col :md="scheduledMeeting.formData.answers.length ? 6 : 12" cols="12" class="ml-0" align="left">
							<v-container class="pa-0 ma-0 font-14">
								<v-row dense v-if="isCancelled">
									<v-col cols="4" class="brand-medium">{{ $t('forms.meeting-modal.status') }}</v-col>
									<v-col cols="8" class="brand-medium" style="color: var(--v-error-base)">Cancelled</v-col>
								</v-row>
								<v-row dense v-if="isCancelled">
									<v-col cols="4" class="brand-medium">{{
										$t('forms.meeting-modal.cancellation-reason')
									}}</v-col>
									<v-col
										cols="8"
										class="brand-medium"
										style="white-space: pre-wrap; border: 1px solid var(--v-gray_30-base)"
										>{{ scheduledMeeting.cancellationReason }}</v-col
									>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('forms.meeting-modal.meeting-name') }}</v-col>
									<v-col cols="8">{{ scheduledMeeting.meetingName }}</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('global.contact') }}</v-col>
									<v-col cols="8">
										<div class="row-format align-center gap-2">
											<div>
												{{ scheduledMeeting.formData.firstName }} {{ scheduledMeeting.formData.lastName }}
											</div>
											<v-icon
												size="22"
												color="primary"
												@click="addNewContact()"
												v-if="showCreateContact"
												v-tippy="{ content: 'Create new contact' }"
												class="pointer material-symbols-rounded"
												>person_add</v-icon
											>
										</div>
										<div class="font-12 font-gray_70">({{ scheduledMeeting.scheduledTimezone }})</div>
									</v-col>
								</v-row>
								<v-row dense v-if="businessInfo">
									<v-col cols="4" class="brand-medium">{{ $t('forms.meeting-modal.business-info') }}</v-col>
									<v-col cols="8" v-html="businessInfo"></v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('global.email') }}</v-col>
									<v-col cols="8">{{ scheduledMeeting.formData.email }}</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('global.phone') }}</v-col>
									<v-col cols="8">{{ scheduledMeeting.formData.phone }}</v-col>
								</v-row>
								<v-row dense v-if="scheduledMeeting.additionalAttendees && scheduledMeeting.additionalAttendees.length">
									<v-col cols="4" class="brand-medium">Additional attendees</v-col>
									<v-col cols="8">{{ scheduledMeeting.additionalAttendees.toString() }}</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('forms.meeting-modal.meeting-time') }}</v-col>
									<v-col cols="8" :class="isCancelled ? 'strike' : ''">{{
										DateTime.fromISO(scheduledMeeting.confirmedTime.start).toFormat('cccc') +
											' ' +
											DateTime.fromISO(scheduledMeeting.confirmedTime.start).toLocaleString(
												DateTime.DATETIME_MED
											) +
											' (' +
											Settings.defaultZone.name +
											')'
									}}</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="4" class="brand-medium">{{ $t('forms.meeting-modal.duration') }}</v-col>
									<v-col cols="8" :class="isCancelled ? 'strike' : ''"
										>{{
											DateTime.fromISO(scheduledMeeting.confirmedTime.end).diff(
												DateTime.fromISO(scheduledMeeting.confirmedTime.start),
												'minutes'
											).minutes
										}}
										minutes</v-col
									>
								</v-row>
								<v-row dense>
									<v-col cols="4"></v-col>
									<v-col cols="8">
										<create-time-entry
											:start="scheduledMeeting.confirmedTime.start"
											:end="scheduledMeeting.confirmedTime.end"
											:notes="meetingName"
											:client="client"
										></create-time-entry>
									</v-col>
								</v-row>
								<div v-if="!isCancelled">
									<div v-if="scheduledMeeting.location.type === 'Zoom' && scheduledMeeting.zoomMeeting">
										<v-row dense>
											<v-col cols="4" class="brand-medium">Join URL</v-col>
											<v-col cols="8"
												><a :href="scheduledMeeting.zoomMeeting.join_url" target="_blank">{{
													scheduledMeeting.zoomMeeting.join_url
												}}</a></v-col
											>
										</v-row>
										<v-row dense>
											<v-col cols="4" class="brand-medium">Meeting Id</v-col>
											<v-col cols="8">{{ scheduledMeeting.zoomMeeting.id }}</v-col>
										</v-row>
										<v-row dense v-if="scheduledMeeting.zoomMeeting.pstnPassword">
											<v-col cols="4" class="brand-medium">Passcode</v-col>
											<v-col cols="8">{{ scheduledMeeting.zoomMeeting.pstnPassword }}</v-col>
										</v-row>
										<v-row dense v-if="zoomDialIn">
											<v-col cols="4" class="brand-medium">Dial in</v-col>
											<v-col cols="8"
												>{{ zoomDialIn.number.replaceAll(' ', '') }} ({{ zoomDialIn.city }},
												{{ zoomDialIn.country_name }})</v-col
											>
										</v-row>
									</div>
									<div
										v-else-if="scheduledMeeting.microsoftEvent && scheduledMeeting.microsoftEvent.meetingLink"
									>
										<v-row dense>
											<v-col cols="4" class="brand-medium"></v-col>
											<v-col cols="8" class="my-2">
												<v-btn
													elevation="0"
													@click="openTeamsMeeting(scheduledMeeting.microsoftEvent.meetingLink)"
													><v-icon class="mr-1">$microsoft</v-icon> Join Teams meeting</v-btn
												>
											</v-col>
										</v-row>
									</div>
									<div v-else-if="scheduledMeeting.sessionEvent && scheduledMeeting.sessionEvent.sessionLink">
										<v-row dense>
											<v-col cols="4" class="brand-medium"></v-col>
											<v-col cols="8" class="my-2">
												<v-btn
													elevation="0"
													@click="openTeamsMeeting(scheduledMeeting.sessionEvent.sessionLink)"
													><v-icon class="mr-1">$sessionsIcon</v-icon> Join Session</v-btn
												>
											</v-col>
										</v-row>
									</div>
									<div
										v-else-if="scheduledMeeting.location.type === 'Google' && scheduledMeeting.googleMeeting"
									>
										<v-row dense>
											<v-col cols="4" class="brand-medium">Join URL</v-col>
											<v-col cols="8"
												><a :href="scheduledMeeting.googleMeeting.hangoutLink" target="_blank">{{
													scheduledMeeting.googleMeeting.hangoutLink
												}}</a></v-col
											>
										</v-row>
										<v-row dense v-if="googleDialIn">
											<v-col cols="4" class="brand-medium">Dial in</v-col>
											<v-col cols="8">{{ googleDialIn.label }} ({{ googleDialIn.regionCode }})</v-col>
										</v-row>
										<v-row dense v-if="googleDialIn">
											<v-col cols="4" class="brand-medium">Pin</v-col>
											<v-col cols="8">{{ googleDialIn.pin }}</v-col>
										</v-row>
									</div>
									<div v-else-if="scheduledMeeting.location.type === 'PhoneIn'">
										<v-row dense>
											<v-col cols="4" class="brand-medium">Invitee will call</v-col>
											<v-col cols="8">{{ scheduledMeeting.location.phone }}</v-col>
										</v-row>
									</div>
									<div v-else-if="scheduledMeeting.location.type === 'PhoneOut'">
										<v-row dense>
											<v-col cols="4" class="brand-medium">Call invitee at</v-col>
											<v-col cols="8">{{ scheduledMeeting.formData.phone }}</v-col>
										</v-row>
									</div>
									<div v-else-if="scheduledMeeting.location.type === 'InPerson'">
										<v-row dense>
											<v-col cols="4" class="brand-medium">Meet at</v-col>
											<v-col cols="8">{{ scheduledMeeting.location.address }}</v-col>
										</v-row>
									</div>
									<div v-else>
										<v-row dense>
											<v-col cols="12">{{ scheduledMeeting.location.custom }}</v-col>
										</v-row>
									</div>
								</div>
								<v-row v-if="income" class="mt-2">
									<v-col cols="4" class="brand-medium">Payment received</v-col>
									<v-col cols="8" class="row-format align-center gap-2"
										><div>
											{{ $formatters.dollars(income.amount, true, true, income.currency) }} -
											{{ income.paymentSource }}
										</div>
										<v-icon
											size="20"
											class="material-symbols-rounded pointer"
											color="primary"
											@click="openReceipt"
											>receipt</v-icon
										></v-col
									>
								</v-row>
								<v-row v-if="!isCancelled" dense>
									<v-col cols="12" class="mt-4 d-flex align-center justify-space-between gap-3">
										<v-btn class="secondary-action" style="flex:0 1 50%;" @click="view = 'CANCEL'"
											><span style="color: var(--v-error-base)">Cancel meeting</span></v-btn
										>
										<v-btn class="primary-action" style="flex:0 1 50%" @click="view = 'RESCHEDULE'"
											>Reschedule meeting</v-btn
										>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12" class="mt-2 mb-0 pb-0">
										<select-client
											ref="selectClient"
											id="select-client"
											v-model="scheduledMeeting.clientId"
											prepend-icon="$clients"
											:hide-archive="true"
											:placeholder="$t('timetrack.current.choose-client')"
										/>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12" class="mt-n2 text-center pa-0">
										<v-btn
											class="secondary-action add-new-client"
											color="primary"
											text
											v-if="!scheduledMeeting.clientId"
											@click="addNewClient"
											>+ {{ $t('forms.meeting-modal.create-client') }}</v-btn
										>
									</v-col>
								</v-row>

								<v-row dense>
									<v-col cols="12" class="mt-0">
										<v-textarea
											v-model="scheduledMeeting.notes"
											hide-details
											persistent-placeholder
											class="h-outline"
											label="Meeting notes"
											rows="3"
											auto-grow
										></v-textarea>
									</v-col>
								</v-row>

								<v-row dense v-if="fromLeadGen">
									<v-col cols="12" class="mt-0">
										<v-switch
											inset
											v-model="scheduledMeeting.leadGenArchived"
											:label="$t('forms.meeting-modal.archive-from-leadgen')"
										/>
									</v-col>
								</v-row>
							</v-container>
						</v-col>
						<v-col v-if="scheduledMeeting.formData.answers.length" md="6" cols="12" align="left">
							<div class="mb-2">
								{{ $t('forms.meeting-modal.questionnaire') }}
							</div>
							<v-container
								class="pa-2 ma-0 font-14"
								style="background-color: var(--v-gray_5-base); border-radius: 4px"
							>
								<v-row dense>
									<v-col class="pa-4">
										<div
											v-for="(answer, index) in scheduledMeeting.formData.answers"
											:key="index"
											class="text-left font-14 pb-3"
										>
											<div class="brand-medium" v-html="answer.question"></div>
											<div v-if="answer.fieldType === 'FileInput'">
												<div v-for="fileName in JSON.parse(answer.answer)" :key="fileName">
													<div class="font-primary pointer" @click="downloadFile(fileName)">
														{{ fileName }}
													</div>
												</div>
											</div>
											<div v-else style="white-space: pre-wrap">{{ answer.answer }}</div>
										</div>
									</v-col>
								</v-row>
							</v-container>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>
		<div class="modal-footer row-format centered gap-1" v-if="view === 'MAIN'">
			<v-btn class="super-action" style="width: 160px" @click="saveScheduledMeeting">
				{{ $t('global.save') }}
			</v-btn>
			<v-btn  icon>
				<v-icon size="20" @click="deleteMeeting" class="material-symbols-rounded">delete</v-icon>
			</v-btn>
		</div>
		<div v-if="view === 'CANCEL'">
			<i-frame-wrapper :frame-url="cancelUrl" @frame-event="frameEvent"></i-frame-wrapper>
		</div>
		<div v-if="view === 'RESCHEDULE'">
			<i-frame-wrapper :frame-url="rescheduleUrl" @frame-event="frameEvent"></i-frame-wrapper>
		</div>
	</div>
</template>

<script>
	import ScheduledMeetingService from '@/modules/meetings/ScheduledMeetingService';
	import SelectClient from '@/modules/clients/SelectClient';
	import MeetingService from '@/modules/meetings/MeetingService';
	import AccountService from '@/modules/account/AccountService';
	import IFrameWrapper from '@/modules/meetings/iFrameWrapper';
	import DateTime from '@/modules/utils/HDateTime';
	import ConfirmModal from '@/components/ConfirmModal';
	import ClientCreate from '@/modules/clients/ClientCreate';
	import CreateTimeEntry from '@/components/CreateTimeEntry';
	import axios from 'axios';
	import { Settings } from 'luxon';
	import IncomeService from '@/modules/accounting/payments/IncomeService';
	import PaymentDetail from '@/modules/accounting/payments/PaymentDetail';
	import ContactEdit from "@/modules/clients/contacts/ContactEdit";

	export default {
		name: 'MeetingDetail',

		props: ['id', 'fromLeadGen'],

		components: {
			IFrameWrapper,
			SelectClient,
			CreateTimeEntry,
			PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget'),
		},

		data: function() {
			return {
				Settings: Settings,
				view: 'MAIN',
				isReady: false,
				scheduledMeeting: null,
				meeting: null,
				uniqueAccountUrl: null,
				scheduledMeetingService: new ScheduledMeetingService(),
				incomeService: new IncomeService(),
				meetingService: new MeetingService(),
				DateTime: DateTime,
				baseUrl: process.env.VUE_APP_MEET_LINK,
				income: null,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'meeting-detail' });
			this.getScheduledMeeting();
			this.getUniqueUrl();
		},

		beforeDestroy() {},

		methods: {
			getScheduledMeeting: function() {
				this.scheduledMeetingService
					.getScheduledMeeting(this.id)
					.then((res) => {
						this.scheduledMeeting = res.data;
						this.getMeeting();
						this.getIncome();
					})
					.catch(() => {
						this.$store.commit('error', 'Unable to locate scheduled meeting.');
						this.$emit('result');
					});
			},

			getMeeting: function() {
				this.meetingService.getMeeting(this.scheduledMeeting.meetingId).then((res) => {
					this.meeting = res.data;
					this.isReady = true;
				});
			},

			getIncome: function() {
				if (this.scheduledMeeting.incomeRecordId) {
					this.incomeService
						.getIncome(this.scheduledMeeting.incomeRecordId)
						.then((res) => {
							this.income = res.data;
						})
						.catch((err) => console.log(err));
				}
			},

			downloadFile: function(fileName, download = true) {
				this.scheduledMeetingService.getSignedUrl(this.scheduledMeeting.id, fileName).then((res) => {
					let signedUrl = res.data;

					if (download == false) {
						window.open(signedUrl, '_blank');
					} else {
						axios({
							url: signedUrl, //your url
							method: 'GET',
							responseType: 'blob', // important
						}).then((response) => {
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', fileName); //or any other extension
							document.body.appendChild(link);
							link.click();
						});
					}
				});
			},

			frameEvent: function() {
				this.view = 'MAIN';
				this.getScheduledMeeting();
			},

			getUniqueUrl: function() {
				let accountService = new AccountService();
				accountService.getAccountUniqueUrl().then((res) => (this.uniqueAccountUrl = res.data));
			},

			saveScheduledMeeting: function() {
				this.scheduledMeetingService.updateScheduledMeeting(this.id, this.scheduledMeeting).then((res) => {
					this.scheduledMeeting = res.data;
					this.handleClose();
				});
			},

			deleteMeeting: function() {
				let binding = {
					headingText: 'Confirm meeting delete!',
					bodyText: 'Are you sure you want to delete this scheduled meeting?  This cannot be undone.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.scheduledMeetingService
							.deleteScheduledMeeting(this.id)
							.then(() => {
								this.$emit('result', { action: 'DELETED' });
							})
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			addNewContact: function(){
				let binding = {
					formData: this.scheduledMeeting.formData,
					clientId: this.scheduledMeeting.clientId
				}
				this.$store.state.globalModalController.openModal(ContactEdit,binding).then((res) => {
					if(res){
						this.$store.commit('success','Contact created');
					}
				});
			},

			addNewClient: function() {
				// let fromLeadGen = { ...this.scheduledMeeting.formData };
				// fromLeadGen.notes = this.getClientNotes();

				let leadGen = { ...this.scheduledMeeting };
				leadGen.notes = this.getClientNotes();
				leadGen.isScheduledMeeting = true;

				let binding = {
					clientList: this.$store.getters.clients,
					leadGen: leadGen,
				};

				this.$store.state.globalModalController.openModal(ClientCreate, binding).then((res) => {
					if (res) {
						this.scheduledMeeting.clientId = res.id;
						this.$refs.selectClient.getClientList();
					}
				});
			},

			getClientNotes: function() {
				let notes = '';
				let answers = this.scheduledMeeting.formData.answers;

				if (this.scheduledMeeting.notes) {
					notes += this.scheduledMeeting.notes + '<br><hr><br>';
				}

				answers.forEach((a) => {
					notes += `<strong>${a.question}</strong>:<br>${a.answer}<br><br>`;
				});

				return notes;
			},

			handleClose: function() {
				this.$emit('result', this.scheduledMeeting);
			},

			openTeamsMeeting: function(url) {
				window.open(url, '_blank');
			},

			openReceipt: function() {
				this.$store.state.globalModalController.openModal(PaymentDetail, { id: this.income.id });
			},
		},

		computed: {
			client: function() {
				return this.$store.getters.getClientById(this.scheduledMeeting.clientId);
			},
			businessInfo: function() {
				let form = this.scheduledMeeting.formData;
				let result = '';

				if (form.businessName) {
					result += `<span class="">${form.businessName}</span><br>`;
				}

				let address = [form.address1, form.address2, form.city, form.locality, form.postal, form.country];
				address = address.filter((a) => a !== null);

				if (address.length) {
					result += address.join(' ') + '<br>';
				}

				if (form.website) {
					result += form.website + '<br>';
				}

				return result;
			},

			meetingName: function() {
				return (
					this.scheduledMeeting.meetingName +
					' with ' +
					this.scheduledMeeting.formData.firstName +
					' ' +
					this.scheduledMeeting.formData.lastName
				);
			},

			showCreateContact: function(){
				if(this.scheduledMeeting.formData && this.scheduledMeeting.formData.email){
					if(this.$store.getters.contacts.findIndex(c => c.email && c.email.toLowerCase() === this.scheduledMeeting.formData.email.toLowerCase()) > -1){
						return false;
					}
				}
				return true;
			},

			fullMeetingLink: function() {
				if (this.meeting) {
					return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + this.meeting.uniqueMeetingId + '/';
				} else {
					return null;
				}
			},

			cancelUrl: function() {
				return `${this.fullMeetingLink}?id=${this.id}&action=cancel&inFrame=true`;
			},

			rescheduleUrl: function() {
				return `${this.fullMeetingLink}?id=${this.id}&action=change&inFrame=true`;
			},

			podId: function() {
				let podId = this.$store.getters.getAccount.pod.podId;
				return podId.replace(/\D/g, '');
			},

			isCancelled: function() {
				if (this.scheduledMeeting && this.scheduledMeeting.meetingStatus === 'Cancelled') {
					return true;
				} else {
					return false;
				}
			},

			zoomDialIn: function() {
				if (
					this.scheduledMeeting.zoomMeeting &&
					this.scheduledMeeting.zoomMeeting.settings &&
					this.scheduledMeeting.zoomMeeting.settings.global_dial_in_numbers &&
					this.scheduledMeeting.zoomMeeting.settings.global_dial_in_numbers.length
				) {
					return this.scheduledMeeting.zoomMeeting.settings.global_dial_in_numbers[0];
				} else {
					return null;
				}
			},

			googleDialIn: function() {
				if (this.scheduledMeeting.googleMeeting) {
					return this.scheduledMeeting.googleMeeting.entryPoints.find((e) => e.entryPointType === 'phone');
				} else {
					return null;
				}
			},
		},
	};
</script>

<style lang="scss">
	.meeting-detail {
		@media #{map-get($display-breakpoints, 'sm-and-down')} {
			max-width: 600px;
		}
		@media #{map-get($display-breakpoints, 'md-and-up')} {
			&.no-answers {
				width: 600px;
			}
			&.with-answers {
				width: 1000px;
			}
		}
		.v-btn.add-new-client .v-btn__content {
			font-weight: 600 !important;
			color: var(--v-gray_70-base);
			&:hover {
				color: var(--gray_90-base);
			}
		}
	}
</style>
<style scoped lang="scss">
	.meeting-detail {
		#meeting-inner-wrapper {
			max-height: calc(var(--vh) - 180px);
			overflow-y: auto;
			display: flex;
			flex-direction: column;
		}

		.modal-footer {
			position: sticky;
			position: -webkit-sticky;
			margin-top: 8px;
		}

		.strike {
			text-decoration: line-through;
		}
	}
</style>
