import { render, staticRenderFns } from "./StylesConfig.vue?vue&type=template&id=f1c20768&scoped=true&"
import script from "./StylesConfig.vue?vue&type=script&lang=js&"
export * from "./StylesConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1c20768",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VIcon,VSelect,VSlider,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2004326659/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f1c20768')) {
      api.createRecord('f1c20768', component.options)
    } else {
      api.reload('f1c20768', component.options)
    }
    module.hot.accept("./StylesConfig.vue?vue&type=template&id=f1c20768&scoped=true&", function () {
      api.rerender('f1c20768', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/agreements/config/StylesConfig.vue"
export default component.exports