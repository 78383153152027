<template>
	<div class="confirm-dialog-div">
		<v-btn class="close-dialog" icon rounded @click.prevent="handleClose()">
			<v-icon size="20">$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="row-format text-left align-center gap-3">
				<v-icon :color="severity" size="50">{{ icon }}</v-icon>
				<div>
					<div v-if="headingText" class="font-18 brand-medium">
						{{ headingText }}
					</div>
					<div v-if="bodyText" class="font-14" v-html="bodyText"></div>
					<v-text-field
						v-if="confirmText"
						v-model="confirmation"
						type="text"
						class="confirm-input"
						autofocus
						:rules="[(value) => !!value || confirmHint]"
						@keyup.enter="handleEnter()"
					></v-text-field>
				</div>
			</div>
		</div>
		<v-form @submit.prevent="handleSubmit()" ref="confirmDialog" id="confirmDialog" lazy-validation>
			<div class="modal-footer row-format gap-1 px-3 py-3" style="justify-content: end">
				<v-btn v-if="!hideNo" class="primary-action" @click.prevent="handleClose()">{{ noText }}</v-btn>
				<v-btn
					type="submit"
					min-width="100"
					elevation="0"
					:disabled="submitDisabled"
					class="super-action"
					@click.prevent="handleSubmit()"
				>
					{{ yesText }}
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'ConfirmModal',

		props: {
			severity: { type: String, required: false, default: 'warning' }, // info, warning, error, success
			icon: { type: String, required: false, default: '$alert' }, // $alert, $info, etc.
			headingText: String,
			bodyText: String,
			hideNo: { type: Boolean, required: false, default: false },
			yesText: { type: String, required: false, default: 'Yes' },
			noText: { type: String, required: false, default: 'No' },
			persistent: { type: Boolean, default: false },
			confirmText: { type: String, required: false, default: '' },
			confirmHint: { type: String, required: false, default: '' },
			timeOut: { type: Number, required: false },
			forceTimeoutEventId: { type: String, required: false, default: null },
			align: { type: String, required: false, default: 'center' },
		},

		data() {
			return {
				confirmation: '',
			};
		},

		mounted() {
			if (this.timeOut) {
				setTimeout(() => this.handleClose(), this.timeOut * 1000);
			}

			if (this.forceTimeoutEventId) {
				this.$store.state.eventBus.$on(this.forceTimeoutEventId, this.handleClose);
			}
		},

		beforeDestroy() {
			if (this.forceTimeoutEventId) {
				this.$store.state.eventBus.$off(this.forceTimeoutEventId, this.handleClose);
			}
		},

		methods: {
			handleEnter() {
				if (!this.submitDisabled) {
					this.handleSubmit();
				}
			},
			handleSubmit() {
				this.$emit('result', true);
			},
			handleClose() {
				this.$emit('result', false);
			},
		},

		computed: {
			submitDisabled() {
				if (!this.confirmText) return false;
				return !this.confirmation || this.confirmation !== this.confirmText;
			},
		},
	};
</script>

<style lang="scss">
	.confirm-dialog-div {
		background-color: var(--v-white-base);
		position: relative;
		border-radius: 4px !important;
		max-width: 460px;
		min-width: 360px;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
