import { render, staticRenderFns } from "./LetsGo.vue?vue&type=template&id=79cae84e&scoped=true&"
import script from "./LetsGo.vue?vue&type=script&lang=js&"
export * from "./LetsGo.vue?vue&type=script&lang=js&"
import style0 from "./LetsGo.vue?vue&type=style&index=0&id=79cae84e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79cae84e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1967088155/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79cae84e')) {
      api.createRecord('79cae84e', component.options)
    } else {
      api.reload('79cae84e', component.options)
    }
    module.hot.accept("./LetsGo.vue?vue&type=template&id=79cae84e&scoped=true&", function () {
      api.rerender('79cae84e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/welcome/LetsGo.vue"
export default component.exports