<template>
	<div class="mb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' },{label: 'Client portal' }]">
			Enabled features
		</page-header>


	<v-container fluid v-if="features" class="ma-0 pa-0">
		<v-row dense>
			<v-col cols="12">
				<div class="brand-medium text-left">Default feature access:</div>
				<v-switch
					v-model="features.projectsEnabled"
					label="Projects enabled"
					hide-details
					@change="saveFeatures"
				></v-switch>
				<v-switch
					v-model="features.invoicesEnabled"
					label="Invoices enabled"
					hide-details
					@change="saveFeatures"
				></v-switch>
				<v-switch
					v-model="features.summaryFinancialsEnabled"
					label="Financial reports / summary"
					hide-details
					@change="saveFeatures"
				></v-switch>
				<v-switch
					v-model="features.proposalsEnabled"
					label="Agreements enabled"
					hide-details
					@change="saveFeatures"
				></v-switch>
				<v-switch
					v-model="features.timeEnabled"
					label="Time worked enabled"
					hide-details
					@change="saveFeatures"
				></v-switch>
				<v-switch
					v-model="features.meetingsEnabled"
					label="Meetings enabled"
					hide-details
					@change="saveFeatures"
				></v-switch>
				<v-switch
					v-model="features.ticketsEnabled"
					label="Requests/tickets enabled"
					hide-details
					@change="saveFeatures"
				></v-switch>
			</v-col>
		</v-row>
	</v-container>
	</div>
</template>

<script>
	import PortalFeaturesService from '@/modules/portal/PortalFeaturesService';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'Features',

		props: ['filter'],

		components: {PageHeader},

		data: function() {
			return {
				portalFeatureService: new PortalFeaturesService(),
				features: null,
			};
		},

		mounted() {
			this.getFeatures();
			this.$store.state.eventBus.$on('account-changed', this.getFeatures);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getFeatures);
		},

		methods: {
			getFeatures: function() {
				this.portalFeatureService.getPortalFeatures().then((res) => (this.features = res.data));
			},

			saveFeatures: function() {
				this.portalFeatureService.updatePortalFeatures(this.features).then((res) => (this.features = res.data));
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
