var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "mt-4",
          staticStyle: { "max-width": "985px!important" }
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "brand-semilight",
                    staticStyle: { "font-size": "48px" }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "subscription-v" + this.pricingVersion + ".heading"
                        )
                      )
                    )
                  ]
                )
              ]),
              _c(
                "v-col",
                { staticClass: "my-4", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: {
                        mandatory: true,
                        color: "primary",
                        "background-color": "gray_5"
                      },
                      model: {
                        value: _vm.annual,
                        callback: function($$v) {
                          _vm.annual = $$v
                        },
                        expression: "annual"
                      }
                    },
                    [
                      _c("v-btn", { attrs: { large: "", value: false } }, [
                        _vm._v("Monthly")
                      ]),
                      _c("v-btn", { attrs: { large: "", value: true } }, [
                        _vm._v("Annually")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.$store.getters.isDisabledAccount
            ? _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-1 brand-medium row-format centered gap-2"
                      },
                      [
                        _c("v-icon", { attrs: { color: "error" } }, [
                          _vm._v("info")
                        ]),
                        _c(
                          "div",
                          { staticStyle: { color: "var(--v-error-base)" } },
                          [
                            _vm._v(
                              " Your current subscription has expired. Please select a new plan to continue using Moxie. "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.isCanceled
            ? _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-1 brand-medium row-format centered gap-2"
                      },
                      [
                        _c("v-icon", { attrs: { color: "primary" } }, [
                          _vm._v("info")
                        ]),
                        _c("div", { staticClass: "font-primary" }, [
                          _vm._v(
                            " Plan cancellation pending. Workspace active until " +
                              _vm._s(
                                _vm.HDateTime.fromISO(
                                  _vm.currentSubscription.recurlySubscription
                                    .currentPeriodEndsAt
                                ).toLocaleString(_vm.HDateTime.DATE_MED)
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "font-red font-12 pointer",
                            on: { click: _vm.reactivateCurrentPlan }
                          },
                          [_vm._v("[Reactivate plan]")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.pendingChange
            ? _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-1 brand-medium row-format centered gap-2"
                      },
                      [
                        _c("v-icon", { attrs: { color: "primary" } }, [
                          _vm._v("info")
                        ]),
                        _c("div", { staticClass: "font-primary" }, [
                          _vm._v(
                            " Plan changing to " +
                              _vm._s(
                                _vm.getFriendlyPlanName(
                                  _vm.pendingChange.plan.code
                                )
                              ) +
                              " on " +
                              _vm._s(
                                _vm.HDateTime.fromISO(
                                  _vm.pendingChange.activateAt
                                ).toLocaleString(_vm.HDateTime.DATE_FULL)
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "font-red font-12 pointer",
                            on: { click: _vm.cancelPendingChange }
                          },
                          [_vm._v("[Cancel change]")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format gap-4 pa-4",
                    staticStyle: { "justify-content": "center" }
                  },
                  _vm._l(_vm.filteredPlans, function(plan) {
                    return _c("plan-card", {
                      key: plan.code,
                      attrs: {
                        "pricing-version": _vm.pricingVersion,
                        "current-plan": _vm.currentPlan,
                        "in-trial": _vm.inTrial,
                        plan: plan,
                        disabled: _vm.pendingChange || _vm.isCanceled
                      },
                      on: {
                        click: function($event) {
                          return _vm.planClicked(plan)
                        }
                      }
                    })
                  }),
                  1
                ),
                _c("div", [_vm._v(" (All amounts in USD)")])
              ])
            ],
            1
          ),
          _vm.invoices.length || _vm.billingInfo
            ? _c(
                "v-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-left",
                      attrs: {
                        cols: "12",
                        md: "8",
                        order: "2",
                        "order-md": "1"
                      }
                    },
                    [_c("invoices", { attrs: { invoices: _vm.invoices } })],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-left",
                      attrs: {
                        cols: "12",
                        md: "4",
                        order: "1",
                        "order-md": "2"
                      }
                    },
                    [
                      _vm.billingInfo
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "brand-medium py-4 pr-4 row-format font-14"
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("subscription.payment-method")
                                    )
                                  )
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-primary pointer ml-auto",
                                    on: { click: _vm.updatePaymentInfo }
                                  },
                                  [_vm._v("Update")]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "mr-8",
                                staticStyle: {
                                  height: "42px",
                                  width: "28px",
                                  float: "left"
                                }
                              },
                              [
                                _c("payment-icon", {
                                  attrs: {
                                    size: "28",
                                    name: _vm.billingInfo.paymentMethod.cardType
                                  }
                                })
                              ],
                              1
                            ),
                            _c("p", {
                              staticClass: "mb-3",
                              domProps: { innerHTML: _vm._s(_vm.paymentMethod) }
                            }),
                            _c("p", {
                              staticStyle: {
                                "font-size": "14px",
                                color: "var(--v-gray_70-base)"
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.paymentMethodExp)
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm.currentSubscription && !_vm.inTrial && !_vm.isCanceled
                        ? _c("div", { staticClass: "row-format pr-4" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ml-auto font-red font-14 pointer",
                                on: {
                                  click: function($event) {
                                    _vm.planCancelDialog = true
                                  }
                                }
                              },
                              [_vm._v("Cancel current plan")]
                            )
                          ])
                        : _vm._e(),
                      _vm.activeCoupons.length
                        ? _c(
                            "div",
                            {
                              staticClass: "pa-2 font-14 mt-3",
                              staticStyle: {
                                border: "1px solid var(--v-gray_30-base)",
                                "border-radius": "4px"
                              }
                            },
                            [
                              _c("div", { staticClass: "brand-medium" }, [
                                _vm._v("Active discounts:")
                              ]),
                              _vm._l(_vm.activeCoupons, function(coupon) {
                                return _c(
                                  "div",
                                  { key: coupon.id, staticClass: "row-format" },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(coupon.coupon.name))
                                    ]),
                                    _c("div", { staticClass: "ml-auto" }, [
                                      _vm._v(
                                        _vm._s(coupon.coupon.discount.percent) +
                                          "%"
                                      )
                                    ])
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "div",
                    { staticClass: "brand-medium font-24 text-left mt-8" },
                    [_vm._v("Frequently Asked Questions")]
                  ),
                  _vm._l(_vm.faq, function(q, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "text-left brand-semilight my-4 pa-5",
                        staticStyle: {
                          "background-color": "var(--v-gray_5-base)",
                          "border-radius": "4px"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "font-20 row-format pointer",
                            on: {
                              click: function($event) {
                                _vm.isExpanded(index)
                                  ? _vm.collapseFaq(index)
                                  : _vm.expandFaq(index)
                              }
                            }
                          },
                          [
                            _c("div", [_vm._v(_vm._s(q.question))]),
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-auto",
                                attrs: { color: "black" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.isExpanded(index) ? "$minus" : "$plus"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.isExpanded(index)
                          ? _c(
                              "div",
                              {
                                staticClass: "mt-3",
                                staticStyle: { "white-space": "pre-wrap" }
                              },
                              [_vm._v(_vm._s(q.answer))]
                            )
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.paymentDialog
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.paymentDialog },
              on: {
                close: function($event) {
                  _vm.paymentDialog = false
                }
              }
            },
            [
              _c("payment", {
                attrs: {
                  "billing-info": _vm.billingInfo,
                  "current-plan": _vm.currentPlan,
                  "new-plan": _vm.pendingPlan
                },
                on: {
                  "billing-info": function($event) {
                    return _vm.billingInfoSet($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.planChangeDialog
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.planChangeDialog },
              on: {
                close: function($event) {
                  _vm.planChangeDialog = false
                }
              }
            },
            [
              _c("plan-change", {
                attrs: {
                  "current-subscription": _vm.currentSubscription,
                  "new-plan": _vm.pendingPlan
                },
                on: {
                  confirm: function($event) {
                    return _vm.changePlan()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.planCancelDialog
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.planCancelDialog },
              on: {
                close: function($event) {
                  _vm.planCancelDialog = false
                }
              }
            },
            [
              _c("plan-cancel", {
                on: {
                  confirmed: function($event) {
                    return _vm.cancelCurrentPlan()
                  },
                  close: function($event) {
                    _vm.planCancelDialog = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }