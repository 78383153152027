import { render, staticRenderFns } from "./PaymentPlan.vue?vue&type=template&id=42c51a54&scoped=true&"
import script from "./PaymentPlan.vue?vue&type=script&lang=js&"
export * from "./PaymentPlan.vue?vue&type=script&lang=js&"
import style0 from "./PaymentPlan.vue?vue&type=style&index=0&id=42c51a54&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42c51a54",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1967088155/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42c51a54')) {
      api.createRecord('42c51a54', component.options)
    } else {
      api.reload('42c51a54', component.options)
    }
    module.hot.accept("./PaymentPlan.vue?vue&type=template&id=42c51a54&scoped=true&", function () {
      api.rerender('42c51a54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/agreements/schema/PaymentPlan.vue"
export default component.exports