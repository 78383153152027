var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ticketSettings
    ? _c(
        "div",
        [
          _c(
            "page-header",
            {
              staticClass: "pb-6",
              attrs: {
                "bread-crumbs": [
                  { to: "/home", label: "Home" },
                  { to: "/tickets", label: "Tickets" }
                ]
              }
            },
            [_vm._v(" Settings ")]
          ),
          !_vm.$store.getters.hasFeatureAccess("settings")
            ? _c("div", { staticClass: "pa-12" }, [
                _vm._v(" No access to ticket settings. ")
              ])
            : _c(
                "v-container",
                { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
                [
                  _c("input", {
                    attrs: {
                      id: "forwardingAddress",
                      name: "forwardingAddress",
                      type: "hidden"
                    },
                    domProps: { value: _vm.forwardingAddress }
                  }),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-3", attrs: { cols: "12" } },
                        [
                          _c("v-data-table", {
                            staticClass: "pointer",
                            attrs: {
                              items: _vm.ticketSettings.ticketTypes,
                              headers: _vm.headers,
                              "hide-default-footer": true,
                              "items-per-page": -1
                            },
                            on: {
                              "click:row": function($event) {
                                return _vm.editTicketType($event)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.attachedFormId",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getTemplateById(
                                              item.attachedFormId
                                            ).name
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "item.id",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "pointer",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.confirmDelete(item)
                                            }
                                          }
                                        },
                                        [_vm._v("$delete")]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "header.id",
                                  fn: function(ref) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "row-format" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-auto pointer",
                                              attrs: { size: "20" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNew()
                                                }
                                              }
                                            },
                                            [_vm._v("$plus")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3060017729
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left font-14",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("div", { staticClass: "pt-8" }, [
                            _vm._v(
                              " New requests can be opened by known contacts by emailing "
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-primary brand-medium pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.copyToClipboard()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.forwardingAddress))]
                            ),
                            _vm._v(
                              ". If you have a custom domain with email hosting you can consider creating a forwarding email address from a support@ or help@ account on your domain to this email. Please check with your email provider on how to configure email forwarding. "
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              " In addition, if your client emails you directly, you can forward their email to "
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-primary brand-medium pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.copyToClipboard()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.forwardingAddress))]
                            ),
                            _vm._v(
                              " directly and the email will be converted into a request for the client contact. "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left pt-8",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("span", { staticClass: "font-16" }, [
                            _vm._v("Registered Forwarding Addresses")
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "font-12 font-gray_70" }, [
                            _vm._v(
                              "With most email providers, you will need to register your support@ or help@ forwarded email address below to ensure our email routing system can properly process your forwarded messages."
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "row-format gap-2",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              "persistent-placeholder": "",
                              outlined: "",
                              placeholder: "support@yourdomain.com"
                            },
                            model: {
                              value: _vm.customForwardingAddress,
                              callback: function($$v) {
                                _vm.customForwardingAddress = $$v
                              },
                              expression: "customForwardingAddress"
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "super-action",
                              staticStyle: { height: "40px!important" },
                              attrs: { disabled: !_vm.customForwardingAddress },
                              on: {
                                click: function($event) {
                                  return _vm.addCustomForwardingAddress()
                                }
                              }
                            },
                            [_vm._v("Register address")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.customForwardingAddresses, function(address) {
                    return _c(
                      "v-row",
                      {
                        key: address.email,
                        staticClass: "text-left font-14 py-3",
                        staticStyle: {
                          "border-bottom": "1px solid var(--v-gray_30-base)"
                        },
                        attrs: { dense: "" }
                      },
                      [
                        _c("v-col", { attrs: { cols: "6" } }, [
                          _vm._v(_vm._s(address.email))
                        ]),
                        _c("v-col", { attrs: { cols: "4" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.DateTime.fromISO(
                                address.timestamp
                              ).toLocaleString(_vm.DateTime.DATETIME_MED)
                            )
                          )
                        ]),
                        _c(
                          "v-col",
                          { staticClass: "text-right", attrs: { cols: "2" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-symbols-rounded pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteCustomForwardingAddress(
                                      address.email
                                    )
                                  }
                                }
                              },
                              [_vm._v("delete")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }