var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-modal", staticStyle: { width: "600px" } },
    [
      _c("div", { staticClass: "panel-modal-header" }, [
        _c(
          "div",
          { staticClass: "row-format align-center" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mr-2 pointer",
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.save(false)
                  }
                }
              },
              [_vm._v("$chevronRight")]
            ),
            _c(
              "v-icon",
              {
                staticClass: "mr-1 material-symbols-rounded",
                attrs: { color: "black" }
              },
              [_vm._v("splitscreen")]
            ),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "ml-auto" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action",
                    on: {
                      click: function($event) {
                        return _vm.save(true)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                ),
                _c(
                  "v-icon",
                  {
                    staticClass: "pointer ml-2",
                    attrs: { size: "20", color: "gray_60" },
                    on: {
                      click: function($event) {
                        return _vm.confirmDelete()
                      }
                    }
                  },
                  [_vm._v("$delete")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "panel-modal-body text-left" }, [
        _c(
          "div",
          { staticClass: "column-format pt-4 pl-3" },
          [
            _c(
              "div",
              { staticClass: "row-format" },
              [
                _c("v-text-field", {
                  attrs: {
                    autofocus: "",
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    placeholder: "Task name"
                  },
                  model: {
                    value: _vm.ourDeliverable.name,
                    callback: function($$v) {
                      _vm.$set(_vm.ourDeliverable, "name", $$v)
                    },
                    expression: "ourDeliverable.name"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "mt-6 mb-4 row-format align-center" }, [
              _c(
                "div",
                {
                  class:
                    "page-link " +
                    (_vm.currentView === "Overview" ? "page-link-active" : ""),
                  on: {
                    click: function($event) {
                      return _vm.setView("Overview")
                    }
                  }
                },
                [_c("div", [_vm._v("Overview")])]
              ),
              _c(
                "div",
                {
                  class:
                    "page-link " +
                    (_vm.currentView === "Recurrence"
                      ? "page-link-active"
                      : ""),
                  on: {
                    click: function($event) {
                      return _vm.setView("Recurrence")
                    }
                  }
                },
                [_c("div", [_vm._v("Recurrence")])]
              )
            ]),
            _vm.currentView === "Overview"
              ? _c("deliverable-detail", {
                  attrs: {
                    "hide-task-name": true,
                    "fee-schedule": _vm.deliverablePackage.feeSchedule,
                    deliverable: _vm.ourDeliverable,
                    "attachment-service": _vm.attachmentService
                  }
                })
              : _c(
                  "div",
                  { staticClass: "column-format gap-3" },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "Task type",
                        "hide-details": "",
                        outlined: "",
                        dense: "",
                        "persistent-placeholder": "",
                        items: _vm.recurringEnable,
                        "item-value": "value",
                        "item-text": "text"
                      },
                      model: {
                        value: _vm.ourDeliverable.isRecurring,
                        callback: function($$v) {
                          _vm.$set(_vm.ourDeliverable, "isRecurring", $$v)
                        },
                        expression: "ourDeliverable.isRecurring"
                      }
                    }),
                    _vm.ourDeliverable.isRecurring
                      ? _c(
                          "div",
                          { staticClass: "column-format gap-3" },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "Recurring type",
                                "hide-details": "",
                                outlined: "",
                                dense: "",
                                "persistent-placeholder": "",
                                items: _vm.recurringOptions,
                                "item-value": "value",
                                "item-text": "text"
                              },
                              model: {
                                value:
                                  _vm.ourDeliverable.recurringSettings
                                    .recurrenceType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ourDeliverable.recurringSettings,
                                    "recurrenceType",
                                    $$v
                                  )
                                },
                                expression:
                                  "ourDeliverable.recurringSettings.recurrenceType"
                              }
                            }),
                            _vm.ourDeliverable.recurringSettings
                              .recurrenceType === "CUSTOM"
                              ? _c("v-select", {
                                  attrs: {
                                    label: "Day of month",
                                    "hide-details": "",
                                    outlined: "",
                                    dense: "",
                                    "persistent-placeholder": "",
                                    items: _vm.daysInMonth,
                                    "item-value": "value",
                                    "item-text": "text"
                                  },
                                  model: {
                                    value:
                                      _vm.ourDeliverable.recurringSettings
                                        .monthlyDayOfMonth,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ourDeliverable.recurringSettings,
                                        "monthlyDayOfMonth",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ourDeliverable.recurringSettings.monthlyDayOfMonth"
                                  }
                                })
                              : _vm._e(),
                            _vm.ourDeliverable.recurringSettings
                              .recurrenceType === "MONTHLY"
                              ? _c(
                                  "div",
                                  { staticClass: "row-format gap-3" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        label: "Ordinal",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        "persistent-placeholder": "",
                                        items: _vm.weeks,
                                        "item-value": "value",
                                        "item-text": "text"
                                      },
                                      model: {
                                        value:
                                          _vm.ourDeliverable.recurringSettings
                                            .monthlyWeekOfMonth,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ourDeliverable
                                              .recurringSettings,
                                            "monthlyWeekOfMonth",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ourDeliverable.recurringSettings.monthlyWeekOfMonth"
                                      }
                                    }),
                                    _c("v-select", {
                                      attrs: {
                                        label: "Day of week",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        "persistent-placeholder": "",
                                        items: _vm.daysInWeek,
                                        "item-value": "value",
                                        "item-text": "text"
                                      },
                                      model: {
                                        value:
                                          _vm.ourDeliverable.recurringSettings
                                            .monthlyDayOfWeek,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ourDeliverable
                                              .recurringSettings,
                                            "monthlyDayOfWeek",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ourDeliverable.recurringSettings.monthlyDayOfWeek"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.ourDeliverable.recurringSettings
                              .recurrenceType === "WEEKLY"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "column-format text-left pa-2",
                                    staticStyle: {
                                      border: "1px solid var(--v-gray_50-base)",
                                      "border-radius": "4px",
                                      position: "relative"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "font-12 font-gray_70",
                                        staticStyle: {
                                          "background-color":
                                            "var(--v-white-base)",
                                          position: "absolute",
                                          top: "-9px",
                                          "padding-left": "2px"
                                        }
                                      },
                                      [_vm._v("Day of week")]
                                    ),
                                    _c("daily-scheduler", {
                                      model: {
                                        value:
                                          _vm.ourDeliverable.recurringSettings
                                            .weeklyDays,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ourDeliverable
                                              .recurringSettings,
                                            "weeklyDays",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ourDeliverable.recurringSettings.weeklyDays"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "brand-medium font-18" }, [
      _c("div", [_vm._v("Edit task")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }