<template>
	<v-data-table
		:items="filteredInvoiceTemplates"
		:fixed-header="true"
		:headers="headers"
		@click:row="editTemplate($event)"
		style="cursor: pointer"
		:hide-default-footer="true"
		:items-per-page="-1"
		sort-by="name"
	>
		<template v-slot:item.name="{ item }">
			{{ item.name }}
		</template>
		<template v-slot:item.created="{ item }">
			{{ DateTime.fromISO(item.created).toLocaleString(DateTime.DATE_MED) }}
		</template>
		<template v-slot:item.updated="{ item }">
			<span v-if="item.updated">{{ DateTime.fromISO(item.updated).toLocaleString(DateTime.DATE_MED) }}</span>
			<span v-else>--</span>
		</template>
		<template v-slot:item.defaultTemplate="{ item }">
			<v-icon small v-if="item.defaultTemplate">check</v-icon>
		</template>
	</v-data-table>
</template>

<script>
	import InvoiceTemplateService from '@/modules/templates/invoices/InvoiceTemplateService';
	import HDateTime from '@/modules/utils/HDateTime';
	import InvoiceTemplateBuilder from '@/modules/templates/invoices/InvoiceTemplateBuilder';
	import DefaultTemplate from '@/modules/templates/invoices/DefaultTemplate';

	export default {
		name: 'InvoiceTemplateList',

		props: ['search'],

		components: {
		},

		data: function() {
			return {
				DateTime: HDateTime,
				invoiceTemplateService: new InvoiceTemplateService(),
				invoices: [],
				DefaultTemplate: DefaultTemplate,
				showMenu: false,
			};
		},

		mounted() {
			this.getInvoiceTemplates();
		},

		beforeDestroy() {},

		methods: {
			getInvoiceTemplates: function() {
				this.invoiceTemplateService.getInvoiceTemplates().then((res) => {
					this.invoices.splice(0);
					this.invoices.push(...res.data);
					if (!this.invoices.length) {
						this.createDefaultTemplate();
					}
				});
			},

			createDefaultTemplate: function() {
				this.invoiceTemplateService.createInvoiceTemplate(DefaultTemplate).then((res) => {
					this.invoices.push(res.data);
				});
			},

			addNew: function() {
				let template = JSON.parse(JSON.stringify(DefaultTemplate));
				template.name = "New template";
				template.defaultTemplate = this.invoices.length === 0;
				this.invoiceTemplateService.createInvoiceTemplate(template).then((res) => {
					this.invoices.push(res.data);
					this.editTemplate(res.data);
				});
			},

			editTemplate: function(template) {
				this.$store.state.globalModalController
					.openModal(InvoiceTemplateBuilder, { id: template.id }, false, true)
					.then((res) => {
						let ix = this.invoices.findIndex((i) => i.id === template.id);
						if (res.deleted) {
							this.invoices.splice(ix, 1);
						} else if (res.duplicate) {
							this.duplicateTemplate(res);
						} else {
							this.invoices.splice(ix, 1, res);
						}
					});
			},

			duplicateTemplate: function(template) {
				template.id = null;
				template.name = 'Copy of: ' + template.name;
				template.defaultTemplate = false;
				this.invoiceTemplateService.createInvoiceTemplate(template).then((res) => {
					this.invoices.push(res.data);
					setTimeout(() => this.editTemplate(res.data), 250);
				});
			},
		},

		computed: {
			headers: function() {
				return [
					{ text: this.$t('templates.invoices.name'), value: 'name' },
					{ text: this.$t('templates.invoices.created'), value: 'created' },
					{ text: this.$t('templates.invoices.updated'), value: 'updated' },
					{ text: this.$t('templates.invoices.default'), value: 'defaultTemplate'},
				];
			},

			filteredInvoiceTemplates: function() {
				if (this.search) {
					return this.invoices.filter((t) => {
						let search = this.search.toLowerCase();

						return t.name.toLowerCase().includes(search);
					});
				} else {
					return this.invoices;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
