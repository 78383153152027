<template>
	<div class="column-format text-left">
		<div class="row-format gap-2 font-14 font-gray_70">
			<div v-for="(crumb, index) in breadCrumbs" :key="index" class="row-format gap-2">
				<div v-if="crumb.to" class="pointer" @click="handleClick($event,crumb.to)">{{ crumb.label }}</div><div v-else>{{ crumb.label }}</div><div v-if="index < breadCrumbs.length - 1">/</div>
			</div>
			<div v-if="compact" class="row-format gap-2">
				<div>/</div>
				<div class="font-gray_90" style="font-weight: 600"><slot></slot></div>
			</div>
		</div>
		<div v-if="!compact" style="font-size: 24px;" class="brand-medium ">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PageHeader',

		props: {
			breadCrumbs: {
				type: Array,
				required: true
			},
			compact: {
				type: Boolean,
				default: true
			}
		},

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleClick: function(event,to) {
				if (event && (event.ctrlKey || event.metaKey)) {
					let w = window.open(to, '_blank');
					w.focus();
				} else {
					this.$router.push(to);
				}
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
