var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: { "bread-crumbs": [{ to: "/home", label: "Home" }] }
        },
        [_vm._v(" Data import ")]
      ),
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "column-format gap-3" },
                  [
                    _c("v-select", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                        label: "What type of data?",
                        items: _vm.definitions,
                        "item-value": "type",
                        "item-text": "label"
                      },
                      model: {
                        value: _vm.importType,
                        callback: function($$v) {
                          _vm.importType = $$v
                        },
                        expression: "importType"
                      }
                    }),
                    _c("v-file-input", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                        "prepend-icon": "",
                        "prepend-inner-icon": "attach_file",
                        label: "Select import file",
                        accept: ".csv"
                      },
                      on: { change: _vm.handleInput },
                      model: {
                        value: _vm.importFile,
                        callback: function($$v) {
                          _vm.importFile = $$v
                        },
                        expression: "importFile"
                      }
                    }),
                    _vm.parsedData.length
                      ? _c("div", { staticClass: "font-14" }, [
                          _vm._v(
                            _vm._s(_vm.parsedData.length) + " records found"
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "row-format centered" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "super-action",
                            staticStyle: {
                              width: "300px",
                              "max-width": "300px"
                            },
                            attrs: { disabled: !_vm.parsedData.length },
                            on: { click: _vm.mapColumns }
                          },
                          [_vm._v("Next: Map columns")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "mt-4",
        attrs: {
          headers: _vm.headers,
          "sort-by": "importDateTime",
          "sort-desc": true,
          items: _vm.imports,
          "items-per-page": -1,
          "hide-default-footer": true,
          "disable-pagination": true,
          "mobile-breakpoint": 900
        },
        scopedSlots: _vm._u([
          {
            key: "item.importDateTime",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.DateTime.fromISO(item.importDateTime).toLocaleString(
                        _vm.DateTime.DATETIME_MED
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.id",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    staticClass: "row-format pointer",
                    on: {
                      click: function($event) {
                        return _vm.confirmDelete(item)
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass:
                          "ml-auto mr-1 pointer material-symbols-rounded",
                        attrs: { size: "20" }
                      },
                      [_vm._v("delete")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [_vm._v(" No previous imports. ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }