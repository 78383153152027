import HttpClient from '@/services/HttpClient';

export default class ScheduledMeetingService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    getScheduledMeetingList(clientId = null) {
        return this.httpClient.get(`/scheduledMeetings`, {
                params: {
                    clientId: clientId
                }
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getScheduledMeeting(id) {
        return this.httpClient.get(`/scheduledMeetings/${id}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    updateScheduledMeeting(id,scheduledMeeting){
        return this.httpClient.put(`/scheduledMeetings/${id}`,scheduledMeeting)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    deleteScheduledMeeting(id){
        return this.httpClient.delete(`/scheduledMeetings/${id}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }


    getSignedUrl(id, fileName) {
        return this.httpClient
            .get(`/scheduledMeetings/${id}/files?fileName=${encodeURIComponent(fileName)}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}
