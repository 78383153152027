var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-3 pa-3 text-left" },
    [
      _c("v-select", {
        attrs: {
          "persistent-placeholder": "",
          "hide-details": "",
          dense: "",
          outlined: "",
          items: _vm.invoiceTemplates,
          "item-value": "id",
          "item-text": "name",
          label: "Invoice template"
        },
        on: { change: _vm.invoiceTemplateUpdated },
        model: {
          value: _vm.invoiceTemplateId,
          callback: function($$v) {
            _vm.invoiceTemplateId = $$v
          },
          expression: "invoiceTemplateId"
        }
      }),
      !_vm.recurring && !_vm.paymentPlan
        ? _c("v-text-field", {
            attrs: {
              disabled: _vm.invoiceNumberLocked,
              "hide-details": "",
              dense: "",
              outlined: "",
              "persistent-placeholder": "",
              label: "Invoice number"
            },
            on: {
              blur: function($event) {
                _vm.invoiceNumberLocked = true
              },
              change: _vm.invoiceNumberUpdated
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function() {
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pointer",
                          on: {
                            click: function($event) {
                              _vm.invoiceNumberLocked = !_vm.invoiceNumberLocked
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.invoiceNumberLocked ? "lock" : "lock_open"
                            )
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1123170182
            ),
            model: {
              value: _vm.invoiceNumberFormatted,
              callback: function($$v) {
                _vm.invoiceNumberFormatted = $$v
              },
              expression: "invoiceNumberFormatted"
            }
          })
        : _vm._e(),
      _vm.recurring || _vm.paymentPlan
        ? _c("v-select", {
            attrs: {
              items: _vm.dayOptions,
              "hide-details": "",
              dense: "",
              outlined: "",
              "persistent-placeholder": "",
              label: "Due after send"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item === -1
                        ? _c("span", [_vm._v("1st of next month")])
                        : item === -2
                        ? _c("span", [_vm._v("15th of next month")])
                        : item === 0
                        ? _c("span", [_vm._v("On Receipt")])
                        : _c("span", [_vm._v(_vm._s(item) + " days")])
                    ]
                  }
                },
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item === -1
                        ? _c("span", [_vm._v("1st of next month")])
                        : item === -2
                        ? _c("span", [_vm._v("15th of next month")])
                        : item === 0
                        ? _c("span", [_vm._v("On Receipt")])
                        : _c("span", [_vm._v(_vm._s(item) + " days")])
                    ]
                  }
                }
              ],
              null,
              false,
              1188985468
            ),
            model: {
              value: _vm.invoice.paymentTerms.paymentDays,
              callback: function($$v) {
                _vm.$set(_vm.invoice.paymentTerms, "paymentDays", $$v)
              },
              expression: "invoice.paymentTerms.paymentDays"
            }
          })
        : _vm._e(),
      !_vm.recurring && !_vm.paymentPlan
        ? _c("date-selector", {
            attrs: {
              label: "Date issued",
              standard: true,
              date: _vm.invoice.dateSent,
              "show-year": true
            },
            on: {
              change: function($event) {
                return _vm.dateSentUpdated($event)
              }
            }
          })
        : _vm._e(),
      !_vm.recurring && !_vm.paymentPlan
        ? _c("date-selector", {
            attrs: {
              label: "Date due",
              standard: true,
              date: _vm.invoice.dateDue,
              "show-year": true
            },
            on: {
              change: function($event) {
                return _vm.dateDueUpdated($event)
              }
            }
          })
        : _vm._e(),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Purchase Order"
        },
        on: { change: _vm.purchaseOrderUpdated },
        model: {
          value: _vm.purchaseOrder,
          callback: function($$v) {
            _vm.purchaseOrder = $$v
          },
          expression: "purchaseOrder"
        }
      }),
      _c("v-text-field", {
        attrs: {
          type: "number",
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Monthly late fee percentage",
          suffix: "%"
        },
        model: {
          value: _vm.invoice.paymentTerms.latePaymentFee,
          callback: function($$v) {
            _vm.$set(_vm.invoice.paymentTerms, "latePaymentFee", $$v)
          },
          expression: "invoice.paymentTerms.latePaymentFee"
        }
      }),
      _c("v-text-field", {
        attrs: {
          type: "number",
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Discount percentage",
          suffix: "%"
        },
        model: {
          value: _vm.invoice.discountPercentage,
          callback: function($$v) {
            _vm.$set(_vm.invoice, "discountPercentage", $$v)
          },
          expression: "invoice.discountPercentage"
        }
      }),
      _vm.invoice.taxRateLocked || _vm.additionalTaxRates.length
        ? _c(
            "div",
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": true,
                    "close-on-click": true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                {
                                  attrs: {
                                    type: "number",
                                    readonly: true,
                                    min: 0,
                                    max: 100,
                                    step: "1",
                                    "hide-details": "",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    label: "Tax rate",
                                    suffix: "%"
                                  },
                                  model: {
                                    value: _vm.invoice.taxPercentage,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.invoice,
                                        "taxPercentage",
                                        $$v
                                      )
                                    },
                                    expression: "invoice.taxPercentage"
                                  }
                                },
                                on
                              )
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1962432660
                  )
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "column-format pa-3 font-14 gap-2",
                      staticStyle: { "background-color": "var(--v-white-base)" }
                    },
                    _vm._l(_vm.additionalTaxRates, function(taxRate) {
                      return _c(
                        "div",
                        {
                          key: taxRate.id,
                          staticClass: "row-format gap-3 pointer",
                          on: {
                            click: function($event) {
                              return _vm.taxRateSelected(taxRate)
                            }
                          }
                        },
                        [
                          _c("div", [_vm._v(_vm._s(taxRate.name))]),
                          _c("div", { staticClass: "ml-auto" }, [
                            _vm._v(_vm._s(taxRate.rate) + "%")
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  disabled: _vm.invoice.taxRateLocked,
                  min: 0,
                  max: 100,
                  step: "1",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Tax rate",
                  suffix: "%"
                },
                model: {
                  value: _vm.invoice.taxPercentage,
                  callback: function($$v) {
                    _vm.$set(_vm.invoice, "taxPercentage", $$v)
                  },
                  expression: "invoice.taxPercentage"
                }
              })
            ],
            1
          ),
      _c("v-select", {
        attrs: {
          "persistent-placeholder": "",
          "hide-details": "",
          dense: "",
          outlined: "",
          items: _vm.paidByOptions,
          label: "Pass credit card fees to client?"
        },
        model: {
          value: _vm.invoice.paymentTerms.whoPaysCardFees,
          callback: function($$v) {
            _vm.$set(_vm.invoice.paymentTerms, "whoPaysCardFees", $$v)
          },
          expression: "invoice.paymentTerms.whoPaysCardFees"
        }
      }),
      !_vm.hideAutoPay
        ? _c("v-select", {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: {
                  content:
                    "If set, the assigned payment method will be automatically charged on the due date."
                },
                expression:
                  "{\n\t\t\tcontent: 'If set, the assigned payment method will be automatically charged on the due date.',\n\t\t}"
              }
            ],
            attrs: {
              "hide-details": "",
              "persistent-placeholder": "",
              dense: "",
              outlined: "",
              label: "Auto charge payment method (optional)",
              items: _vm.paymentMethods,
              "item-value": "id",
              "return-object": true,
              clearable: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        { staticStyle: { "text-transform": "capitalize" } },
                        [
                          _vm._v(
                            _vm._s(item.label.toLowerCase()) +
                              " - " +
                              _vm._s(item.last4)
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        { staticStyle: { "text-transform": "capitalize" } },
                        [
                          _vm._v(
                            _vm._s(item.label.toLowerCase()) +
                              " - " +
                              _vm._s(item.last4)
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              104597948
            ),
            model: {
              value: _vm.invoice.autoPayMethod,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "autoPayMethod", $$v)
              },
              expression: "invoice.autoPayMethod"
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "h-outline", staticStyle: { position: "relative" } },
        [
          _c("editor", {
            ref: "editor",
            staticClass: "text-left px-1 pt-3",
            staticStyle: {
              width: "100%",
              "min-height": "100px",
              "border-bottom": "none",
              "font-size": "0.9em"
            },
            attrs: {
              "api-key": _vm.$store.getters.getTinyMceKey,
              "initial-value": _vm.payInstructions,
              inline: true,
              init: _vm.mceConfigText,
              spellcheck: true
            },
            on: { input: _vm.handlePayInstructionsInput }
          }),
          _c("div", { staticClass: "phone-label" }, [
            _vm._v("Payment instructions")
          ])
        ],
        1
      ),
      _c("v-switch", {
        staticClass: "ma-0 pa-0",
        attrs: {
          "hide-details": "",
          dense: "",
          label: "Online payments enabled"
        },
        model: {
          value: _vm.invoice.allowOnlinePayment,
          callback: function($$v) {
            _vm.$set(_vm.invoice, "allowOnlinePayment", $$v)
          },
          expression: "invoice.allowOnlinePayment"
        }
      }),
      _vm.$store.state.podAccount.accountPreferences.invoiceDueNotification !==
      "DISABLED"
        ? _c("v-switch", {
            staticClass: "ma-0 pa-0",
            attrs: {
              "hide-details": "",
              dense: "",
              label: "Payment reminders enabled"
            },
            model: {
              value: _vm.invoice.remindersEnabled,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "remindersEnabled", $$v)
              },
              expression: "invoice.remindersEnabled"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }