var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-format text-left" }, [
    _c(
      "div",
      { staticClass: "row-format gap-2 font-14 font-gray_70" },
      [
        _vm._l(_vm.breadCrumbs, function(crumb, index) {
          return _c("div", { key: index, staticClass: "row-format gap-2" }, [
            crumb.to
              ? _c(
                  "div",
                  {
                    staticClass: "pointer",
                    on: {
                      click: function($event) {
                        return _vm.handleClick($event, crumb.to)
                      }
                    }
                  },
                  [_vm._v(_vm._s(crumb.label))]
                )
              : _c("div", [_vm._v(_vm._s(crumb.label))]),
            index < _vm.breadCrumbs.length - 1
              ? _c("div", [_vm._v("/")])
              : _vm._e()
          ])
        }),
        _vm.compact
          ? _c("div", { staticClass: "row-format gap-2" }, [
              _c("div", [_vm._v("/")]),
              _c(
                "div",
                {
                  staticClass: "font-gray_90",
                  staticStyle: { "font-weight": "600" }
                },
                [_vm._t("default")],
                2
              )
            ])
          : _vm._e()
      ],
      2
    ),
    !_vm.compact
      ? _c(
          "div",
          { staticClass: "brand-medium", staticStyle: { "font-size": "24px" } },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }