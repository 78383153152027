<template>
	<div v-if="isReady" class="mb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' },{label: 'Client portal' }]">
			Permissions
		</page-header>

		<div class="row-format align-center mb-4">
			<permission-filter :filter="filter" @filter="setFilter($event)"></permission-filter>
    </div>
		<v-data-table
				:headers="headers"
				:items="filteredClients"
				:hide-default-footer="true"
				sort-by="name"
				:fixed-header="true"
				style="cursor: pointer"
				:items-per-page="-1"
				@click:row="editClientPermissions($event)"
		>
			<template v-slot:item.name="{ item }">
				<div class="row-format align-center">
					<client-avatar :client="item"></client-avatar>
					<div>
						<div>{{item.name}}</div>
						<div v-if="item.archive" class="font-gray_70 font-12">(Archived)</div>
					</div>

				</div>
			</template>

			<template v-slot:item.contacts="{ item }">
				<div v-for="(contact,index) in item.contacts.filter(c => c.portalAccess)" :key="contact.id">
					{{contact.firstName}} {{contact.lastName}}<br>
					<div class="font-primary font-12">{{contact.email}}</div>
					<div v-if="index < item.contacts.filter(c => c.portalAccess).length-1" class="mb-3">
					</div>
				</div>
				<div v-if="item.contacts.filter(c => c.portalAccess).length === 0" style="color: var(--v-gray_50-base)">
					No contacts with access
				</div>
			</template>

			<template v-slot:item.access="{ item }">
				<div class="row-format align-center">
					<div v-if="hasCustomAccess(item.id)" class="row-format align-center"><v-icon small>lock</v-icon><div class="ml-2">Enabled</div></div>
					<div v-else> -- </div>
				</div>
			</template>

			<template v-slot:item.action="{item}">
				<v-menu :close-on-click="true" :nudge-bottom="30" :disabled="customDomain === null">
					<template v-slot:activator="scope">
						<div class="ml-1">
							<v-btn icon class="menu-activator" v-on="scope.on">
								<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click="viewPortalAs(item)">
							View portal as {{item.name}}
						</div>
					</div>
				</v-menu>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import ClientService from "@/modules/clients/ClientService";
	import ClientAvatar from "@/components/ClientAvatar";
	import PermissionModal from "@/modules/portal/PermissionModal";
	import DateTime from "@/modules/utils/HDateTime";
	import AuthService from "@/modules/auth/AuthService";
	import CustomDomainService from "@/modules/portal/CustomDomainService";
	import PortalFeaturesService from "@/modules/portal/PortalFeaturesService";
	import PermissionFilter from "@/modules/portal/PermissionFilter";
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'Permissions',

		props: [],

		components: {ClientAvatar, PermissionFilter, PageHeader},

		data: function () {
			return {
        textFilter: null,
				clients: [],
				clientService: new ClientService(),
				customDomainService: new CustomDomainService(),
				portalFeatureService: new PortalFeaturesService(),
				isReady: false,
				refreshKey:0,
				customDomain: null,
				features: null,

				filter: {
					textFilter: null,
					status: 'active'
				},

				headers: [
					{ text: 'Client', value: 'name', width: 300 },
					{ text: 'Contacts with access', value: 'contacts', sortable: false },
					{ text: 'Custom access', value: 'access', sortable: false},
					{ text: '', value: 'action', align: 'right', sortable: false},
				],
			};
		},

		mounted() {
			this.getClients();
			this.getCustomDomains();
			this.getFeatures();
			this.$store.state.eventBus.$on('account-changed', this.getClients);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getClients);
		},

		methods: {
			getClients: function () {
				this.clientService.getClientList('no',true).then((res) => {
					this.clients.splice(0,this.clients.length);
					this.clients.push(... res.data);
					this.isReady = true;
				})
			},

			getFeatures: function () {
				this.portalFeatureService.getPortalFeatures().then((res) => {
					this.features = res.data;
				});
			},

			hasCustomAccess: function(clientId){
				if(this.features && this.features.clientFeatures[clientId]){
					return true;
				}else{
					return false;
				}

			},

			getCustomDomains: function () {
				this.customDomainService.getCustomDomains().then((res) => {
					let domains = [... res.data];
					if(domains.length){
						domains = domains.filter(d => d.currentStatus === 'READY');
						console.log(domains);
						for(let i=0; i < domains.length; i++){
							if(domains[i].providedDomain){
								continue
							}else{
								this.customDomain = domains[i]
								return;
							}
						}
						this.customDomain = domains[0];
					}
				});
			},

			viewPortalAs: function(client){
				let clientAccess = {
					clients: [
						{
							clientId : client.id,
							clientName : client.name,
							granted : DateTime.now().toISO(),
						}
					]
				}

				AuthService.getPortalAccessToken(clientAccess).then((res) => {
					let url = `https://${this.customDomain.id}/magicToken?origUri=/welcome&magicToken=${res.data}`;
					window.open(url,'_blank');
				})
			},

			editClientPermissions: function(client){
				let binding = {
					client:client,
					features: this.features,
				}
				this.$store.state.globalModalController.openModal(PermissionModal,binding,true,false).then((res) => {
					if(res){
						let ix = this.clients.findIndex(c => c.id === res.id);
						this.clients.splice(ix,1,res);
						this.refreshKey++;
					}
					this.getFeatures();
				})
			},

			setFilter: function (filter) {
				this.filter = filter;
			},
		},

		computed: {
			filteredClients: function(){
				return this.clients
						.filter((client) => {
							let filterString = this.filter.textFilter;
							if (this.$validations.isEmpty(filterString) || this.$validations.isEmpty(client.name)) {
								return true;
							} else {
								if (client.name.toLowerCase().includes(filterString.toLowerCase())) return true;

								let contacts = client.contacts.filter(c => c.portalAccess);

								for (let i = 0; i < contacts.length; i++) {
									let email = contacts[i].email ?? '';
									let lastName = contacts[i].lastName ?? '';
									let firstName = contacts[i].firstName ?? '';

									if (
											lastName.toLowerCase().startsWith(filterString.toLowerCase()) ||
											firstName.toLowerCase().startsWith(filterString.toLowerCase()) ||
											email.toLowerCase().startsWith(filterString.toLowerCase())
									) {
										return true;
									}
								}

								return false;
							}
						}).filter((client) => {
							let filterState = this.filter.status;
							if(filterState === 'active' && client.archive === false){
								return true;
							}else if(filterState === 'archive' && client.archive){
								return true;
							}else if(filterState === 'all'){
								return true;
							}else{
								return false;
							}
						})
			}
		},
	};
</script>

<style scoped lang="scss"></style>
