import Vue from 'vue';
import Vuex from 'vuex';
import PubNub from 'pubnub';
import AuthService from '@/modules/auth/AuthService';
import AccountService from '@/modules/account/AccountService';
import CommunicatorService from '@/modules/communicator/CommunicatorService';
import DeliverableStatusService from '@/modules/projects/DeliverableStatusService';
import ClientService from '@/modules/clients/ClientService';
import router from '../router';
import CustomEventBus from '@/utils/CustomEventBus';
import CustomEmailService from '@/modules/portal/CustomEmailService';
import { DateTime } from 'luxon';
import CustomFieldsService from '@/modules/fields/CustomFieldsService';
import userflow from 'userflow.js';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		mainApp: true,
		accessToken: null,
		loggedIn: false,
		inWelcomeFlow: false,
		newUser: false,
		loggedInUser: null,
		initialized: false,
		defaultView: 'home',
		origRequestedUri: '/home',
		showModal: false,
		alert: { type: null, message: null },
		pubNub: null,
		pubNubToken: null,
		pubNubChannels: [],
		eventBus: new CustomEventBus(),
		deliverableStatusList: [],
		pipelineStages: [],
		pipelineFields: [],
		deliverableFields: [],
		clientFields: [],
		contactFields: [],
		projectFields: [],
		clientCache: [],
		favoriteClients: [],
		accountFonts: [],
		selectedUserAccount: null,
		usersForAccount: [],
		loadingState: false,
		podAccount: null,
		communicationPreferences: null,
		customEmail: null,
		documentTitle: document.title,
		isMobile: window.mobileCheck(),
		isMac: /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
		leftNavExpanded: true,
		rightNavExpanded: false,
		rightNavFocus: null,
		modalOpen: false,
		showOnBoardingWidget: false,
		globalModalController: null,
		currentTimer: null,
		defaultCurrency: 'USD',
		locale: 'en-US',
		navDetailKeys: [],
		theme: 'moxie',
		agreementBuilderState: {
			activePage: null,
			activeContainerId: null,
			activeElementId: null,
		},

		darkMode: 'automatic',
		showScrollBars: false,
		weekStartsOn: 'Monday',
		homePage: '/getting-started',

		productivity: {
			updateKey: null,
			notifications: [],
			todo: null,
			notes: [],
			deliverables: [],
		},

		calls: [],
		threads: [],
		contacts: [],
		emailBoxes: [],
		communicatorSubscription: null,
		aiEntitlement: null,

		communicator: {
			docked: true,
			selectedTab: 'Phone',
			callIsActive: false,
			activeCall: null,
			to: null,
			keyPadEntry: '',
			phoneIsValid: false,
			toList: null,
			activeThreadId: null,
			newMessage: false,
			refreshKey: 0,
			messageUpdate: 0,
			showExpanded: false,
			callTime: null,
			timerId: null,
			callMuted: false,
			callRecording: false,
		},

		notificationWindowOpenState: false,

		tinyMceConfig: {
			menubar: false,
			inline: true,
			plugins: ['lists', 'link'],
			toolbar: [
				'undo redo | bold italic underline | fontselect fontsizeselect',
				'forecolor backcolor | alignleft aligncenter alignright alignfull | numlist bullist outdent indent | link unlink',
			],
		},

		filterState: {
			clients: '',
		},
	},

	mutations: {
		logIn(state, authorizedUser) {
			state.accessToken = authorizedUser.authToken;
			state.pubNubToken = authorizedUser.pubNubToken;

			if(state.pubNub){
				state.pubNub.setToken(state.pubNubToken);
				state.pubNub.subscriptionSet({
					channels: state.pubNubChannels,
				}).subscribe();
			}
		},

		logOut(state) {
			state.accessToken = null;
			state.loggedIn = false;
			state.loggedInUser = null;
			state.initialized = false;
			state.origRequestedUri = '/getting-started';
			sessionStorage.clear();
			window.Intercom('shutdown');

			try {
				let root = document.documentElement;
				root.style.setProperty('--v-recaptcha-visibility', 'visible');
			} catch (err) {
				console.log(err);
			}
		},

		setDefaultView(state, view) {
			state.defaultView = view;
		},

		setNavDetailKey(state, item) {
			let ix = state.navDetailKeys.findIndex((k) => k.key === item.key);
			if (ix > -1) {
				state.navDetailKeys.splice(ix, 1);
			}
			state.navDetailKeys.push(item);
		},

		clearNavDetailKey(state, { key }) {
			let ix = state.navDetailKeys.findIndex((k) => k.key === key);
			if (ix > -1) {
				state.navDetailKeys.splice(ix, 1);
			}
		},

		setNavExpanded(state, expanded) {
			state.leftNavExpanded = expanded;
		},

		startLoading(state) {
			state.loadingState = true;
		},

		stopLoading(state) {
			state.loadingState = false;
		},

		notificationWindow(state, openState) {
			state.notificationWindowOpenState = openState;
		},

		initializeLoggedInUser(state, user) {
			state.loggedInUser = user;
			state.selectedUserAccount = null;
			let ownedAccount = null;
			let subscriptionType = 'GUEST';
			let ownedAccountList = user.userAccounts.filter((a) => a.userType === 'OWNER');

			try {
				ownedAccount = user.userAccounts.find((a) => a.userType === 'OWNER');
				let selectedAccountId = parseInt(localStorage.getItem('selectedAccountId'), 10);
				state.selectedUserAccount = user.userAccounts.find((a) => a.account.accountId === selectedAccountId);
			} catch (e) {
				console.log(e);
			}

			if (!state.selectedUserAccount) {
				if (ownedAccount) {
					state.selectedUserAccount = ownedAccount;
				} else {
					state.selectedUserAccount = user.userAccounts[0];
				}
			}

			if (user.isSupport) {
				console.log('Not booting intercom as support user');
			} else {
				if (ownedAccountList.length) {
					let subList = [];
					for (let i = 0; i < ownedAccountList.length; i++) {
						let account = ownedAccountList[i].account;
						let subscriptionType = account.subscriptionType;

						if (account.subscriptionProvider === 'APP_SUMO') {
							subscriptionType = subscriptionType + '-AppSumo';
						}

						if (account.trialEndsAt && account.subscriptionType !== 'FREE') {
							if (DateTime.fromISO(account.trialEndsAt) > DateTime.now()) {
								subscriptionType = 'TRIAL';
							}
						}

						subList.push(subscriptionType + 'v' + account.pricingVersion);
					}
					subscriptionType = subList.join(', ');
				}

				userflow.init(process.env.VUE_APP_USERFLOW_TOKEN);
				userflow.setPageTrackingDisabled(true);

				userflow
					.identify(
						user.userId,
						{
							name: user.firstName + ' ' + user.lastName,
							email: user.email,
						},
						{
							signature: user.props.USERFLOW_HASH,
						}
					)
					.catch((err) => console.log(err));

				window.Intercom('boot', {
					app_id: process.env.VUE_APP_INTERCOM_APP_ID,
					email: user.email,
					name: user.firstName + ' ' + user.lastName,
					user_id: user.userId,
					user_hash: user.props.INTERCOM_HASH,
					custom_launcher_selector: '#intercom_launcher',
					hide_default_launcher: true,
					subscription: subscriptionType,

					avatar: {
						type: 'avatar',
						image_url: user.profilePicture,
					},
				});
			}

			if (state.pubNub == null) {
				state.pubNub = new PubNub({
					subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE,
					publishKey: process.env.VUE_APP_PUBNUB_PUBLISH,
					uuid: user.uuid,
					logVerbosity: false,
					auto_reconnect: true,
					restore: true,
				});
				state.pubNub.setToken(state.pubNubToken);
				state.pubNub.setHeartbeatInterval(60);
				state.pubNubChannels = [];
				state.pubNubChannels.push('u-' + user.userId + '.*');

				// add a status listener
				state.pubNub.addListener({
					status: (s) => {console.log('Status', s) }
				});

				for (let i = 0; i < user.userAccounts.length; i++) {
					if (user.userAccounts[i].userType !== 'COLLABORATOR') {
						state.pubNubChannels.push('a-' + user.userAccounts[i].account.accountId + '.*');
					} else if (user.userAccounts[i].userType === 'COLLABORATOR') {
						state.pubNubChannels.push('a-' + user.userAccounts[i].account.accountId + '.deliverableStatusList');
						let projects = user.userAccounts[i].projectAccess.projects;
						for (let j = 0; j < projects.length; j++) {
							state.pubNubChannels.push('a-' + user.userAccounts[i].account.accountId + '.pr-' + projects[j].projectId);
							state.pubNubChannels.push('a-' + user.userAccounts[i].account.accountId + '.pr-' + projects[j].projectId + '.*');
						}
					}
				}

				let subscription = state.pubNub.subscriptionSet({
					channels: state.pubNubChannels,
				});

				state.pubNub.addListener({
					//This function will broadcast on the specific channel as well as a more broad channel
					//for the event.  a-1016.p-123445 will also broadcast on a-1016.p to allow for broader
					//event handling based solely on the category of message and not the ID
					message: (msg) =>  {
						if (msg.channel.includes('mobile-pndebug')) {
							//console.log(msg.channel, msg);
							return;
						}

						state.eventBus.$emit(msg.channel, msg); //broadcast on original channel

						let parts = msg.channel.split('.');
						let channel = '';
						for (let i = 0; i < parts.length; i++) {
							if (channel === '') {
								channel = parts[i];
							} else {
								channel = channel + '.' + parts[i];
							}

							if (channel !== msg.channel) {
								state.eventBus.$emit(channel, msg);
							}

							let truncated = channel.split('-');
							if (truncated.length > 1) {
								truncated.pop();
								truncated = truncated.join('-');
								state.eventBus.$emit(truncated, msg);
							}
						}
					},
				});

				subscription.subscribe();
			}



			state.loggedIn = true;
			try {
				let root = document.documentElement;
				root.style.setProperty('--v-recaptcha-visibility', 'hidden');
			} catch (err) {
				console.log(err);
			}
		},

		selectAccount(state, userAccount) {
			state.selectedUserAccount = userAccount;
			localStorage.setItem('selectedAccountId', userAccount.account.accountId);
		},

		setOrigRequestedUri(state, origRequestedUri) {
			state.origRequestedUri = origRequestedUri;
		},

		setInitialized(state, initialized) {
			state.initialized = initialized;
		},

		setDeliverableStatusList(state, statusList) {
			state.deliverableStatusList = statusList;
		},

		setDeliverableFields(state, deliverableFields) {
			state.deliverableFields = deliverableFields;
		},

		setPipelineFields(state, pipelineFields) {
			state.pipelineFields = pipelineFields;
		},

		setClientFields(state, clientFields) {
			state.clientFields = clientFields;
		},

		setContactFields(state, contactFields) {
			state.contactFields = contactFields;
		},

		setProjectFields(state, projectFields) {
			state.projectFields = projectFields;
		},

		setCommunicatorSubscription(state, subscription) {
			state.communicatorSubscription = subscription;
		},

		setClientCache(state, clients) {
			state.clientCache.splice(0, state.clientCache.length);
			state.clientCache.push(...clients);
		},

		setUsersForAccount(state, users) {
			state.usersForAccount.splice(0, state.usersForAccount.length);
			state.usersForAccount.push(...users);
		},

		success(state, message) {
			state.alert = {
				type: 'success',
				message: message,
			};
		},

		info(state, message) {
			state.alert = {
				type: 'info',
				message: message,
			};
		},

		warn(state, message) {
			state.alert = {
				type: 'warning',
				message: message,
			};
		},

		error(state, message) {
			state.alert = {
				type: 'error',
				message: message,
			};
		},

		clear(state) {
			state.alert = {
				type: null,
				message: null,
			};
		},

		setBreadcrumbs(state, crumbs) {
			console.log(crumbs);
		},

		setFilterState(state, payload) {
			state.filterState[payload.key] = payload.value;
		},

		setCurrentTimer(state, currentTimer) {
			state.currentTimer = currentTimer;
		},
	},

	getters: {
		scroll: (state) => {
			return state.showScrollBars ? 'show-scrollbar' : '';
		},

		clients: (state) => {
			return state.clientCache.filter((c) => c.archive === false);
		},

		contacts: (state) => {
			return state.contacts.filter((c) => {
				return !c.emulated;
			});
		},

		communicatorContacts: (state) => {
			return state.contacts;
		},

		archivedClients: (state) => {
			return state.clientCache.filter((c) => c.archive === true);
		},

		isPaidAccount: (state, getters) => {
			return getters.getAccount.subscriptionType === 'PAID' || getters.getAccount.subscriptionType === 'TEAM';
		},

		isPhoneAvailable: (state, getters) => {
			return getters.getAccount.subscriptionProvider !== 'APP_SUMO';
		},

		isAiAvailable: (state, getters) => {
			let maxAllowed = state.aiEntitlement.activeLicenses + state.aiEntitlement.includedLicenses;
			let assigned = state.aiEntitlement.assignedLicenses.length;
			let inCompliance = assigned <= maxAllowed;

			if (state.aiEntitlement) {
				return inCompliance && state.aiEntitlement.assignedLicenses.includes(getters.getLoggedInUserId);
			} else {
				return false;
			}
		},

		isAiEnabledOnWorkspace: (state) => {
			if (state.aiEntitlement) {
				let maxAllowed = state.aiEntitlement.activeLicenses + state.aiEntitlement.includedLicenses;
				return maxAllowed > 0;
			} else {
				return false;
			}
		},

		isDisabledAccount: (state, getters) => {
			return getters.getAccount.subscriptionType === 'NONE';
		},

		isInTrial: (state, getters) => {
			if (getters.getAccount && getters.getAccount.trialEndsAt) {
				if (DateTime.fromISO(getters.getAccount.trialEndsAt) > DateTime.now()) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},

		isSupportMode: (state) => {
			if (state.loggedInUser && state.loggedInUser.isSupport) {
				return true;
			} else {
				return false;
			}
		},

		isSampleMode: (state, getters) => {
			if (getters.getAccount) {
				return getters.getAccount.sampleMode;
			} else {
				return false;
			}
		},

		isTeamAccount: (state, getters) => {
			return getters.getAccount.subscriptionType === 'TEAM';
		},

		getLoggedInUserId(state) {
			if (state.loggedInUser) {
				return state.loggedInUser.userId;
			}
		},

		getUserAccounts(state) {
			if (state.loggedInUser) {
				return state.loggedInUser.userAccounts;
			}
		},

		getFilterState: (state) => (key) => {
			return state.filterState[key];
		},

		getLoggedInUserName(state) {
			if (state.loggedInUser) {
				return state.loggedInUser.firstName + ' ' + state.loggedInUser.lastName;
			} else {
				return null;
			}
		},

		getUserSignature: (state, getters) => {
			if (state.communicationPreferences.customEmail && state.communicationPreferences.customEmail.signature) {
				return state.communicationPreferences.customEmail.signature;
			} else {
				return getters.getLoggedInUserName;
			}
		},

		getBaseUrl: (state, getters) => {
			if (state.loggedInUser && state.loggedInUser.userAccounts) {
				return state.selectedUserAccount.account.pod.podUrl + 'api/v1/' + getters.getAccountId;
			} else {
				return null;
			}
		},

		getBaseUrlV2: (state, getters) => {
			if (state.loggedInUser && state.loggedInUser.userAccounts) {
				return state.selectedUserAccount.account.pod.podUrl + 'api/v2/' + getters.getAccountId;
			} else {
				return null;
			}
		},

		getPodUrl: (state) => {
			if (state.loggedInUser && state.loggedInUser.userAccounts) {
				return state.selectedUserAccount.account.pod.podUrl;
			} else {
				return null;
			}
		},

		getPodUrlForAccount: (state) => (accountId) => {
			let userAccount = state.loggedInUser.userAccounts.find((a) => a.account.accountId === accountId);
			if (userAccount) {
				return userAccount.account.pod.podUrl + 'api/v1/' + accountId;
			} else {
				return null;
			}
		},

		getBaseCommUrl: (state, getters) => {
			if (state.loggedInUser && state.loggedInUser.userAccounts) {
				return state.selectedUserAccount.account.pod.comUrl + 'api/v1/' + getters.getAccountId;
			} else {
				return null;
			}
		},

		getSubscriptionBaseUrl: (state, getters) => (prepend_account = true) => {
			return process.env.VUE_APP_BASE_AUTH_URL + (prepend_account ? '/' + getters.getAccountId : '');
		},

		getAccountId: (state) => {
			if (state.loggedInUser && state.selectedUserAccount) {
				return state.selectedUserAccount.account.accountId;
			} else {
				return null;
			}
		},

		getAccountName: (state) => {
			if (state.loggedInUser && state.selectedUserAccount) {
				return state.selectedUserAccount.account.accountName;
			} else {
				return null;
			}
		},

		getAccountLogo: (state) => {
			if (state.loggedInUser && state.selectedUserAccount) {
				return state.selectedUserAccount.account.accountLogo;
			} else {
				return null;
			}
		},

		getUserType: (state) => {
			if (state.loggedInUser && state.selectedUserAccount) {
				return state.selectedUserAccount.userType;
			} else {
				return null;
			}
		},

		isAccountOwner: (state) => {
			return state.selectedUserAccount.userType === 'OWNER' || state.selectedUserAccount.userType === 'IMPLEMENTER';
		},

		isAccountFullUser: (state) => {
			return (
				state.selectedUserAccount.userType === 'FULL_USER' ||
				state.selectedUserAccount.userType === 'OWNER' ||
				state.selectedUserAccount.userType === 'IMPLEMENTER'
			);
		},

		isAccountCollaborator: (state) => {
			return state.selectedUserAccount.userType === 'COLLABORATOR';
		},

		isAccountRestrictedUser: (state) => {
			return state.selectedUserAccount.userType === 'RESTRICTED_USER';
		},

		hasInvoiceAccess: (state, getters) => {
			return getters.hasFeatureAccess('invoices') || getters.hasFeatureAccess('accounting');
		},

		hasFeatureAccess: (state, getters) => (featureName) => {
			if (getters.isAccountOwner || getters.isAccountFullUser) {
				return true;
			} else if (getters.isAccountCollaborator && (featureName === 'projects' || featureName === 'timesheets')) {
				return true;
			} else if (getters.isAccountRestrictedUser && featureName === 'clients') {
				return true;
			} else if (
				getters.isAccountRestrictedUser &&
				state.selectedUserAccount.featureAccess &&
				(featureName === 'any' || state.selectedUserAccount.featureAccess[featureName])
			) {
				return true;
			} else {
				return false;
			}
		},

		getAccount: (state) => {
			if (state.loggedInUser && state.selectedUserAccount) {
				return state.selectedUserAccount.account;
			} else {
				return null;
			}
		},

		getMessageChannelPrefix: (state, getters) => {
			return 'a-' + getters.getAccountId;
		},

		getAlert: (state) => {
			return state.alert;
		},

		getAlgoliaHash: (state, getters) => {
			if (state.loggedInUser) {
				return state.loggedInUser.props['ALGOLIA_' + getters.getAccountId];
			} else {
				return null;
			}
		},

		getTinyMceKey: () => {
			return '';
		},

		getClientPortalUri: () => {
			return process.env.VUE_APP_CLIENT_PORTAL;
		},

		getFromEmail: (state) => {
			if (state.customEmail && state.customEmail.active) {
				if (state.customEmail.fromEmail) {
					return state.customEmail.fromEmail;
				} else {
					return state.customEmail.username;
				}
			} else {
				return process.env.VUE_APP_FROM_EMAIL;
			}
		},

		getDeliverableStatusMap: (state) => {
			let statusMap = new Map();
			if (state.deliverableStatusList && state.deliverableStatusList.statusList) {
				for (let i = 0; i < state.deliverableStatusList.statusList.length; i++) {
					let s = state.deliverableStatusList.statusList[i];
					statusMap.set(s.id, s);
				}
			}
			return statusMap;
		},

		getDeliverableStatusById: (state, getters) => (id) => {
			let status = getters.getDeliverableStatusMap.get(id);
			if (status) {
				return status;
			} else {
				return {
					id: 'unknown',
					label: 'Unknown',
					hexColor: '#bfbfbf',
				};
			}
		},

		getDeliverableStatusIndex: (state) => (id) => {
			return state.deliverableStatusList.statusList.findIndex((s) => s.id === id);
		},

		getIncompleteStatusList: (state) => {
			let result = [];
			for (let i = 0; i < state.deliverableStatusList.statusList.length; i++) {
				let status = state.deliverableStatusList.statusList[i];

				if (status.complete) {
					break;
				} else {
					result.push(status);
				}
			}
			return result;
		},

		getUserById: (state) => (userId) => {
			for (let i = 0; i < state.usersForAccount.length; i++) {
				if (state.usersForAccount[i].userId === userId) {
					return state.usersForAccount[i];
				}
			}
			return null;
		},

		unreadNotificationCount: (state) => {
			let unreadCount = 0;

			for (let i = 0; i < state.productivity.notifications.length; i++) {
				let notification = state.productivity.notifications[i];
				if (notification.read === false) {
					unreadCount++;
				}
			}

			return unreadCount;
		},

		unreadCommunicator: (state) => {
			let calls = state.calls.filter((c) => c.unread).length;
			let threads = state.threads
				.map((t) => t.unread)
				.reduce((currentValue, previousValue) => currentValue + previousValue, 0);

			return calls + threads;
		},

		getNotifications: (state) => {
			return state.productivity.notifications;
		},

		firstDayOfWeek: (state) => {
			if (state.weekStartsOn === 'Sunday') {
				return 0;
			} else {
				return 1;
			}
		},

		getClientById: (state) => (id) => {
			return state.clientCache.find((c) => c.id === id);
		},

		getContactById: (state) => (id) => {
			return state.contacts.find((c) => c.id === id);
		},

		getContactByClientPortalId: (state) => (id) => {
			return state.contacts.find((c) => c.clientPortalUserId !== null && c.clientPortalUserId === id);
		},

		getContactByPhone: (state) => (phone) => {
			if (phone) {
				let contact = state.contacts.find((c) => c.phone === phone);
				if (contact) {
					return contact;
				}

				for (let i = 0; i < state.contacts.length; i++) {
					let contact = state.contacts[i];
					if (contact.customValues.find((v) => v.type === 'Phone' && v.value === phone)) {
						return contact;
					}
				}
			}

			return null;
		},

		getContactByEmail: (state) => (email) => {
			if (email) {
				let contact = state.contacts.find((c) => c.email && c.email.toLowerCase() === email.toLowerCase());

				if (contact) {
					return contact;
				}

				for (let i = 0; i < state.contacts.length; i++) {
					let contact = state.contacts[i];
					if (contact.customValues.find((v) => v.type === 'Email' && v.value === email)) {
						return contact;
					}
				}
			} else {
				return null;
			}
		},

		getContactsByClientId: (state,getters) => (clientId) => {
			return getters.contacts.filter((c) => c.clientId === clientId);
		},

		getContactByPortalUserId: (state) => (portalUserId) => {
			if (portalUserId) {
				return state.contacts.find((c) => c.clientPortalUserId === portalUserId);
			} else {
				return null;
			}
		},

		getCustomBranding: (state) => {
			if (state.communicationPreferences) {
				return state.communicationPreferences.customBranding;
			} else {
				return {};
			}
		},
	},

	actions: {
		setLoggedInUser({ dispatch, commit, state }, user) {
			commit('initializeLoggedInUser', user);
			dispatch('setSelectedAccount', state.selectedUserAccount);
			state.eventBus.$emit('logged-in', user);
		},

		setSelectedAccount({ dispatch, commit, state }, userAccount) {
			let accountChanged = state.selectedUserAccount.account.accountId !== userAccount.account.accountId;

			commit('selectAccount', userAccount);
			dispatch('setDeliverableStatusList');
			dispatch('setCustomFields');
			dispatch('setCommunicatorSubscription');
			dispatch('setClientCache');
			dispatch('getUsersForAccount', userAccount.account);

			let accountService = new AccountService();
			let customEmailService = new CustomEmailService();

			customEmailService.getCustomEmail().then((res) => {
				state.customEmail = res.data;
			});

			accountService
				.getCommunicationPreferences()
				.then((res) => {
					state.communicationPreferences = res.data;
				})
				.catch((err) => console.log(err));

			accountService
				.getCurrency()
				.then((res) => {
					state.defaultCurrency = res.data;
				})
				.catch((err) => console.log(err));

			accountService
				.getAccount()
				.then((res) => {
					state.podAccount = res.data;
				})
				.catch((err) => console.log(err));

			if (accountChanged) {
				if (userAccount.userType === 'COLLABORATOR') {
					if (router.currentRoute.path === '/projects') {
						state.eventBus.$emit('account-changed', userAccount);
					} else {
						router.push('/projects');
					}
				} else {
					state.eventBus.$emit('account-changed', userAccount);
				}
			}
		},

		getUsersForAccount: function({ commit }, account) {
			AuthService.getUsersForAccount(account)
				.then((res) => {
					commit('setUsersForAccount', res);
				})
				.catch((err) => {
					commit('error', err.response.data.message);
				});
		},

		setDeliverableStatusList: function({ commit }) {
			new DeliverableStatusService()
				.getDeliverableStatusForAccount()
				.then((res) => {
					commit('setDeliverableStatusList', res.data);
				})
				.catch(() => {
					//do nothing
				});
		},

		setCustomFields: function({ commit }) {
			new CustomFieldsService('/deliverableFields')
				.getCustomFields()
				.then((res) => {
					commit('setDeliverableFields', res.data);
				})
				.catch(() => {
					//do nothing
				});

			new CustomFieldsService('/clientFields')
				.getCustomFields()
				.then((res) => {
					commit('setClientFields', res.data);
				})
				.catch(() => {
					//do nothing
				});

			new CustomFieldsService('/contactFields')
				.getCustomFields()
				.then((res) => {
					commit('setContactFields', res.data);
				})
				.catch(() => {
					//do nothing
				});

			new CustomFieldsService('/projectFields')
				.getCustomFields()
				.then((res) => {
					commit('setProjectFields', res.data);
				})
				.catch(() => {
					//do nothing
				});

			new CustomFieldsService('/pipeline/fields', 2)
				.getCustomFields()
				.then((res) => {
					commit('setPipelineFields', res.data);
				})
				.catch(() => {
					//do nothing
				});
		},

		setCommunicatorSubscription: function({ commit }) {
			new CommunicatorService().getSubscription().then((res) => commit('setCommunicatorSubscription', res.data));
		},

		setClientCache: function({ commit }) {
			new ClientService()
				.getClientMiniList()
				.then((res) => {
					let clientList = [...res.data];
					clientList.sort((a, b) => a.name.localeCompare(b.name));
					commit('setClientCache', clientList);
				})
				.catch((err) => {
					console.log('Error fetching client cache.' + JSON.stringify(err));
				});
		},

		updateDeliverableStatusList: function({ commit, state }) {
			new DeliverableStatusService()
				.updateDeliverableStatusForAccount(state.deliverableStatusList)
				.then((res) => {
					commit('setDeliverableStatusList', res.data);
				})
				.catch((err) => {
					commit('error', err.response.data.message);
				});
		},

		updateAccountInfo: function(context, account) {
			let accountService = new AccountService();
			accountService.updateAccount(account).then(() => {
				AuthService.refreshToken(true).then((res) => {
					context.commit('logIn', res.authToken);

					AuthService.getProfile().then((res) => {
						context.dispatch('setLoggedInUser', res);
					});
				});
			});
		},
	},

	modules: {},
});
