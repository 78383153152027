<template>
	<div class="pb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Project management' }]">
			Project list
		</page-header>

		<div
			class="column-format fill-height"
			v-if="isInitialized"
			style=""
		>
			<div class="pb-4 row-format align-center gap-3 flex-wrap">
				<div class="row-format align-center gap-3">
					<v-text-field
						outlined
						hide-details
						dense
						v-model="filter.search"
						placeholder="Search..."
						style="max-width: 200px; background-color: var(--v-white-base)"
						color="gray_30"
					>
						<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
					</v-text-field>
					<div>
						<project-list-filter :filter="filter" @updated="saveCurrentFilter"></project-list-filter>
					</div>
					<div>
						<project-list-sort :filter="filter" @updated="saveCurrentFilter"></project-list-sort>
					</div>
				</div>
				<div class="ml-auto row-format align-center gap-3">
					<div class="pointer row-format align-center" v-tippy="{ content: 'List' }" @click="setView('LIST')">
						<span
							class="material-symbols-rounded"
							:style="`font-size: 24px; color: var(--v-${currentView === 'LIST' ? 'primary' : 'gray_60'}-base)`"
							>list</span
						>
					</div>
					<div class="pointer row-format align-center" v-tippy="{ content: 'Card' }" @click="setView('CARD')">
						<span
							class="material-symbols-rounded"
							:style="`font-size: 24px; color: var(--v-${currentView === 'CARD' ? 'primary' : 'gray_60'}-base)`"
							>grid_on</span
						>
					</div>
					<v-btn class="super-action" @click="createNewProject()" :disabled="!isAccountFullUser"
					><v-icon size="20">add</v-icon> Add project</v-btn
					>
				</div>
			</div>

			<div v-if="projects.length" >
				<div class="column-format" v-if="currentView === 'CARD'">
					<div class="row-format" style="flex-wrap: wrap; gap: 16px;">
						<project-card
							v-for="project in filteredProjects"
							:key="project.id"
							:project="project"
							@click="routeToProject(project, $event)"
							@edit="editProject(project)"
							@mark-complete="markAsComplete(project)"
						></project-card>
					</div>
				</div>

				<div v-else class="column-format" style="background-color: var(--v-white-base); border: 1px solid var(--v-gray_30-base); border-radius: 4px">
					<v-container fluid class="px-0 mt-n2" style="max-width: 100%; overflow: hidden">
						<project-row
							:show-collaborators="anyCollaborators"
							v-for="(project,index) in filteredProjects"
							:key="project.id"
							:project="project"
							:index="index"
							@click="routeToProject(project, $event)"
							@edit="editProject(project)"
							@mark-complete="markAsComplete(project)"
						></project-row>
					</v-container>
				</div>
			</div>
			<div class="row-format centered fill-height" v-else style="min-height: 600px;">
				<empty-view
					header="No projects yet"
					body="Let's create your first project! That’s the best way to track deadlines, add tasks, and get paid for the work you’ve done."
					cta="Create a project"
					video-header="See how it works"
					video-body="The projects view within client details is a little bit different from the project management page. We’ll show you how they’re different and how to use them."
					video-cta="Watch the tutorial"
					video-id="WzMkYNIIxSM"
					@cta-clicked="createNewProject()"
				></empty-view>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.project-count {
		width: 24px;
		height: 24px;
		border-radius: 24px;
		background-color: var(--v-gray_10-base);
		color: var(--v-primary-base);
		font-size: 12px;
		font-weight: 500;
	}
</style>

<script>
	import ProjectService from '@/modules/projects/ProjectService';
	import DateTime from '@/modules/utils/HDateTime';
	import EmptyView from '@/components/EmptyView';
	import ProjectCard from '@/modules/projects/ProjectCard';
	import ProjectListFilter from '@/modules/projects/ProjectListFilter';
	import ProjectListSort from '@/modules/projects/ProjectListSort';
	//import ProjectSelector from '@/modules/projects/ProjectSelector';
	import ProjectEdit from '@/modules/projects/detail/ProjectEdit';
	import ConfirmModal from '@/components/ConfirmModal';
	import ProjectRow from '@/modules/projects/ProjectRow';
	import NewProject from '@/modules/projects/NewProject';
	import PageHeader from '@/components/PageHeader';

	export default {
		name: 'ProjectList',

		props: [],

		components: { ProjectRow, ProjectListSort, ProjectListFilter, ProjectCard, EmptyView, PageHeader },

		data: function() {
			return {
				showMenu: false,
				currentView: 'LIST',
				projects: [],
				projectService: new ProjectService(),
				isInitialized: false,
				DateTime: DateTime,
				filter: null,
				filterDefault: {
					search: null,
					clientList: [],
					sort: 'Name',
				},
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.getProjects);
			this.getSelectedFilters();
			this.getProjects();
			this.getCurrentView();
			localStorage.setItem('PROJECT_VIEW', 'list');
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getProjects);
		},

		methods: {
			setView: function(view) {
				this.currentView = view;
				localStorage.setItem(this.currentViewKey, view);
			},

			getCurrentView: function() {
				let currentView = localStorage.getItem(this.currentViewKey);
				if (currentView) {
					this.currentView = currentView;
				}
			},

			updated: function() {
				this.$emit('updated', this.filter);
			},

			getProjects: function() {
				this.projectService.getAllActiveProjects(true, true).then((res) => {
					this.projects.splice(0, this.projects.length);
					this.projects.push(...res.data);
					this.projects.forEach((p) => {
						p.nextDueDate = this.getNextDueDate(p);
						p.fees = this.fees(p);
						p.timeline = this.timeline(p);
					});
					this.isInitialized = true;
				});
			},

			timeline: function(project) {
				if (project.startDate && project.dueDate) {
					let start = DateTime.fromISO(project.startDate).toLocaleString({
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					});
					let due = DateTime.fromISO(project.dueDate).toLocaleString({
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					});
					return `${start} to ${due}`;
				} else if (project.startDate) {
					let start = DateTime.fromISO(project.startDate).toLocaleString({
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					});
					return `Starting on ${start}`;
				} else if (project.dueDate) {
					let due = DateTime.fromISO(project.dueDate).toLocaleString({
						month: 'short',
						day: 'numeric',
						year: 'numeric',
					});
					return `Due on ${due}`;
				} else {
					return '--';
				}
			},

			fees: function(project) {
				if (project.feeSchedule) {
					if (project.feeSchedule.feeType === 'Hourly') {
						return this.$formatters.dollars(project.feeSchedule.amount, true, true, project.client.currency) + '/hr';
					} else if (project.feeSchedule.feeType === 'Retainer') {
						if (project.feeSchedule.retainerSchedule) {
							return (
								this.$formatters.dollars(project.feeSchedule.amount, true, true, project.client.currency) +
								'/' +
								project.feeSchedule.retainerSchedule.toLowerCase()
							);
						} else {
							return '--';
						}
					} else if (project.feeSchedule.feeType === 'Per Item') {
						return 'Fee per task';
					} else if (project.feeSchedule.feeType === 'Fixed Price') {
						return this.$formatters.dollars(project.feeSchedule.amount, true, true, project.client.currency);
					} else {
						return '--';
					}
				} else {
					return '--';
				}
			},

			getNextDueDate: function(project) {
				let d = [...project.deliverables];

				let incomplete = [];
				for (let i = 0; i < this.$store.state.deliverableStatusList.statusList.length; i++) {
					let s = this.$store.state.deliverableStatusList.statusList[i];
					if (s.complete) {
						break;
					} else {
						incomplete.push(s.id);
					}
				}

				d = d.filter((d) => d.dueDate && incomplete.includes(d.statusId));

				if (!d.length) {
					return null;
				}

				d.sort(function(a, b) {
					if (a.dueDate > b.dueDate) {
						return 1;
					} else if (b.dueDate > a.dueDate) {
						return -1;
					} else {
						return 0;
					}
				});

				return {
					name: d[0].name,
					dueDate: d[0].dueDate,
					date: DateTime.fromISO(d[0].dueDate).toLocaleString({ year: 'numeric', month: 'short', day: 'numeric' }),
				};
			},

			saveCurrentFilter(event) {
				this.filter = event;
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			getSelectedFilters() {
				try {
					this.filter = JSON.parse(localStorage.getItem(this.filterStateKey));
					if (!this.filter) {
						this.saveCurrentFilter(this.filterDefault);
					}
				} catch (err) {
					console.log(err);
				}
			},

			routeToProject: function(project, event) {
				if (event && (event.ctrlKey || event.metaKey)) {
					window.open(`/project/${project.id}`, '_blank');
				} else {
					this.$router.push(`/project/${project.id}`);
				}
			},

			markAsComplete: function(project) {
				let binding = {
					headingText: `Mark as complete?`,
					bodyText: `Are you sure you want to mark ${project.name} as complete?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						const patch = [{ op: 'replace', path: '/active', value: false }];
						this.$store.commit('startLoading');
						this.projectService
							.patchProject(project.id, patch)
							.then(() => {
								let ix = this.projects.findIndex((p) => p.id === project.id);
								this.projects.splice(ix, 1);
							})
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			editProject: function(project) {
				this.$store.state.globalModalController.openModal(ProjectEdit, { projectId: project.id }).then((res) => {
					if (res) {
						let ix = this.projects.findIndex((p) => p.id === project.id);
						if (ix > -1) {
							if (res.deleted) {
								this.projects.splice(ix, 1);
							}
							if (res.active) {
								res.client = project.client;
								res.deliverables = project.deliverables;
								this.projects.splice(ix, 1, res);
							} else {
								this.projects.splice(ix, 1);
							}
						}
					}
				});
			},

			createNewProject: function() {
				let binding = {
					skipDeliverable: true,
					projectFilter: [],
				};

				this.$store.state.globalModalController.openModal(NewProject, binding, true, false).then((res) => {
					if (res) {
						setTimeout(() => this.$router.push(`/project/${res.id}?edit=true`), 500);
					}
				});
			},

			runSort: function(functions, a, b) {
				for (let i = 0; i < functions.length; i++) {
					let result = functions[i].call(this, a, b);
					if (result !== 0) {
						return result;
					}
				}

				return 0;
			},

			sortByClient: function(a, b) {
				let result = a.client.name.localeCompare(b.client.name);
				if (result === 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			sortByName: function(a, b) {
				return a.name.localeCompare(b.name);
			},

			sortByNextDueAndName: function(a, b) {
				return this.runSort([this.sortByNextDue, this.sortByName], a, b);
			},

			sortByStartDateAndName: function(a, b) {
				return this.runSort([this.sortByStartDate, this.sortByDueDate, this.sortByName], a, b);
			},

			sortByStartDate(a, b) {
				if (a.startDate && b.startDate) {
					return a.startDate.localeCompare(b.startDate);
				} else if (a.startDate) {
					return -1;
				} else if (b.startDate) {
					return 1;
				} else {
					return 0;
				}
			},

			sortByDueDateAndName: function(a, b) {
				return this.runSort([this.sortByDueDate, this.sortByStartDate, this.sortByName], a, b);
			},

			sortByDueDate(a, b) {
				if (a.dueDate && b.dueDate) {
					return a.dueDate.localeCompare(b.dueDate);
				} else if (a.dueDate) {
					return -1;
				} else if (b.dueDate) {
					return 1;
				} else {
					return 0;
				}
			},

			sortByNextDue: function(a, b) {
				if (a.nextDueDate && b.nextDueDate) {
					return a.nextDueDate.dueDate.localeCompare(b.nextDueDate.dueDate);
				} else if (a.nextDueDate) {
					return -1;
				} else if (b.nextDueDate) {
					return 1;
				} else {
					return 0;
				}
			},
		},

		computed: {
			anyCollaborators: function() {
				return this.$store.state.usersForAccount.filter((u) => u.userType === 'COLLABORATOR').length > 0;
			},

			filterStateKey: function() {
				return 'PROJECT_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},

			currentViewKey: function() {
				return (
					'PROJECT_OVERVIEW_CURRENT_VIEW_' +
					this.$store.getters.getAccountId +
					'_' +
					this.$store.getters.getLoggedInUserId
				);
			},

			isAccountFullUser: function() {
				return this.$store.getters.isAccountFullUser;
			},

			filteredProjects: function() {
				let result = this.projects
					.filter((p) => {
						if (this.$validations.isEmpty(this.filter.search)) {
							return true;
						} else {
							let search = this.filter.search.toLowerCase();
							if (p.name.toLowerCase().includes(search)) return true;
							if (p.client.name.toLowerCase().includes(search)) return true;
							if (p.fees && p.fees.toLowerCase().includes(search)) return true;
							if (p.timeline && p.timeline.toLowerCase().includes(search)) return true;

							let customValues = p.customValues;

							for (let j = 0; j < customValues.length; j++) {
								let cv = customValues[j];
								if (
									cv.value &&
									cv.value
										.toString()
										.toLowerCase()
										.includes(search)
								) {
									return true;
								}
							}
						}
					})
					.filter((p) => {
						if (this.filter.clientList.length === 0) {
							return true;
						} else if (this.filter.clientList.includes(p.client.id)) {
							return true;
						} else {
							return false;
						}
					});

				if (this.filter.sort === 'Next task due') {
					result.sort(this.sortByNextDueAndName);
				} else if (this.filter.sort === 'Client') {
					result.sort(this.sortByClient);
				} else if (this.filter.sort === 'Project start') {
					result.sort(this.sortByStartDateAndName);
				} else if (this.filter.sort === 'Project due') {
					result.sort(this.sortByDueDateAndName);
				} else {
					result.sort(this.sortByName);
				}

				return result;
			},
		},

		watch: {},
	};
</script>
