<template>
	<div class="pb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Accounting' }]">
			Settings
		</page-header>


		<div class="row-format mb-4 gap-3 flex-wrap">
			<div
					:class="`page-link ${settingsTab === page.value ? 'page-link-active' : ''}`"
					v-for="page in pages"
					:key="page.value"
					@click.stop="settingsTab = page.value"
			>
				<div>{{ page.label }}</div>
			</div>
		</div>

		<div class="row-format align-center mb-4">
			<v-text-field
					outlined
					hide-details
					dense
					v-model="search"
					placeholder="Search..."
					:style="`max-width: 200px; background-color: var(--v-white-base)`"
					color="gray_30"
			>
				<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
			</v-text-field>

			<v-btn class="ml-auto super-action" @click="addNew()"
			><v-icon size="20">add</v-icon>{{addNewLabel}}</v-btn
			>
		</div>

		<products-table v-if="settingsTab===0" ref="ProductTable" :filter="search" :edit-mode="true"></products-table>
		<invoice-template-list v-if="settingsTab===1" ref="InvoiceTemplateTable" :search="search"></invoice-template-list>

	</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ProductsTable from "@/modules/invoices/InvoiceRenderer/ProductsTable";
import InvoiceTemplateList from "@/modules/templates/invoices/InvoiceTemplateList";

export default {
	name: "AccountingSettings",

	props: [],

	components: {PageHeader, ProductsTable, InvoiceTemplateList},

	data: function () {
		return {
			settingsTab: 0,
			search: null,
			pages: [
				{ label: 'Products & services', value: 0 },
				{ label: 'Invoice templates', value: 1 }
			],
		}
	},

	mounted() {
		this.setPage();
	},

	beforeDestroy() {
	},

	methods: {
		setPage: function(){
			if(this.$route.query.p === 'products'){
				this.settingsTab = 0;
			}else if(this.$route.query.p === 'templates'){
				this.settingsTab = 1;
			}
		},

		addNew(){
			if(this.settingsTab === 0){
				this.$refs.ProductTable.createNewProduct();
			}else{
				this.$refs.InvoiceTemplateTable.addNew();
			}
		}
	},

	watch: {
		'$route.query': function(){
			this.setPage();
		}
	},

	computed: {
		addNewLabel: function(){
			if(this.settingsTab === 0){
				return 'Add item';
			}else{
				return 'Add template';
			}
		}
	},

}
</script>

<style scoped lang="scss">

</style>