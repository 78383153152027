<template>
	<div class="project-card column-format text-left" @click="$emit('click',$event)">
		<div class="row-format">
			<client-avatar large :client="project.client"></client-avatar>
			<div class="column-format text-left ml-2" style="line-height: 24px; margin-top: -4px">
				<div class="font-14 font-gray_70">{{project.client.name}}</div>
				<div class="font-18 font-primary">{{project.name}}</div>
			</div>
			<v-menu :close-on-content-click="true" :close-on-click="true" bottom nudge-bottom="30" v-if="isFullUser">
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on" class="ml-auto"><v-icon small>$moreHorizontal</v-icon></v-btn>
				</template>
				<div class="more-menu">
					<div class="more-menu-item" @click.stop="$emit('click')">View details</div>
					<div class="more-menu-item" @click.stop="$emit('edit')">Edit</div>
					<div class="more-menu-item" @click.stop="$emit('mark-complete')">Mark complete</div>
				</div>
			</v-menu>
		</div>

		<div class="mt-4" v-if="isFullUser">
			<div class="font-14 font-gray_70">{{project.fees}}</div>
		</div>

		<div class="column-format mt-auto">
			<div class="row-format" style="align-items: flex-end; justify-content: space-between">
				<div>
					<div v-if="project.nextDueDate" class="text-left font-14" style="width: fit-content"  v-tippy="{content: project.nextDueDate.name}">
						<div >{{project.nextDueDate.date}}</div>
						<div class="font-gray_70 mt-1">Next due</div>
					</div>
					<div v-else class="text-left font-14" style="width: fit-content">
						<div>--</div>
						<div class="font-gray_70">Next due</div>
					</div>
				</div>
				<div class="column-format" v-if="collaborators.length">
					<div class="row-format align-center pl-2">
						<assigned-user class="ml-n2" :style="`border-radius: 100px; border: 2px solid var(--v-white-base); z-index: ${5-index}`" v-for="(collaborator,index) in collaborators" :key="collaborator.userId" :assigned-to="collaborator.userId" :show-name="false"></assigned-user>
					</div>
					<div class="font-14 font-gray_70">Collaborators</div>
				</div>
			</div>
			<div style="max-height: 8px; height: 8px;" class="mt-6">
				<deliverable-by-status :project="project" :deliverables="project.deliverables" v-if="project.deliverables.length"></deliverable-by-status>
				<div class="mt-1" v-else style="height: 100%; width:100%; border-radius: 2px; background-color: var(--v-gray_20-base)" content="No tasks" v-tippy="{placement : 'top',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }"></div>
			</div>
		</div>
	</div>
</template>

<script>
import ClientAvatar from "@/components/ClientAvatar";
import DeliverableByStatus from "@/modules/projects/detail/DeliverableByStatus";
import AssignedUser from "@/components/AssignedUser";

export default {
	name: "ProjectCard",

	props: ['project'],

	components: {AssignedUser, DeliverableByStatus, ClientAvatar},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		toggleFavorite: function(){
			console.log('favorite!');
		},
	},

	computed: {
		collaborators: function(){
			return this.$store.state.usersForAccount.filter(u => u.userType === 'COLLABORATOR' && u.projectAccess.projects.findIndex(p => p.projectId === this.project.id) > -1);
		},

		isFullUser(){
			return this.$store.getters.isAccountFullUser;
		},
	},

}
</script>

<style scoped lang="scss">
	.project-card {
		width: 330px;
		height: 215px;
		box-sizing: border-box;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		cursor: pointer;
		padding: 20px;

		&:hover {
			background-color: var(--v-gray_5-base);
		}
	}
</style>