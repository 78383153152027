var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "row-format mr-2" },
      [
        _c(
          "v-btn",
          {
            staticClass: "secondary-action ml-auto",
            on: {
              click: function($event) {
                return _vm.createNewNote()
              }
            }
          },
          [_vm._v("+ Add note")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-5 px-1" },
      _vm._l(_vm.comments, function(comment) {
        return _c(
          "div",
          { key: comment.id, staticClass: "edit-comment my-4" },
          [
            _c(
              "div",
              { staticClass: "row-format align-center font-12 pb-2" },
              [
                comment.authorId !== "0"
                  ? _c("assigned-user", {
                      staticClass: "mr-3",
                      attrs: {
                        "show-name": false,
                        "assigned-to": parseInt(comment.authorId, 10)
                      }
                    })
                  : _vm._e(),
                _c("div", [_vm._v(" " + _vm._s(comment.author) + " ")]),
                _c(
                  "v-icon",
                  { staticClass: "ml-3 mr-1", attrs: { size: "14" } },
                  [_vm._v("$clock")]
                ),
                _c("div", { staticStyle: { color: "var(--v-gray_70-base)" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("formatForTimeAgo")(comment.timestamp)) +
                      " "
                  )
                ]),
                comment.edited
                  ? _c(
                      "div",
                      { staticClass: "font-italic font-12 font-gray_70 ml-4" },
                      [_vm._v("Edited")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    comment.id !== _vm.activeComment
                      ? _c(
                          "v-icon",
                          {
                            staticClass:
                              "pointer material-symbols-rounded mx-1",
                            attrs: { size: "16" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.setActive(comment)
                              }
                            }
                          },
                          [_vm._v("edit")]
                        )
                      : _vm._e(),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer material-symbols-rounded",
                        attrs: { size: "16" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.confirmDelete(comment)
                          }
                        }
                      },
                      [_vm._v("$delete")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            comment.id !== _vm.activeComment
              ? _c("div", {
                  staticClass: "pb-2",
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  },
                  domProps: {
                    innerHTML: _vm._s(
                      comment.comment === ""
                        ? "Enter a note..."
                        : comment.comment
                    )
                  }
                })
              : _c("comment", {
                  attrs: { "author-id": parseInt(comment.authorId, 10) },
                  on: {
                    input: function($event) {
                      _vm.activeComment = null
                    }
                  },
                  model: {
                    value: comment.comment,
                    callback: function($$v) {
                      _vm.$set(comment, "comment", $$v)
                    },
                    expression: "comment.comment"
                  }
                })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }