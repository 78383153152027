var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-8" }, [
    _vm.pushEnabled
      ? _c(
          "div",
          [
            !_vm.existingSubscription
              ? _c(
                  "v-btn",
                  {
                    attrs: { block: "", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.handleSubscribeToPushNotifications()
                      }
                    }
                  },
                  [_vm._v("Enable browser push notifications")]
                )
              : _c(
                  "v-btn",
                  {
                    attrs: { block: "", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.unsubscribe()
                      }
                    }
                  },
                  [_vm._v("Disable browser push notifications")]
                )
          ],
          1
        )
      : _c("div", [_vm._v("Your browser doesn't support push notifications.")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }