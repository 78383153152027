var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.subscription
      ? _c("div", [
          _c(
            "div",
            { staticClass: "row-format pt-4 px-3 mb-3 gap-3" },
            [
              _c(
                "div",
                {
                  class:
                    "page-link " +
                    (_vm.selectedTab === "Phone" ? "page-link-active" : ""),
                  on: {
                    click: function($event) {
                      return _vm.selectTab("Phone")
                    }
                  }
                },
                [
                  _c("div", [_vm._v("Phone")]),
                  _vm.unreadCalls
                    ? _c("div", { staticClass: "umi" }, [
                        _c("div", [_vm._v(_vm._s(_vm.unreadCalls))])
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  class:
                    "page-link " +
                    (_vm.selectedTab === "Texts" ? "page-link-active" : ""),
                  on: {
                    click: function($event) {
                      return _vm.selectTab("Texts")
                    }
                  }
                },
                [
                  _c("div", [_vm._v("Messages")]),
                  _vm.unreadThreads && _vm.selectedTab !== "Texts"
                    ? _c("div", { staticClass: "umi" }, [
                        _c("div", [_vm._v(_vm._s(_vm.unreadThreads))])
                      ])
                    : _vm._e()
                ]
              ),
              _vm.selectedTab === "Texts"
                ? _c(
                    "v-icon",
                    {
                      staticClass: "ml-auto",
                      attrs: { size: "26", color: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.Messages.startNewMessage()
                        }
                      }
                    },
                    [_vm._v("add_box")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "column-format",
              staticStyle: {
                "flex-grow": "1",
                "background-color": "var(--v-white-base)"
              }
            },
            [
              _c("phone", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedTab === "Phone",
                    expression: "selectedTab === 'Phone'"
                  }
                ],
                attrs: {
                  "is-visible": _vm.isVisible && _vm.selectedTab === "Phone",
                  subscription: _vm.subscription,
                  primary: _vm.primary
                }
              }),
              _c("recent-calls", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedTab === "Phone",
                    expression: "selectedTab === 'Phone'"
                  }
                ],
                attrs: {
                  "is-visible": _vm.isVisible && _vm.selectedTab === "Phone",
                  subscription: _vm.subscription,
                  primary: _vm.primary
                },
                on: {
                  call: function($event) {
                    return _vm.callNumber($event)
                  }
                }
              }),
              _c("messages", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectedTab === "Texts",
                    expression: "selectedTab === 'Texts'"
                  }
                ],
                ref: "Messages",
                attrs: {
                  "is-visible": _vm.isVisible && _vm.selectedTab === "Texts",
                  subscription: _vm.subscription,
                  primary: _vm.primary
                }
              })
            ],
            1
          )
        ])
      : _c("div", [
          _c("div", { staticClass: "pa-5 text-left" }, [
            _c(
              "div",
              { staticClass: "font-20 brand-medium font-primary mb-5" },
              [_vm._v("Virtual business phone")]
            ),
            _c("img", {
              attrs: {
                src: "/images/communicator/communicator.png",
                width: "250"
              }
            }),
            _c("div", { staticClass: "font-14 my-5" }, [
              _vm._v(
                "Your personal phone doesn’t need to double as your work phone. With Moxie Communicator, you’ll get a virtual business phone number so you can make calls, record calls, have text message conversations, and refer back to what was said—all in your browser or mobile app."
              )
            ]),
            _c(
              "div",
              {
                staticClass: "pointer brand-bold font-14 font-primary",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/communicator/phone")
                  }
                }
              },
              [_vm._v("Claim your number now")]
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }