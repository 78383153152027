import { render, staticRenderFns } from "./CheckboxInput.vue?vue&type=template&id=54c7e1bf&scoped=true&"
import script from "./CheckboxInput.vue?vue&type=script&lang=js&"
export * from "./CheckboxInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c7e1bf",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VForm,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1967088155/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54c7e1bf')) {
      api.createRecord('54c7e1bf', component.options)
    } else {
      api.reload('54c7e1bf', component.options)
    }
    module.hot.accept("./CheckboxInput.vue?vue&type=template&id=54c7e1bf&scoped=true&", function () {
      api.rerender('54c7e1bf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/discovery/formrenderer/CheckboxInput.vue"
export default component.exports