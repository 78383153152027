<template>
	<div>
		<v-combobox
			ref="ContactInput"
			class="mt-1 phone-input"
			@change="validateToList"
			v-model="toList"
			:items="contacts"
			hide-details
			dense
			outlined
			label="To:"
			placeholder="Enter phone or search"
			:multiple="multiple"
			small-chips
			:filter="isMatch"
		>
			<template v-slot:selection="{ item }">
				<v-chip :outlined="!item.valid" small :color="!item.valid ? 'warning' : ''"
					><span class="font-12" :style="!item.valid ? 'text-decoration: line-through' : ''">{{item.fullName}} ({{item.phoneLabel}})</span></v-chip
				>
			</template>

			<template v-slot:item="{ item }">
				<div class="row-format align-center gap-1">
					<client-avatar x-small :client="item.client" v-if="item.client"></client-avatar>
					<div class="font-14">{{item.fullName}} ({{item.phoneLabel}})</div>
				</div>
			</template>
		</v-combobox>
	</div>
</template>

<script>
	import libPhoneNumber from 'google-libphonenumber';
	import {v4 as uuidv4} from 'uuid';
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'ContactSelector',

		props: {
			subscription: { type: Object, required: true },
			multiple: { type: Boolean, required: false, default: true },
		},

		components: {ClientAvatar},

		data: function () {
			return {
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
			};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {

			isMatch: function (val1, val2) {
				val2 = val2.toLowerCase();
				if (val1.firstName && val1.firstName.toLowerCase().startsWith(val2)) {
					return true;
				} else if (val1.lastName && val1.lastName.toLowerCase().startsWith(val2)) {
					return true;
				} else if (val1.phone && val1.phone.includes(val2)) {
					return true;
				} else {
					return false;
				}
			},

			matchesAnyCountryCode: function(countryCodes,phone){
				phone = phone.replace(/[^\d]/g, '');
				for(let i=0; i < countryCodes.length; i++){
					if(phone.startsWith(countryCodes[i])){
						return true;
					}
				}
				return false;
			},

			validateToList: function () {
				let countryCodes = [];
				this.subscription.countryCode.forEach(c => {
					countryCodes.push(c.replace(/[^\d]/g, ''));
				})
				let toList = [];

				if (this.multiple) {
					toList.push(...this.toList);
				} else {
					toList.push(this.toList);
				}

				let i = toList.length;
				while (i--) {
					let to = toList[i];

					if (to && !(to instanceof Object)) {
						let phone = to.replace(/[^\d+]/g, '');
						let result;
						let contact;

						if (phone) {
							try {
								if (phone.startsWith('+')) {
									result = this.PhoneUtil.parse(phone);
								} else if (this.matchesAnyCountryCode(countryCodes,phone)) {
									result = this.PhoneUtil.parse('+' + phone);
								} else {
									result = this.PhoneUtil.parse(this.subscription.countryCode[0] + phone);
								}

								if (this.PhoneUtil.isValidNumber(result)) {
									let e146 = this.PhoneUtil.format(result, this.PNF.E164);
									contact = this.contacts.find((c) => c.phone === e146);
									if (!contact) {
										contact = {
											id: null,
											phone: e146,
											firstName: '',
											lastName: '',
											fullName: this.PhoneUtil.format(result, this.PNF.NATIONAL),
											valid: true,
										};
									}
								}
							} catch (err) {
								console.log('error processing to number');
							}
						}

						for (let j = 0; j < this.contacts.length; j++) {
							if (this.isMatch(this.contacts[j], to)) {
								contact = this.contacts[j];
								break;
							}
						}

						if (!contact) {
							contact = {
								id: null,
								phone: null,
								firstName: '',
								lastName: '',
								fullName: to,
								valid: false,
							};
						}

						toList.splice(i, 1, contact);
					}
				}

				if (this.multiple) {
					this.toList.splice(0, this.toList.length);
					this.toList.push(...toList);
				} else {
					if (toList.length) {
						this.toList = toList[0];
					} else {
						this.toList = null;
					}
				}

				this.$emit('to-list', this.toList);
			},
		},

		computed: {
			contacts: function(){
				let result = [];
				for(let i=0; i < this.$store.getters.communicatorContacts.length; i++){
					let contact = JSON.parse(JSON.stringify(this.$store.getters.communicatorContacts[i]));

					if(contact.clientId){
						contact.client = this.$store.getters.getClientById(contact.clientId);
					}

					if(contact.phone){
						contact.phoneLabel = "Main";
						result.push(contact);
					}

					for(let j=0; j < contact.customValues.length; j++){
						let cv = contact.customValues[j];
						if(cv.type === 'Phone' && cv.value){
							let newContact = JSON.parse(JSON.stringify(contact));
							newContact.id = uuidv4();
							newContact.phone = cv.value;
							newContact.phoneLabel = cv.fieldName;
							newContact.valid = true;
							result.push(newContact);
						}
					}
				}

				result.sort((a,b) => a.fullName.localeCompare(b.fullName));
				return result;
			},

			toList: {
				get(){
					return this.$store.state.communicator.toList;
				},
				set(newVal){
					this.$store.state.communicator.toList = newVal;
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.phone-input {
		::v-deep .v-select__selections {
			padding: 4px !important;
		}

		::v-deep .v-chip {
			margin: 2px !important;
		}
	}
</style>
