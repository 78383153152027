import { render, staticRenderFns } from "./BandwidthNumbers.vue?vue&type=template&id=f68de012&scoped=true&"
import script from "./BandwidthNumbers.vue?vue&type=script&lang=js&"
export * from "./BandwidthNumbers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f68de012",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VDataTable,VIcon,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2004326659/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f68de012')) {
      api.createRecord('f68de012', component.options)
    } else {
      api.reload('f68de012', component.options)
    }
    module.hot.accept("./BandwidthNumbers.vue?vue&type=template&id=f68de012&scoped=true&", function () {
      api.rerender('f68de012', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/communicator/settings/providers/bandwidth/BandwidthNumbers.vue"
export default component.exports