var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "contact-card row-format align-center pa-4",
      on: {
        click: function($event) {
          return _vm.$emit("edit")
        }
      }
    },
    [
      _c("div", { staticClass: "column-format text-left gap-2" }, [
        _c(
          "div",
          { staticClass: "column-format mr-2", staticStyle: { gap: "2px" } },
          [
            _c("div", { staticClass: "font-16 brand-medium" }, [
              _vm._v(
                _vm._s(_vm.contact.firstName) +
                  " " +
                  _vm._s(_vm.contact.lastName)
              )
            ]),
            _c(
              "div",
              {
                staticClass: "font-12",
                staticStyle: { "word-wrap": "anywhere" }
              },
              [_vm._v(_vm._s(_vm.contact.role))]
            ),
            _c(
              "div",
              {
                staticClass: "font-12",
                staticStyle: { "word-wrap": "anywhere" }
              },
              [_vm._v(_vm._s(_vm.contact.email))]
            ),
            _c(
              "div",
              {
                staticClass: "font-12",
                staticStyle: { "word-wrap": "anywhere" }
              },
              [_vm._v(_vm._s(_vm.contact.phone))]
            ),
            _c(
              "div",
              { staticClass: "font-12 column-format" },
              _vm._l(_vm.customFields, function(field, index) {
                return _c("div", { key: index }, [
                  _vm._v(_vm._s(field.name) + ": " + _vm._s(field.value))
                ])
              }),
              0
            )
          ]
        ),
        _vm.contact.client
          ? _c(
              "div",
              {
                staticClass:
                  "font-12 brand-medium font-primary row-format align-center gap-1",
                on: { click: _vm.routeToClient }
              },
              [
                _c("client-avatar", {
                  attrs: {
                    client: _vm.contact.client,
                    "x-small": true,
                    "disable-click": false
                  }
                }),
                _c("div", [_vm._v(_vm._s(_vm.contact.client.name))])
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }