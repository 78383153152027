<template>
	<div>
		<v-menu
			v-model="menu"
			:nudge-top="28"
			min-width="150"
			max-width="150"
			bottom
			right
			rounded
			offset-overflow
			offset-y
			:close-on-content-click="false"
			:close-on-click="true"
			content-class="filter-menu-class"
			style="overflow-y: auto;"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" style="min-height: 40px; background-color: var(--v-white-base)" elevation="0" outlined color="gray_30">
					<span :class="`font-14 ${!filtersAreEmpty ? 'font-secondary' : 'font-gray_60'} brand-medium`" v-html="filterLabel"></span>
					<v-icon x-small class="mx-1" :color="!filtersAreEmpty ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="filter-option-box">
				<div class="row-format" @click="clientExpanded = !clientExpanded">
					<div class="pr-4 font-14">
						Client / Prospect
						<span class="brand-medium font-gray_60" v-if="filter.clients.length">[{{ filter.clients.length }}]</span>
					</div>
					<v-icon style="margin-left: auto; margin-top: 1px; margin-right: 4px" small>{{
						clientExpanded ? '$arrowUp' : '$arrowDown'
					}}</v-icon>
				</div>
				<div v-if="clientExpanded">
					<v-checkbox
						v-model="clientsSelected"
						hide-details
						dense
						:indeterminate="clientsIndeterminate"
						class="mx-0 my-2 pa-0"
					>
						<template v-slot:label>
							<div class="client-label row-format pointer" style="align-content: center;">
								<div class="tree-label">
									{{ $t('calendar.filter.select-deselect') }}
								</div>
							</div>
						</template>
					</v-checkbox>
					<v-checkbox
						v-model="filter.clients"
						v-for="client in clients"
						:value="client.id"
						:key="client.id"
						hide-details
						dense
						class="mx-0 my-2 pa-0"
					>
						<template v-slot:label>
							<div class="client-label row-format pointer" style="align-content: center;">
								<client-avatar :small="true" left :client="client" :disable-click="true"></client-avatar>
								<div class="truncate">{{ client.name }}</div>
							</div>
						</template>
					</v-checkbox>
				</div>
			</div>
			<div class="filter-option-box">
				<div class="row-format" @click="statusExpanded = !statusExpanded">
					<div class="font-14">
						Stages <span class="brand-medium  font-gray_60" v-if="filter.stages.length">[{{ filter.stages.length }}]</span>
					</div>
					<v-icon style="margin-left: auto; margin-top: 1px; margin-right: 4px" small>{{
						statusExpanded ? '$arrowUp' : '$arrowDown'
					}}</v-icon>
				</div>
				<div v-if="statusExpanded">
					<v-checkbox v-model="stagesSelected" hide-details dense :indeterminate="stagesIndeterminate">
						<template v-slot:label>
							<div class="client-label row-format pointer" style="align-content: center;">
								<div class="tree-label">
									{{ $t('calendar.filter.select-deselect') }}
								</div>
							</div>
						</template>
					</v-checkbox>
					<v-checkbox
						v-model="filter.stages"
						v-for="stage in stages"
						:value="stage.id"
						:key="stage.id"
						hide-details
						dense
					>
						<template v-slot:label>
							<div class="row-format align-center" style="width: 100%">
								<div
									:style="`width:12px; height: 12px; border-radius: 2px; background-color:${stage.hexColor}`"
								></div>
								<div class="status-label ml-2 ellipsis" v-html="stage.label"></div>
							</div>
						</template>
					</v-checkbox>
				</div>
			</div>
			<div class="filter-option-box">
				<div class="row-format" @click="sentimentExpanded = !sentimentExpanded">
					<div class="font-14">Confidence <span class="brand-medium font-gray_60" v-if="filter.sentimentType !== 'Any'">[1]</span></div>
					<v-icon style="margin-left: auto; margin-top: 1px; margin-right: 4px" small>{{
						sentimentExpanded ? '$arrowUp' : '$arrowDown'
					}}</v-icon>
				</div>
				<div v-if="sentimentExpanded" class="row-format gap-2">
					<v-select hide-details dense v-model="filter.sentimentType" :items="['Any', '>=', '<=','=']">
						<template v-slot:selection="{ item }">
							<div>{{ item }}</div>
						</template>
					</v-select>
					<v-select hide-details dense v-model="filter.sentimentValue" :items="confidenceList" item-value="value" v-if="filter.sentimentType !== 'Any'">
						<template v-slot:selection="{ item }">
							<v-icon :color="item.color" class="material-symbols-rounded" size="28"> {{ item.icon }} </v-icon>
						</template>
						<template v-slot:item="{ item }">
							<div class="fill-width row-format centered">
								<v-icon :color="item.color" class="material-symbols-rounded" size="28">{{ item.icon }}</v-icon>
							</div>
						</template>
					</v-select>
				</div>
			</div>
			<div class="filter-option-box">
				<div class="row-format" @click="valueExpanded = !valueExpanded">
					<div class="font-14">Value  <span class="brand-medium font-gray_60" v-if="filter.valueType !== 'Any'">[1]</span></div>
					<v-icon style="margin-left: auto; margin-top: 1px; margin-right: 4px" small>{{
							valueExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
				</div>
				<div v-if="valueExpanded" class="row-format gap-2">
					<v-select hide-details dense v-model="filter.valueType" :items="['Any', '>=', '<=','=']">
						<template v-slot:selection="{ item }">
							<div>{{ item }}</div>
						</template>
					</v-select>
					<v-text-field type="number" hide-details dense v-model="filter.value" v-if="filter.valueType !== 'Any'"></v-text-field>
				</div>
			</div>
			<div class="filter-option-box">
				<div class="row-format" @click="archiveExpanded = !archiveExpanded">
					<div class="font-14">Active  <span class="brand-medium font-gray_60" v-if="filter.archiveStatus !== 'active'">[1]</span></div>
					<v-icon style="margin-left: auto; margin-top: 1px; margin-right: 4px" small>{{
							archiveExpanded ? '$arrowUp' : '$arrowDown'
						}}</v-icon>
				</div>
				<div v-if="archiveExpanded" class="row-format gap-2">
					<v-radio-group @change="updated" class="small-radio" hide-details dense v-model="filter.archiveStatus">
						<v-radio label="Active" value="active"></v-radio>
						<v-radio label="Archived" value="archive"></v-radio>
						<v-radio label="All" value="all"></v-radio>
					</v-radio-group>
				</div>
			</div>




			<div class="mx-3 mt-3 mb-3 column-format" style="gap: 12px">
				<div @click="$emit('reset-filter')" class="pointer font-14 row-format centered" style="color: var(--v-secondary-base)">
					<v-icon small color="secondary">$clear</v-icon>
					<div>{{ $t('projects.filter.clear-filter') }}</div>
				</div>
				<v-btn class="super-action" @click="menu = false" >{{
						$t('global.done')
					}}</v-btn>
			</div>
		</v-menu>

	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'PipelineFilter',

		props: ['filter', 'clients', 'stages'],

		components: { ClientAvatar },

		data: function() {
			return {
				menu: false,
				clientExpanded: false,
				statusExpanded: false,
				sentimentExpanded: false,
				valueExpanded: false,
				archiveExpanded: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addAllClients() {
				this.removeAllClients();
				this.clients.forEach((c) => this.filter.clients.push(c.id));
			},

			removeAllClients() {
				this.filter.clients.splice(0, this.filter.clients.length);
			},

			addAllStages() {
				this.removeAllStages();
				this.stages.forEach((c) => this.filter.stages.push(c.id));
			},

			removeAllStages() {
				this.filter.stages.splice(0, this.filter.stages.length);
			},
		},

		computed: {
			confidenceList: function() {
				let result = [];

				result.push({ value: 0, icon: 'sentiment_very_dissatisfied', color: '#BA4A00' });
				result.push({ value: 1, icon: 'sentiment_dissatisfied', color: '#D68910' });
				result.push({ value: 2, icon: 'sentiment_neutral', color: '#2ECC71' });
				result.push({ value: 3, icon: 'sentiment_satisfied', color: '#1E8449' });
				result.push({ value: 4, icon: 'sentiment_very_satisfied', color: '#267AE1' });

				return result.reverse();
			},

			clientsSelected: {
				set: function(val) {
					if (val) return this.addAllClients();
					return this.removeAllClients();
				},
				get: function() {
					if (this.filter.clients.length > 0) return true;
					return false;
				},
			},

			clientsIndeterminate: function() {
				if (this.filter && this.filter.clients && this.filter.clients.length == this.clients.length) return false;
				if (this.filter && this.filter.clients && this.filter.clients.length == 0) return false;
				return true;
			},

			stagesSelected: {
				set: function(val) {
					if (val) return this.addAllStages();
					return this.removeAllStages();
				},
				get: function() {
					if (this.filter.stages.length > 0) return true;
					return false;
				},
			},

			stagesIndeterminate: function() {
				if (this.filter && this.filter.stages && this.filter.stages.length == this.stages.length) return false;
				if (this.filter && this.filter.stages && this.filter.stages.length == 0) return false;
				return true;
			},

			filterLabelArray: function(){
				let filter = [];
				if(this.filter.clients && this.filter.clients.length){
					filter.push(this.filter.clients.length + ' client' + (this.filter.clients.length > 1 ? 's' : ''));
				}
				if(this.filter.stages && this.filter.stages.length){
					filter.push(this.filter.stages.length + ' stage' + (this.filter.stages.length > 1 ? 's' : ''));
				}
				if(this.filter.sentimentType !== 'Any'){
					filter.push('Confidence');
				}
				if(this.filter.valueType !== 'Any'){
					filter.push('Value');
				}
				if(this.filter.archiveStatus !== 'active'){
					filter.push('Archive');
				}
				return filter;
			},

			filterLabel: function() {
				if(this.filtersAreEmpty) {
					return this.$t('projects.filter.filter');
				}else{
					return this.filterLabelArray.join(' &bull; ');
				}
			},

			filtersAreEmpty: function() {
				return this.filterLabelArray.length == 0;
			},
		},
	};
</script>

<style scoped lang="scss">
	.filter-wrapper {
		font-size: 14px;
		font-weight: 500;
		color: var(--v-primary-base);
		padding: 3px 8px 2px 6px;
		cursor: pointer;
		transition: all ease 250ms;

		&.filter-empty {
			width: 240px;
			&:hover {
				background-color: var(--v-gray_10-base);
			}
		}

		&.filter-active {
			background-color: var(--v-gray_10-base);
			color: var(--v-blue_70-base);
		}
	}

	#filterContainer {
		background-color: var(--v-white-base);
		min-width: 800px;
	}
</style>
