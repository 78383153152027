<template>
	<table id="fields-table" class="table">
		<thead>
			<tr>
				<th style="text-align: center; width: 50px;"></th>
				<th>Icon</th>
				<th>Field name</th>
				<th>Mapping key</th>
				<th>Type</th>
				<th>Options</th>
				<th v-if="maxPerCard > 0">Show on card</th>
				<th style="text-align: center; width: 50px;"></th>
			</tr>
		</thead>
		<draggable
			:key="refreshKey"
			v-model="customFields.fields"
			group="options"
			tag="tbody"
			@change="saveFields"
			handle=".drag"
			v-if="customFields && customFields.fields.length"
		>
			<tr v-for="field in customFields.fields" :key="field.id" class="font-14 text-left">
				<td style="width: 50px; text-align: center">
					<v-icon class="drag">drag_handle</v-icon>
				</td>
				<td>
					<icon-selector v-model="field.icon" @input="saveFields"></icon-selector>
				</td>
				<td>
					<div contenteditable="true" class="pa-1" @blur="setName(field, $event)">{{ field.name }}</div>
				</td>
				<td>
					<div contenteditable="true" class="pa-1" @blur="setMappingKey(field, $event)">{{ field.mappingKey }}</div>
				</td>
				<td>
					<v-menu :close-on-click="true" :close-on-content-click="true">
						<template v-slot:activator="{ on }">
							<div class="pointer" v-on="on">{{ field.type }}</div>
						</template>
						<div style="background-color: var(--v-white-base)" class="text-left font-14 more-menu">
							<div
								v-for="type in types"
								:key="type"
								class="pl-3 py-2 pointer more-menu-item"
								@click="setType(field, type)"
							>
								{{ type }}
							</div>
						</div>
					</v-menu>
				</td>
				<td>
					<v-menu :close-on-click="true" :close-on-content-click="false" v-if="isOptionType(field)">
						<template v-slot:activator="{ on }">
							<div v-on="on" class="pointer pr-2">
								<div v-if="field.options.length">{{ formatOptions(field.options) }}</div>
								<div v-else>--</div>
							</div>
						</template>
						<div style="background-color: var(--v-white-base)" class="text-left font-14 more-menu">
							<draggable v-model="field.options" group="test" handle=".nested-drag" @change="saveFields">
								<div
									v-for="(option, index) in field.options"
									:key="index"
									class="pointer more-menu-item row-format align-center fill-width gap-2"
								>
									<v-icon class="nested-drag">drag_handle</v-icon>
									<div contenteditable="true" class="fill-width py-2" @blur="setOption(field, index, $event)">
										{{ option }}
									</div>
									<v-icon class="ml-auto material-symbols-rounded" size="20" @click="deleteOption(field, index)"
										>delete</v-icon
									>
								</div>
							</draggable>
							<div class="more-menu-item" @click="addOption(field)">+ Add option</div>
						</div>
					</v-menu>
					<div v-else>--</div>
				</td>
				<td v-if="maxPerCard > 0">
					<v-switch
						v-model="field.showOnCard"
						hide-details
						dense
						class="mt-n1"
						@change="setShowOnCard(field)"
					></v-switch>
				</td>
				<td style="text-align: center; width: 50px;">
					<v-icon class="material-symbols-rounded" size="20" @click="confirmDelete(field)">delete</v-icon>
				</td>
			</tr>
		</draggable>

		<tr v-else>
			<td colspan="5" style="text-align: center" class="pa-4 font-gray_70">No custom fields defined</td>
		</tr>
	</table>
</template>

<script>
	import draggable from 'vuedraggable';
	import { v4 as uuidv4 } from 'uuid';
	import ConfirmModal from '@/components/ConfirmModal';
	import IconSelector from '@/components/IconSelector';
	import CustomFieldsService from '@/modules/fields/CustomFieldsService';

	export default {
		name: 'CustomFieldsEditor',

		props: ['type', 'maxPerCard'],

		components: { IconSelector, draggable },

		data: function() {
			return {
				refreshKey: 0,
				customFields: null,
				customFieldService: null,
				types: ['Text', 'Phone', 'Email', 'Link', 'Numeric', 'Currency', 'Date', 'Select', 'Radio', 'Checkbox'],
			};
		},

		mounted() {
			this.customFieldService = new CustomFieldsService(this.apiPath, this.apiVersion);
			this.getCustomFields();
		},

		beforeDestroy() {},

		methods: {
			formatOptions: function(options) {
				let result = options.join(', ').trim();
				if (result) {
					return result;
				} else {
					return '--';
				}
			},

			getCustomFields: function() {
				this.customFieldService.getCustomFields().then((res) => (this.customFields = res.data));
			},

			confirmDelete: function(field) {
				let binding = {
					headingText: 'Confirm delete?',
					bodyText: 'Are you sure you want to remove this field from your settings?',
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						let ix = this.customFields.fields.findIndex((f) => f.id === field.id);
						this.customFields.fields.splice(ix, 1);
						this.saveFields();
					}
				});
			},

			setShowOnCard: function(field) {
				let count = this.customFields.fields.filter((f) => f.showOnCard).length;
				if (count > this.maxPerCard) {
					let ix = this.customFields.fields.findIndex((f) => f.id === field.id);
					field.showOnCard = false;
					this.customFields.fields.splice(ix, 1, field);
					this.refreshKey++;
					this.$store.commit('error', `You can only have ${this.maxPerCard} custom fields visible on the card`);
				} else {
					this.saveFields();
				}
			},

			isOptionType: function(field) {
				return field.type === 'Select' || field.type === 'Radio' || field.type === 'Checkbox';
			},

			setName: function(field, event) {
				if (event.target && event.target.innerText) {
					field.name = event.target.innerText;
					event.target.innerText = field.name;
					this.saveFields();
				}
			},

			setMappingKey: function(field, event) {
				if (event.target && event.target.innerText) {
					field.mappingKey = event.target.innerText;
					event.target.innerText = field.mappingKey;
					this.saveFields();
				}
			},

			setOption: function(field, index, event) {
				if (event.target && event.target.innerText) {
					field.options[index] = event.target.innerText;
					event.target.innerText = field.options[index];
					this.saveFields();
				}
			},

			setType: function(field, type) {
				field.type = type;
				this.saveFields();
			},

			addOption: function(field) {
				field.options.push('');
				this.saveFields();
			},

			deleteOption: function(field, index) {
				field.options.splice(index, 1);
				this.saveFields();
			},

			addField: function() {
				let field = {
					id: uuidv4(),
					name: '',
					type: 'Text',
					options: [],
				};
				this.customFields.fields.push(field);
				this.saveFields();
			},

			saveFields: function() {
				this.customFieldService.updateCustomFields(this.customFields).then((res) => {
					this.$store.commit(`set${this.type}Fields`, res.data);
				});
			},
		},

		computed: {
			apiVersion: function() {
				if (this.type === 'Pipeline') {
					return 2;
				} else {
					return 1;
				}
			},

			apiPath: function() {
				if (this.type === 'Client') {
					return '/clientFields';
				} else if (this.type === 'Contact') {
					return '/contactFields';
				} else if (this.type === 'Project') {
					return '/projectFields';
				} else if (this.type === 'Pipeline') {
					return '/pipeline/fields';
				} else if (this.type === 'Deliverable') {
					return '/deliverableFields';
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.drag {
		cursor: grab;
		&:active {
			cursor: grabbing;
		}
	}

	#fields-table {
		text-align: left;
		border-collapse: collapse;
		width: 100%;

		thead {
			tr {
				th {
					padding: 8px;
					font-size: 12px;
					font-weight: 600;
					margin: 0px;
					border-top: 1px solid var(--v-gray_30-base);
					border-bottom: 1px solid var(--v-gray_30-base);
					border-right: 1px solid var(--v-gray_30-base);

					&:first-of-type {
						padding-left: 20px !important;
						border-left: 1px solid var(--v-gray_30-base);
					}

					&:last-of-type {
						padding-right: 12px !important;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 8px;
					margin: 0px;
					border-bottom: 1px solid var(--v-gray_30-base);
					border-right: 1px solid var(--v-gray_30-base);

					&:first-of-type {
						padding-left: 20px !important;
						border-left: 1px solid var(--v-gray_30-base);
					}
					&:last-of-type {
						padding-right: 12px !important;
					}
				}

				&:hover {
					td {
						background-color: var(--v-gray_10-base);
					}
				}
			}
		}
	}
</style>
