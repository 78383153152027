<template>
	<div>
		<div id="nav-shim"></div>
		<div
			id="left-nav"
			:class="$store.state.leftNavExpanded ? 'left-nav-expanded' : 'left-nav-collapsed'"
			@mouseenter="handleMouseIn"
			@mouseleave="handleMouseOut"
		>
			<div class="column-format fill-height" v-if="$store.state.selectedUserAccount && isReady">
				<div
					:class="`row-format align-center mx-2 mt-2 nav-link${navExpanded ? '-expanded' : ''}`"
					style="margin-bottom: 2px"
				>
					<div
						class="row-format align-center px-3 pb-3 pt-2 pointer"
						style="width: 100%; min-height: 56px; min-width: 56px"
						id="moxie-logo-link"
						@click="$router.push('/getting-started')"
					>
						<logo style="max-height: 28px; margin-left: -2px; margin-top: -4px" v-if="navExpanded"></logo>
						<icon style="max-height: 22px; margin-left: -8px; margin-top: -8px" v-else @click="$router.push('/getting-started')"></icon>
					</div>
				</div>
				<div style="max-height: calc(var(--vh) - 120px); overflow-y: auto; padding-top: 6px" class="column-format gap-5 pb-16">
					<template v-for="element in pinnedElements">
						<nav-link
							:key="element.key"
							:label="element.title.label"
							:icon="element.title.icon"
							:route="element.key"
							v-if="!element.title.featureAccess || $store.getters.hasFeatureAccess(element.title.featureAccess)"
							:nav-config="NavConfig"
							:expanded="navExpanded"
						>
						</nav-link>
					</template>
					<v-menu :close-on-content-click="false" v-model="moreMenu">
						<template v-slot:activator="{ on }">
							<nav-link
								label="More"
								icon="steppers"
								class="pointer"
								:more="true"
								v-on="on"
								:expanded="navExpanded"
							></nav-link>
						</template>
						<div class="column-format brand-box gap-5" @mouseenter="handleMoreMouseIn" @mouseleave="handleMoreMouseOut">
							<div v-if="unPinnedElements.length" class="column-format gap-5 pt-5">
								<template v-for="element in unPinnedElements">
									<div class="row-format" :key="element.key" style="align-items: start">
										<nav-link
											:label="element.title.label"
											:icon="element.title.icon"
											:route="element.key"
											v-if="
												!element.title.featureAccess ||
													$store.getters.hasFeatureAccess(element.title.featureAccess)
											"
											:nav-config="NavConfig"
											:expanded="true"
											@click="moreMenu = false"

										>
										</nav-link>
										<v-icon
											class="ml-auto material-symbols-rounded pointer mr-3 ml-n2"
											size="16"
											color="gray_50"
											v-tippy
											content="Pin"
											@click="pin(element.key)"
											>keep</v-icon
										>
									</div>
								</template>
							</div>
							<div
								style="background-color: var(--v-gray_5-base); border-top: 1px solid var(--v-gray_30-base); border-bottom-left-radius: 4px; border-bottom-right-radius: 4px"
								class="text-left pt-3 pb-6 pl-6 pr-3 column-format gap-3"
							>
								<div class="brand-medium font-14">Pinned</div>
								<div v-for="element in pinnedElements" :key="element.key + 'a'" class="row-format gap-2">
									<v-icon color="black" class="material-symbols-rounded" size="18">{{
										element.title.icon
									}}</v-icon>
									<div class="nav-label">{{ element.title.label }}</div>
									<v-icon
										class="ml-auto material-symbols-rounded pointer"
										size="16"
										color="primary"
										v-tippy
										content="Unpin"
										@click="unpin(element.key)"
										>keep</v-icon
									>
								</div>
							</div>
						</div>
					</v-menu>
				</div>

				<div
					class="py-2 pointer row-format centered font-14 brand-medium"
					@click="$router.push('/subscription')"
					v-if="showTrialBanner && navExpanded"
					style="color: var(--v-white-base);background-color: var(--v-secondary-base); position: absolute; bottom: 54px; left: 0; width:236px"
				>
					<div>{{ daysInTrial }} {{ daysInTrial !== 1 ? 'Days' : 'Day' }} remaining in your trial</div>
				</div>

				<v-menu offset-x nudge-left="10" content-class="workspace-menu">
					<template v-slot:activator="{ on }">
						<div class="workspace-settings row-format align-center mt-auto" v-on="on" :style="!navExpanded ? 'max-width: 59px;' : ''">
							<div
								v-if="!navExpanded"
								class="row-format centered fill-width"
							>
								<v-avatar size="24" color="white">
									<img :src="accountLogo" alt="logo" v-if="accountLogo" />
									<span v-else class="black--text brand-bold">{{ accountInitials }}</span>
								</v-avatar>
							</div>

							<div v-else class="row-format align-center font-14 flex-grow-1 pl-3 pr-2" style="line-height: 1.2em">
								<v-avatar size="24" color="white">
									<img :src="accountLogo" alt="logo" v-if="accountLogo" />
									<span v-else class="black--text brand-bold">{{ accountInitials }}</span>
								</v-avatar>

								<div class="column-format text-left pl-3">
									<div class="truncate brand-medium" style="max-width: 160px">{{ accountName }}</div>
									<div class="font-12 font-gray_50">Workspace settings</div>
								</div>

								<v-icon class="ml-auto" size="20" color="gray_80" small>$chevronRight</v-icon>
							</div>
						</div>
					</template>

					<settings-nav></settings-nav>
				</v-menu>
			</div>
		</div>
	</div>
</template>

<script>
	import NavLink from '@/views/nav/NavLink';
	import SettingsNav from '@/views/nav/settings/SettingsNav';
	import logo from '@/assets/logo';
	import icon from '@/assets/icon';
	import { DateTime } from 'luxon';
	import NavConfig from '@/views/nav/NavConfig.json';
	import UserNavSettingsService from "@/views/nav/UserNavSettingsService";

	export default {
		name: 'LeftNav',

		props: [],

		components: { SettingsNav, NavLink, logo, icon },

		data: function() {
			return {
				userNavSettingsService: new UserNavSettingsService(),
				NavConfig: NavConfig,
				onboardingMenu: false,
				moreMenu: false,
				pinned: ['/home', '/clients', '/projects','/tickets','/communicator','/pipeline', '/agreements', '/accounting', '/timesheets', '/favorites','/meetings','/forms'],
				expanded: false,
				requestExpanded: false,
				moreExpanded: false,
				isReady: false,
			};
		},

		mounted() {
			this.getLeftNavExpanded();
			this.getUserNavSettings();
			this.$store.state.eventBus.$on('toggle-favorite-client',this.toggleFavoriteClient);
			this.$store.state.eventBus.$on('handle-theme-selection',this.saveThemeSelection);
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('toggle-favorite-client',this.toggleFavoriteClient);
			this.$store.state.eventBus.$off('handle-theme-selection',this.saveThemeSelection);
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function(){
				this.isReady = false;
				this.getUserNavSettings();
			},

			getUserNavSettings: function(){
				this.userNavSettingsService.getUserNavSettings().then((res) => {
					if(res.data){
						this.setLocalUserNavSettings(res.data);
					}else{
						this.initUserNavSettings();
					}
				})
			},

			initUserNavSettings: function(){
				let navSettings = {
					pinned: this.pinned,
					favorites: this.getLegacyFavoriteClients(),
					theme: this.$store.state.theme
				}
				this.userNavSettingsService.updateUserNavSettings(navSettings).then((res) => {
					this.setLocalUserNavSettings(res.data);
				})
			},

			saveUserNavSettings: function(){
				let navSettings = {
					pinned: this.pinned,
					favorites: this.$store.state.favoriteClients,
					theme: this.$store.state.theme
				}

				this.userNavSettingsService.updateUserNavSettings(navSettings).then((res) => {
					this.setLocalUserNavSettings(res.data);
				})
			},

			setLocalUserNavSettings: function(userNavSettings){
				this.pinned.splice(0,this.pinned.length);
				this.pinned.push(... userNavSettings.pinned);

				this.handleThemeSelection(userNavSettings.theme);

				this.$store.state.favoriteClients.splice(0,this.$store.state.favoriteClients.length);
				this.$store.state.favoriteClients.push(... userNavSettings.favorites);
				this.isReady = true;
			},

			saveThemeSelection: function(theme){
				this.handleThemeSelection(theme);
				this.saveUserNavSettings();
			},

			handleThemeSelection: function(theme){
				if(theme === 'always' || theme === 'automatic'){
					localStorage.setItem('DARK_MODE', theme);
					this.$store.state.darkMode = theme;
				}else{
					localStorage.setItem('DARK_MODE', 'never');
					this.$store.state.darkMode = 'never';
					localStorage.setItem('THEME', theme);
				}

				this.$store.state.theme = theme;
				this.$store.state.eventBus.$emit('dark-mode-changed', this.$store.state.darkMode);
			},

			toggleFavoriteClient: function(id){
				let ix = this.$store.state.favoriteClients.indexOf(id);
				if (ix > -1) {
					this.$store.state.favoriteClients.splice(ix, 1);
				} else {
					this.$store.state.favoriteClients.push(id);
				}
				this.saveUserNavSettings();
			},

			getLegacyFavoriteClients() {
				try {
					let favoriteClients = localStorage.getItem('FAVORITE_CLIENTS');
					if (favoriteClients) {
						return JSON.parse(favoriteClients);
					}else{
						return [];
					}
				} catch (err) {
					console.log(err);
				}
			},

			handleMoreMouseIn: function(){
				this.moreExpanded = true;
				this.handleMouseIn();
			},

			handleMoreMouseOut: function(){
				this.moreExpanded = false;
				setTimeout(() => {
					if(!this.moreExpanded){
						this.moreMenu = false;
					}
				},1000);
				this.handleMouseOut();
			},

			handleMouseIn: function() {
				if (this.$store.state.leftNavExpanded) {
					return;
				}

				this.requestExpanded = true;

				setTimeout(() => {
					if (this.requestExpanded) {
						let nav = document.getElementById('left-nav');
						let navShim = document.getElementById('nav-shim');

						nav.classList.add('left-nav-floating');
						nav.classList.remove('left-nav-collapsed');
						navShim.style.display = 'block';
						this.expanded = true;
					}
				}, 250);
			},

			handleMouseOut: function() {
				if (this.$store.state.leftNavExpanded) {
					return;
				}

				this.requestExpanded = false;

				setTimeout(() => {
					if (!this.requestExpanded) {
						let nav = document.getElementById('left-nav');
						let navShim = document.getElementById('nav-shim');

						nav.classList.remove('left-nav-floating');
						nav.classList.add('left-nav-collapsed');
						navShim.style.display = 'none';

						this.expanded = false;
						this.moreMenu = false;
					}
				}, 250);
			},

			pin: function(key) {
				let ix = this.pinned.indexOf(key);
				if (ix === -1) {
					this.pinned.push(key);
					this.saveUserNavSettings();
				}
			},

			unpin: function(key) {
				let ix = this.pinned.indexOf(key);
				if (ix !== -1) {
					this.pinned.splice(ix, 1);
					this.saveUserNavSettings();
				}
			},

			getLeftNavExpanded: function() {
				let strState = localStorage.getItem('LEFT_NAV_STATE');
				if (strState) {
					let leftNavState = JSON.parse(strState);
					this.$store.state.leftNavExpanded = leftNavState.state;
				}
			},
		},

		computed: {
			navExpanded: function() {
				return this.$store.state.leftNavExpanded || this.expanded;
			},

			navElements: function() {
				let result = [];
				for (const [key, value] of Object.entries(JSON.parse(JSON.stringify(NavConfig)))) {
					value.key = key;
					result.push(value);
				}
				return result;
			},

			pinnedElements: function() {
				return this.navElements.filter((n) => this.pinned.includes(n.key));
			},

			unPinnedElements: function() {
				return this.navElements.filter((n) => !this.pinned.includes(n.key));
			},

			showTrialBanner: function() {
				if (this.$store.getters.getAccount.pricingVersion > 1 && this.daysInTrial > -1) {
					return true;
				} else {
					return false;
				}
			},

			daysInTrial: function() {
				if (this.$store.getters.getAccount && this.$store.getters.getAccount.trialEndsAt) {
					let trialEndsAt = DateTime.fromISO(this.$store.getters.getAccount.trialEndsAt);
					let now = DateTime.now();
					if (trialEndsAt > now) {
						return Math.round(trialEndsAt.diff(now, ['days']).days);
					} else {
						return -1;
					}
				} else {
					return -1;
				}
			},

			favoriteClients: function() {
				let clients = [...this.$store.getters.clients];
				clients = clients.filter((c) => this.$store.state.favoriteClients.includes(c.id));
				clients.sort((a, b) => a.name.localeCompare(b.name));
				return clients;
			},

			accountInitials() {
				let ignoreMe = ['a', 'an', 'as', 'to', 'and', 'of', 'if', 'in', '-'];
				let accountName = this.$store.getters.getAccountName;
				if (!accountName) {
					return '?';
				}
				let words = this.$store.getters.getAccountName.toLowerCase().split(' ');
				let ret = [];
				for (let i = 0; i < words.length; i++) {
					if (ignoreMe.indexOf(words[i]) > -1) continue;
					ret[i] = words[i].substring(0, 1);
					if (ret.length == 3) break;
				}
				return ret.join('').toUpperCase();
			},

			accountName() {
				return this.$store.getters.getAccountName;
			},

			accountLogo() {
				return this.$store.getters.getAccountLogo;
			},

			isFullUser() {
				return this.$store.getters.isAccountFullUser;
			},

			isAccountCollaborator() {
				return this.$store.getters.isAccountCollaborator;
			},

			isInFavoriteLink: function() {
				for (let i = 0; i < this.favoriteClients.length; i++) {
					if (this.$route.path.startsWith(`/client/${this.favoriteClients[i].id}`)) {
						return true;
					}
				}
				return false;
			},
		},
	};
</script>

<style lang="scss">
	.workspace-menu {
		border-radius: 4px !important;
		position: absolute;
		bottom: 20px !important;
		top: unset !important;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_30-base);
		z-index: 1000 !important;
	}
</style>

<style scoped lang="scss">
	#left-nav {
		transition: all 150ms linear;
		background-color: var(--v-white-base);
		border-right: 1px solid var(--v-gray_30-base);
	}

	#nav-shim {
		display: none;
		width: 64px;
		min-width: 60px;
		max-width: 60px;
	}

	.left-nav-expanded {
		display: flex;
		flex-direction: row;
		width: 236px;
		min-width: 236px;
		max-width: 236px;
		height: 100vh;
		min-height: 100vh;
	}

	.left-nav-floating {
		display: flex;
		flex-direction: row;
		width: 236px;
		min-width: 236px;
		max-width: 236px;
		height: 100vh;
		min-height: 100vh;
		position: absolute;
		overflow-y: hidden;
		z-index: 1000;
		box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)
	}

	.left-nav-collapsed {
		display: flex;
		flex-direction: row;
		width: 64px;
		min-width: 60px;
		max-width: 60px;
		height: 100vh;
		min-height: 100vh;
	}

	.nav-label {
		font-size: 12px;
		color: var(--v-gray_90-base);
		font-weight: 400;
	}

	.separator {
		border: 1px solid rgba(0, 0, 0, 0.1);
		height: 0px;
		width: 100%;
	}

	.workspace-settings {
		cursor: pointer;
		height: 52px;
		background: var(--v-gray_5-base);
		border-top: 1px solid var(--v-gray_20-base);
	}
</style>
