var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style:
        "width:100%; padding-top: " +
        _vm.item.pt +
        "px; padding-bottom: " +
        _vm.item.pb +
        "px; padding-left: " +
        _vm.item.pl +
        "px; padding-right: " +
        _vm.item.pr +
        "px; overflow-x: auto; --menuY:" +
        _vm.menuY +
        "px; --menuX:" +
        _vm.menuX +
        "px;",
      attrs: { id: "invoice-line-items" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "table",
        {
          class: "line-item-table " + _vm.item.size.toLowerCase(),
          staticStyle: { height: "1px" }
        },
        [
          _c("thead", [
            _c("tr", { staticClass: "header" }, [
              _c("th", [_vm._v(_vm._s(_vm.item.labels.description))]),
              _vm.item.enabledColumns.quantity
                ? _c("th", { staticStyle: { width: "10%" } }, [
                    _vm._v(_vm._s(_vm.item.labels.quantity))
                  ])
                : _vm._e(),
              _vm.item.enabledColumns.rate
                ? _c("th", { staticStyle: { width: "15%" } }, [
                    _vm._v(_vm._s(_vm.item.labels.rate))
                  ])
                : _vm._e(),
              _vm.item.enabledColumns.tax
                ? _c("th", { staticStyle: { width: "10%" } }, [
                    _vm._v(_vm._s(_vm.item.labels.tax))
                  ])
                : _vm._e(),
              _c("th", { staticStyle: { width: "15%" } }, [
                _vm._v(_vm._s(_vm.item.labels.total))
              ])
            ])
          ]),
          _c(
            "tbody",
            [
              _vm._l(_vm.items, function(lineItem, index) {
                return [
                  lineItem.visible
                    ? _c(
                        "tr",
                        {
                          directives: [{ name: "tippy", rawName: "v-tippy" }],
                          key: "A" + lineItem.id + index,
                          class:
                            "handle " +
                            (_vm.item.showTimeDetails &&
                            lineItem.timerEventIds &&
                            lineItem.timerEventIds.length
                              ? ""
                              : "line-item") +
                            " " +
                            (_vm.editMode && !lineItem.isTransientItem
                              ? "pointer"
                              : ""),
                          style: lineItem.isTransientItem ? "opacity: 0.4" : "",
                          attrs: {
                            id: "line-" + lineItem.id,
                            content: lineItem.isTransientItem
                              ? "This item is automatically added based on your settings in the Recurring invoice panel.  If you wish to change the rates or inclusion, please update your project or recurring settings accordingly."
                              : null
                          },
                          on: {
                            click: function($event) {
                              return _vm.editLineItem(lineItem, $event)
                            }
                          }
                        },
                        [
                          _c("td", { staticClass: "line-item-description" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "description row-format align-center"
                              },
                              [
                                _vm.editMode && !lineItem.isTransientItem
                                  ? _c(
                                      "div",
                                      { staticClass: "column-format re-order" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mb-n1",
                                            attrs: { color: "gray_70" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.moveUp(index)
                                              }
                                            }
                                          },
                                          [_vm._v("expand_less")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mt-n1",
                                            attrs: { color: "gray_70" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.moveDown(index)
                                              }
                                            }
                                          },
                                          [_vm._v("expand_more")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { "line-height": "1.4em" } },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(lineItem.description)
                                      }
                                    }),
                                    _c("br"),
                                    lineItem.projectId &&
                                    !lineItem.description.includes(
                                      lineItem.projectName
                                    )
                                      ? _c("span", [
                                          _vm._v(_vm._s(lineItem.projectName))
                                        ])
                                      : _vm._e(),
                                    lineItem.retainerPeriod
                                      ? _c("span", [
                                          _c("br"),
                                          _vm._v(
                                            _vm._s(
                                              _vm.DateTime.fromISO(
                                                lineItem.retainerPeriod.start
                                              ).toLocaleString(
                                                _vm.DateTime.DATE_MED
                                              )
                                            ) +
                                              " - " +
                                              _vm._s(
                                                _vm.DateTime.fromISO(
                                                  lineItem.retainerPeriod.end
                                                ).toLocaleString(
                                                  _vm.DateTime.DATE_MED
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    lineItem.type === "DEPOSIT"
                                      ? _c(
                                          "span",
                                          [
                                            _c("br"),
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "gray_70",
                                                  size: "16"
                                                }
                                              },
                                              [_vm._v("account_balance")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm.item.enabledColumns.quantity
                            ? _c("td", { staticStyle: { width: "10%" } }, [
                                _vm.item.roundQty
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.formatQuantity(lineItem))
                                      )
                                    ])
                                  : _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "tippy",
                                            rawName: "v-tippy",
                                            value: { content: lineItem.qty },
                                            expression: "{content:lineItem.qty}"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.formatQuantity(lineItem))
                                        )
                                      ]
                                    )
                              ])
                            : _vm._e(),
                          _vm.item.enabledColumns.rate
                            ? _c("td", { staticStyle: { width: "15%" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$formatters.dollars(
                                        lineItem.price,
                                        true,
                                        true,
                                        _vm.currency,
                                        4
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.item.enabledColumns.tax
                            ? _c(
                                "td",
                                { staticStyle: { width: "10%" } },
                                [
                                  lineItem.taxable
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "material-symbols-rounded pr-1",
                                          style:
                                            "color:" +
                                            _vm.styles.fontColor +
                                            "!important",
                                          attrs: { size: "22" }
                                        },
                                        [_vm._v("check")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("td", { staticStyle: { width: "15%" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$formatters.dollars(
                                  lineItem.subtotal,
                                  true,
                                  true,
                                  _vm.currency
                                )
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  lineItem.visible &&
                  _vm.item.showTimeDetails &&
                  lineItem.timerEventIds &&
                  lineItem.timerEventIds.length &&
                  lineItem.id
                    ? _c(
                        "tr",
                        {
                          key: "B" + lineItem.id + index,
                          staticClass: "line-item"
                        },
                        [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                "padding-top": "0!important",
                                "padding-bottom": "4px"
                              },
                              attrs: { colspan: "5" }
                            },
                            [
                              _c("time-entries", {
                                attrs: {
                                  invoice: _vm.invoice,
                                  "invoice-item-id": lineItem.id,
                                  "invoice-service": _vm.invoiceService
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ]
              }),
              _c("tr", { staticStyle: { height: "100%" } }, [
                _c(
                  "td",
                  {
                    staticClass: "pr-3",
                    style: "height: 100%; text-align: " + _vm.buttonAlignment,
                    attrs: { colspan: _vm.colspanSpacer }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "column-format",
                        staticStyle: { "min-height": "100%", height: "100%" }
                      },
                      [
                        _vm.editMode && _vm.invoice.invoiceType === "STANDARD"
                          ? _c(
                              "div",
                              { staticClass: "my-4" },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    staticStyle: { "overflow-y": "auto" },
                                    attrs: {
                                      "max-height": "90vh",
                                      "max-width": "900",
                                      "close-on-content-click": false,
                                      "close-on-click": true,
                                      "content-class": "filter-menu-class",
                                      origin: "top center",
                                      transition: "scale-transition"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "add-a-new-line"
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: "20",
                                                        color: "primary"
                                                      }
                                                    },
                                                    [_vm._v("add_circle")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "brand-regular"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "invoice.details.add-a-new-line-item"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      984695953
                                    ),
                                    model: {
                                      value: _vm.showProductsMenu,
                                      callback: function($$v) {
                                        _vm.showProductsMenu = $$v
                                      },
                                      expression: "showProductsMenu"
                                    }
                                  },
                                  [
                                    _vm.editMode
                                      ? _c("products", {
                                          attrs: {
                                            currency: _vm.invoice.currency,
                                            "client-id": _vm.invoice.clientId
                                          },
                                          on: {
                                            close: function($event) {
                                              _vm.showProductsMenu = false
                                            },
                                            select: function($event) {
                                              return _vm.handleSelectProduct(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                !_vm.recurring && !_vm.paymentPlan
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "add-a-new-line",
                                        on: {
                                          click: function($event) {
                                            return _vm.handleAddNewTrackedItemModal()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "20",
                                              color: "primary"
                                            }
                                          },
                                          [_vm._v("folder_special")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brand-regular" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoice.details.add-line-item-from-billable"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.paymentPlan
                                  ? _c(
                                      "v-menu",
                                      {
                                        staticStyle: { "overflow-y": "auto" },
                                        attrs: {
                                          "max-height": "90vh",
                                          "max-width": "900",
                                          "close-on-content-click": false,
                                          "close-on-click": true,
                                          "content-class": "filter-menu-class",
                                          origin: "top center",
                                          transition: "scale-transition"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "add-a-new-line"
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            size: "20",
                                                            color: "primary"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "folder_special"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "brand-regular"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoice.details.add-fixed-price-project"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          301717936
                                        ),
                                        model: {
                                          value: _vm.showProjectMenu,
                                          callback: function($$v) {
                                            _vm.showProjectMenu = $$v
                                          },
                                          expression: "showProjectMenu"
                                        }
                                      },
                                      [
                                        _c("fixed-price-project-list", {
                                          attrs: {
                                            invoice: _vm.invoice,
                                            "invoice-service":
                                              _vm.invoiceService
                                          },
                                          on: {
                                            selected: function($event) {
                                              return _vm.handleSelectProject(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.hasExpenses &&
                                !_vm.recurring &&
                                !_vm.paymentPlan
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "add-a-new-line",
                                        on: {
                                          click: function($event) {
                                            return _vm.handleAddExpenses()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "20",
                                              color: "primary"
                                            }
                                          },
                                          [_vm._v("paid")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brand-regular" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "invoice.details.add-expenses"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                (_vm.balanceExists ||
                                  _vm.invoice.creditAmount) &&
                                !_vm.recurring &&
                                !_vm.paymentPlan
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "add-a-new-line",
                                        on: {
                                          click: function($event) {
                                            return _vm.handleApplyDeposit()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "20",
                                              color: "primary"
                                            }
                                          },
                                          [_vm._v("account_balance")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "brand-regular" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "invoice.details.apply-deposit-credit"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "mt-auto" },
                          [
                            _vm.invoice.amountDue > 0 &&
                            _vm.invoice.payOnlineUrl &&
                            _vm.invoice.allowOnlinePayment
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "elevation-0",
                                    attrs: {
                                      color: _vm.item.buttonColor,
                                      "x-small": _vm.payNowButton.xSmall,
                                      small: _vm.payNowButton.small,
                                      large: _vm.payNowButton.large,
                                      "x-large": _vm.payNowButton.xLarge,
                                      outlined: _vm.payNowButton.outlined,
                                      block: _vm.payNowButton.block,
                                      text: _vm.payNowButton.text
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handlePayNow()
                                      }
                                    }
                                  },
                                  [
                                    _vm.item.payNowIcon
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "material-symbols-rounded mr-1",
                                            style:
                                              "color: " +
                                              _vm.item.buttonTextColor +
                                              "!important"
                                          },
                                          [_vm._v(_vm._s(_vm.item.payNowIcon))]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        style:
                                          "color: " +
                                          _vm.item.buttonTextColor +
                                          "; font-family: " +
                                          _vm.styles.font
                                      },
                                      [_vm._v(_vm._s(_vm.item.payNowText))]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "td",
                  {
                    staticStyle: { "padding-right": "0!important" },
                    attrs: { colspan: _vm.colspanSubTotal }
                  },
                  [
                    _c("div", { staticClass: "row-format" }, [
                      _c("div", { staticClass: "ml-auto mt-2" }, [
                        _c(
                          "table",
                          { staticStyle: { "border-collapse": "collapse" } },
                          [
                            _c("tr", { staticClass: "summary sub-total" }, [
                              _c(
                                "td",
                                {
                                  staticClass: "text-right summary-label",
                                  attrs: { colspan: "2" }
                                },
                                [_vm._v(_vm._s(_vm.item.labels.subTotal))]
                              ),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatters.dollars(
                                      _vm.invoice.itemSubtotal,
                                      true,
                                      true,
                                      _vm.currency
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm.invoice.discountAmount || _vm.inBuilder
                              ? _c("tr", { staticClass: "summary discount" }, [
                                  _c(
                                    "td",
                                    { staticClass: "text-right summary-label" },
                                    [_vm._v(_vm._s(_vm.item.labels.discount))]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-right summary-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.invoice.discountPercentage) +
                                          "%"
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatters.dollars(
                                          _vm.invoice.discountAmount * -1,
                                          true,
                                          true,
                                          _vm.currency
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.invoice.creditAmount || _vm.inBuilder
                              ? _c("tr", { staticClass: "summary credit" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right summary-label",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.item.labels.creditApplied)
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatters.dollars(
                                          _vm.invoice.creditAmount * -1,
                                          true,
                                          true,
                                          _vm.currency
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.invoice.convenienceFee || _vm.inBuilder
                              ? _c("tr", { staticClass: "summary credit" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right summary-label",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.item.labels.processingFee)
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatters.dollars(
                                          _vm.invoice.convenienceFee,
                                          true,
                                          true,
                                          _vm.currency
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.lateFee || _vm.inBuilder
                              ? _c("tr", { staticClass: "summary late-fee" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right summary-label",
                                      attrs: { colspan: "2" }
                                    },
                                    [_vm._v(_vm._s(_vm.item.labels.lateFee))]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatters.dollars(
                                            _vm.lateFee,
                                            true,
                                            true,
                                            _vm.currency
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.showTax
                              ? _c("tr", { staticClass: "summary tax" }, [
                                  _c(
                                    "td",
                                    { staticClass: "text-right summary-label" },
                                    [_vm._v(_vm._s(_vm.item.labels.tax))]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-right summary-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.invoice.taxPercentage) + "%"
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatters.dollars(
                                          _vm.invoice.taxAmount,
                                          true,
                                          true,
                                          _vm.currency
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _c("tr", { staticClass: "summary grand-total" }, [
                              _c(
                                "td",
                                {
                                  staticClass: "text-right summary-label",
                                  attrs: { colspan: "2" }
                                },
                                [_vm._v(_vm._s(_vm.item.labels.total))]
                              ),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatters.dollars(
                                      _vm.invoice.total,
                                      true,
                                      true,
                                      _vm.currency
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._l(_vm.invoice.payments, function(payment) {
                              return _c(
                                "tr",
                                {
                                  key: payment.id,
                                  staticClass: "summary status"
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right summary-label",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$DateTime.shortDate(
                                            payment.datePaid
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatters.dollars(
                                          payment.amount,
                                          true,
                                          true,
                                          _vm.currency
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            }),
                            _vm.invoice.paymentTotal > 0
                              ? _c("tr", { staticClass: "summary status" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right summary-label",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.item.labels.paymentTotal)
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatters.dollars(
                                          _vm.invoice.paymentTotal,
                                          true,
                                          true,
                                          _vm.currency
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.invoice.paymentTotal > 0
                              ? _c("tr", { staticClass: "summary status" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right summary-label",
                                      attrs: { colspan: "2" }
                                    },
                                    [_vm._v(_vm._s(_vm.item.labels.amountDue))]
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatters.dollars(
                                          _vm.invoice.amountDue,
                                          true,
                                          true,
                                          _vm.currency
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ])
                  ]
                )
              ])
            ],
            2
          )
        ]
      ),
      _vm.scheduledPayments && _vm.scheduledPayments.length
        ? _c("scheduled-payments", {
            staticClass: "mt-5",
            attrs: {
              invoice: _vm.invoice,
              "scheduled-payments": _vm.scheduledPayments,
              "line-items": _vm.item
            }
          })
        : _vm._e(),
      _vm.dialog
        ? _c(
            "v-menu",
            {
              attrs: {
                attach: "#invoice-line-items",
                "content-class": "override-menu",
                "close-on-content-click": false
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c("line-item-editor", {
                attrs: {
                  "line-item": _vm.lineItem,
                  invoice: _vm.invoice,
                  "menu-width": _vm.menuWidth
                },
                on: {
                  cancel: function($event) {
                    _vm.dialog = false
                  },
                  save: function($event) {
                    return _vm.updateLineItem($event)
                  },
                  delete: function($event) {
                    return _vm.deleteLineItem($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }