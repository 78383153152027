<template>
	<div class="contact-card row-format align-center pa-4" @click="$emit('edit')">
		<!--div class="ml-3 mr-2 column-format align-center">
			<v-avatar color="black" size="40">
				<span class="font-18" style="color: var(--v-white-base)">{{ getInitials(contact) }}</span>
			</v-avatar>
		</div-->

		<div class="column-format text-left gap-2">
			<div class="column-format mr-2" style="gap: 2px">
				<div class="font-16 brand-medium">{{ contact.firstName }} {{ contact.lastName }}</div>
				<div class="font-12" style="word-wrap: anywhere">{{ contact.role }}</div>
				<div class="font-12" style="word-wrap: anywhere">{{ contact.email }}</div>
				<div class="font-12" style="word-wrap: anywhere">{{ contact.phone }}</div>
				<div class="font-12 column-format">
					<div v-for="(field,index) in customFields" :key="index">{{field.name}}: {{field.value}}</div>
				</div>
			</div>
			<div class="font-12 brand-medium font-primary row-format align-center gap-1" v-if="contact.client" @click="routeToClient">
				<client-avatar :client="contact.client" :x-small="true" :disable-click="false"></client-avatar>
				<div>{{contact.client.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";
	import DateTime from "@/modules/utils/HDateTime";
	export default {
		name: 'ContactCard',

		props: ['input'],

		components: {ClientAvatar},

		data: function () {
			return {
				contact: JSON.parse(JSON.stringify(this.input))
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			getInitials: function (contact) {
				let initials = '';
				if (contact.firstName) {
					initials += contact.firstName.substr(0, 1).toUpperCase();
				}
				if (contact.lastName) {
					initials += contact.lastName.substr(0, 1).toUpperCase();
				}
				if (initials) {
					return initials;
				} else {
					return '--';
				}
			},

			routeToClient: function (event) {
				event.stopPropagation();
				this.$router.push(`/client/${this.contact.client.id}`);
			},
		},

		computed: {
			customFields: function(){
				let result = [];
				this.$store.state.contactFields.fields.filter(c => c.showOnCard).forEach(f => {
						let v = this.contact.customValues.find(cv => cv.fieldId === f.id);
						if(v){
							if(f.type === 'Date'){
								v.value = DateTime.fromISO(v.value).toLocaleString(DateTime.DATE_MED)
							}

							result.push({
								name: f.name,
								value: v.value
							})
						}
				})
				return result;
			}
		},
	};
</script>

<style scoped lang="scss">
	.long-text {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.contact-card {
		min-height: 150px;
		width: 300px;
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		margin-right: 2px;
		cursor: pointer;
		background-color: var(--v-white-base);

		&:hover {
			background-color: var(--v-gray_5-base);
		}
	}
</style>
