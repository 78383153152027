var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Accounting" }
            ]
          }
        },
        [_vm._v(" Settings ")]
      ),
      _c(
        "div",
        { staticClass: "row-format mb-4 gap-3 flex-wrap" },
        _vm._l(_vm.pages, function(page) {
          return _c(
            "div",
            {
              key: page.value,
              class:
                "page-link " +
                (_vm.settingsTab === page.value ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.settingsTab = page.value
                }
              }
            },
            [_c("div", [_vm._v(_vm._s(page.label))])]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "row-format align-center mb-4" },
        [
          _c("v-text-field", {
            style: "max-width: 200px; background-color: var(--v-white-base)",
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search...",
              color: "gray_30"
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "material-symbols-rounded font-gray_50" },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto super-action",
              on: {
                click: function($event) {
                  return _vm.addNew()
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
              _vm._v(_vm._s(_vm.addNewLabel))
            ],
            1
          )
        ],
        1
      ),
      _vm.settingsTab === 0
        ? _c("products-table", {
            ref: "ProductTable",
            attrs: { filter: _vm.search, "edit-mode": true }
          })
        : _vm._e(),
      _vm.settingsTab === 1
        ? _c("invoice-template-list", {
            ref: "InvoiceTemplateTable",
            attrs: { search: _vm.search }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }