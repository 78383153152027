<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }]">
			My profile
		</page-header>

		<div class="row-format mb-4 gap-3 flex-wrap">
			<div
				:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
				v-for="page in pages"
				:key="page.value"
				@click.stop="setView(page.value)"
			>
				<div>{{ page.label }}</div>
			</div>
		</div>

		<user-settings v-if="currentView === 0" style="ma"></user-settings>
		<delete-account v-if="currentView === 1"></delete-account>
		<notifications v-if="currentView === 2"></notifications>
		<workspaces v-if="currentView === 3"></workspaces>
	</div>
</template>

<script>
	import PageHeader from '@/components/PageHeader';
	import UserSettings from '@/modules/account/UserSettings';
	import DeleteAccount from '@/modules/account/DeleteAccount';
	import Notifications from '@/modules/account/Notifications';
	import Workspaces from '@/modules/account/Workspaces';

	export default {
		name: 'Me',

		components: { Notifications, DeleteAccount, UserSettings, PageHeader, Workspaces },

		data: function() {
			return {
				currentView: 0,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			setView: function(view) {
				this.currentView = view;
			},
		},

		computed: {
			pages: function() {
				let result = [];
				result.push({ label: 'My info', value: 0 });
				result.push({ label: 'Delete account', value: 1 });
				result.push({ label: 'Workspaces', value: 2 });
				result.push({ label: 'Notifications', value: 3 });

				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
