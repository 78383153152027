<template>
	<div class="mb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { to: '/communicator/inbox', label: 'Communicator' }]">
			Settings
		</page-header>

		<div class="row-format mb-4 gap-3 flex-wrap">
			<div
					:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
					v-for="page in pages"
					:key="page.value"
					@click.stop="setView(page.value)"
			>
				<div>{{ page.label }}</div>
			</div>
		</div>

		<mailboxes v-show="currentView === 0"></mailboxes>
		<custom-email v-show="currentView === 1"></custom-email>
		<subscription-router v-show="currentView === 2"></subscription-router>
	</div>
</template>

<script>
	import Mailboxes from "@/modules/communicator/settings/Mailboxes";
	import SubscriptionRouter from "@/modules/communicator/settings/providers/SubscriptionRouter";
	import PageHeader from "@/components/PageHeader";
	import CustomEmail from "@/modules/communicator/CustomEmail";

	export default {
		name: 'CommunicatorSettings',

		props: [],

		components: {CustomEmail, SubscriptionRouter, Mailboxes, PageHeader },

		data: function () {
			return {
				currentView: 0,
			};
		},

		mounted() {
			this.setPage();
		},

		beforeDestroy() {

		},

		methods: {
			setView: function(view) {
				this.currentView = view;
			},
		},

		watch: {

		},

		computed: {
			pages: function() {
				let result = [];
				result.push({ label: 'Mailboxes', value: 0 });
				if (this.owner) {
					result.push({ label: 'Custom email', value: 1 });
				}
				result.push({ label: 'Phone', value: 2});

				return result;
			},

			owner: function() {
				return this.$store.getters.isAccountOwner;
			},

			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
		},
	};
</script>

<style scoped lang="scss">
.v-slide-group__wrapper {
	border-top-left-radius: 4px!important;
	border-top-right-radius: 4px!important;
}
</style>
